import React, { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';
import { useQueryClient } from '@tanstack/react-query';
import DistressDialog from 'components/contextbox/asset/distress/distressDialog';
import useInterval from 'hooks/useInterval';
import useSnackbar from 'hooks/useSnackbar';
import { deviceQueryKeys } from 'apis/rest/devices/queryKeys';
import { getReports } from 'apis/rest/reports/requests';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useReportsDataRepository } from 'repositories/reports/hooks';
import useTimezone from 'hooks/session/useTimezone';
import { useAppDispatch } from 'store/types';
import {
  mostRecentDeviceReportSelector,
  setMostRecentDeviceReportDebounceThunk,
  setUnreadTextMessage,
} from 'slices/report.slice';
import { useSelector } from 'react-redux';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useOrganisationId from 'hooks/session/useOrganisationId';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

export interface LiveUpdateDummyProps {
  mostRecentDeviceReport: number | null
}

const LiveUpdateDummy = (): JSX.Element => {
  const timezone = useTimezone();
  const t = useTranslations('components.LiveUpdateDummy');
  const snackbar = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [distressReports, setDistressReports] = React.useState<Report[]>([]);
  const reportsRepo = useReportsDataRepository();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const organisationId = useOrganisationId();
  const eventSnackbar = useFeatureFlag('landingtakeoffMapNotification');
  const eventMapNotificationFeatureAssets = useFeatureAssets('mapEventNotifications');

  const assetLabel = useAssetLabel();
  const assets = useGetAssetsList().query;

  const [messagesSeen, setMessagesSeen] = useState<number[]>([]);
  const [takeoffEventsSeen, setTakeoffEventsSeen] = useState<number[]>([]);
  const [landingEventsSeen, setLandingEventsSeen] = useState<number[]>([]);
  const mostRecentDeviceReport = useSelector(mostRecentDeviceReportSelector);
  const uiSettings = useUiSettings();
  const isDisplayMode = uiSettings.displayMode ?? false;

  const alertSound = new Audio('./audio/ding.mp3');

  useEffect(() => () => {
    console.info('clear reports');
    dispatch(setMostRecentDeviceReportDebounceThunk(null));
    reportsRepo.clear();
    reportsRepo.updateAllAssets();
  }, [reportsRepo, timezone, dispatch]);

  useInterval(async () => {
    const until = moment();
    const from = mostRecentDeviceReport ? moment(mostRecentDeviceReport) : until.clone().subtract('1', 'minute');
    console.log(`getting current reports for ${organisationId} from ${from.toISOString()} until ${until.toISOString()}`);
    const reportsSince = await getReports({ organisationId, from: from.valueOf(), until: until.valueOf() });

    const newDistressReports: Report[] = [];
    const reportsSinceForAsset = reportsSince.reduce((assetReports, report) => {
      assetReports[report.assetId] = [...(assetReports[report.assetId] || []), report];
      return assetReports;
    }, {} as Record<number, Report[]>);
    Object.values(reportsSinceForAsset).forEach(assetReports => {
      const latestReport = [...assetReports].sort((a, b) => b.received - a.received)[0];
      const latestReportMoment = moment.unix(latestReport?.received);

      if ((latestReportMoment.diff(moment(), 'h') >= -2)) {
        if (latestReport.events.some(e => e.includes('EVT_DISTRESS'))) {
          if (!distressReports.some(r => r.id === latestReport.id)) {
            newDistressReports.push(latestReport);
            if (!isDisplayMode) {
              setOpen(true);
            } else {
              const evt = latestReport.events.find(e => e.includes('EVT_DISTRESS'));
              const ast = assets.data?.find(a => a.deviceId === latestReport.deviceId);
              snackbar.display({ id: `${latestReport.id}`, text: `Event ${evt} for Asset ${assetLabel(ast)}`, type: 'error' });
            }
          }
        }
      }

      // Update lastActive time on device query (used in device page)
      queryClient.setQueryData<DeviceBasic | undefined>(
        deviceQueryKeys.detail(organisationId, latestReport.deviceId),
        data => {
          if (!data) { return undefined; }
          if (moment(data.lastActive).isAfter(latestReportMoment)) { return undefined; }
          return { ...data, lastActive: latestReportMoment.tz(timezone).toISOString(true) };
        },
      );

      // If any of the new messages arriving are text messages set unread state for the device in redux
      assetReports.forEach(r => {
        if (r.events.includes('EVT_TEXT') && (moment.unix(r?.received).diff(moment(), 'm') >= -10)) {
          dispatch(setUnreadTextMessage({ deviceId: r.deviceId, reportId: r.id }));
          if (messagesSeen.includes(r.id)) {
            setMessagesSeen(messagesSeen.filter(id => r.id === id));
            return;
          }

          setMessagesSeen(messagesSeen.concat([r.id]));
          const asset = assets.data?.find(a => a.id === r.assetId);
          const text = asset ? t('receivedMessage', {
            asset: assetLabel(asset),
            message: r.text,
          }) : t('receivedMessageNoAsset', { message: r.text });
          snackbar.display({
            id: `message-received-${r.id}`,
            type: 'info',
            text,
          });
        }

        // update latest config (tracking rates in context box, device page)
        if (r.events.includes('EVT_CONFIG')) {
          queryClient.setQueryData(['config', organisationId, r.deviceId], {
            deviceId: r.deviceId,
            time: r.received,
            config: r.package,
          });
        }

        const enableTakeoff = uiSettings.takeoffNotifications && eventSnackbar && eventMapNotificationFeatureAssets.some;
        const enableLanding = uiSettings.landingNotifications && eventSnackbar && eventMapNotificationFeatureAssets.some;

        if (enableTakeoff && r.events.includes('EVT_TAKEOFF')) {
          const asset = assets.data?.find(a => a.id === r.assetId);
          snackbar.display({
            id: `takeoff-event-received-${r.id}`,
            type: 'info',
            text: t('takeoffMessage', { asset: assetLabel(asset) }),
          });
          if (!takeoffEventsSeen.includes(r.id)) {
            alertSound.play();
            setTakeoffEventsSeen(prevState => [...prevState, r.id]);
          }
        }

        if (enableLanding && r.events.includes('EVT_LANDING')) {
          const asset = assets.data?.find(a => a.id === r.assetId);
          snackbar.display({
            id: `landing-event-received-${r.id}`,
            type: 'info',
            text: t('landingMessage', { asset: assetLabel(asset) }),
          });
          if (!landingEventsSeen.includes(r.id)) {
            alertSound.play();
            setLandingEventsSeen(prevState => [...prevState, r.id]);
          }
        }
      });
    });

    // use insertReports because it only triggers render once all are in.
    const todayStart = moment().tz(timezone).startOf('day');
    const count = reportsRepo.reportRepository.reports.size;
    reportsRepo.insertReports(reportsSince, todayStart.unix());
    console.info(`inserted ${reportsRepo.reportRepository.reports.size - count} reports for ${organisationId}`);

    setDistressReports([...distressReports, ...newDistressReports]);

    const latestReportTimestamp = reportsSince.length ? Math.max(...reportsSince.map(r => r.received)) : undefined;
    if (latestReportTimestamp) {
      dispatch(setMostRecentDeviceReportDebounceThunk(latestReportTimestamp * 1000)); // to ms
    }
  }, 10 * 1000);

  return <DistressDialog open={open} setOpen={setOpen} reports={distressReports} />;
};

export default LiveUpdateDummy;
