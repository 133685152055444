import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import AnalysisboxContainer from 'components/analysisboxContainer';
import AssetContextbox from 'components/contextbox/asset';
import EditDialog from 'components/dialogs/markers/editDialog';
import { EditPointAnnotationDialog } from 'components/dialogs/annotations/editPointDialog';
import MapContainer from 'components/mapContainer';
import Omnibox from 'components/omnibox';
import Page from 'components/pages/page';
import { closeCreateMarkerDialog, selectCreateMarkerDialog, setIsPlacingMarker } from 'slices/markers.slice';
import { useCreateMarker } from 'apis/rest/markers/hooks';
import moment from 'moment';
import useTimezone from 'hooks/session/useTimezone';
import { getSelectedDay, getSelectedItem } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';
import useStyles from './map-styles';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import IMAGES from 'resources/resources';
import { createOrUpdatePath, createOrUpdatePoint, selectEditPathDialog, selectEditPointDialog, setEditPathDialog, setEditPointDialog } from 'slices/map/annotations.slice';
import { EditPathAnnotationDialog } from 'components/dialogs/annotations/editPathDialog';

const MapPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const t = useTranslations('dialogs.markers.create');
  const tPoint = useTranslations('dialogs.annotations.point');
  const tPath = useTranslations('dialogs.annotations.path');
  const createMarkerDialog = useSelector(selectCreateMarkerDialog);
  const createMarkerMutation = useCreateMarker();

  const editPointDialog = useSelector(selectEditPointDialog)
  const editPathDialog = useSelector(selectEditPathDialog)

  const selectedItem = useSelector(getSelectedItem);
  const organisationId = useOrganisationId();
  const contextboxOpen = useSelector((state: ReduxState) => state.ui.contextboxOpen);


  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);
  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : undefined), [selectedDay, timezone]);
  const uiSettings = useUiSettings()

  return (
    <Page>
      <Omnibox />

      <Stack className={classes.mapWrapper}>
        {(uiSettings.displayMode ?? false) && (
          <img className={`bg-slate-800/80 absolute top-[8.1px] left-3.5 w-48 rounded-xl py-2 pl-2 pr-4 m-2`}
            alt="tp-logo" src={IMAGES.logo_light} style={{ zIndex: 2 }} />
        )}
        <MapContainer key={organisationId} />
        {!(uiSettings.displayMode ?? false) && (<AnalysisboxContainer selectedAsset={selectedItem} />)}
      </Stack>

      {selectedItem && <AssetContextbox selectedAsset={selectedItem} now={now} contextboxOpen={contextboxOpen} />}

      <EditDialog
        isLoading={createMarkerMutation.isPending}
        open={createMarkerDialog.open}
        title={t('title')}
        latLng={createMarkerDialog.latLng}
        onClose={() => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
        }}
        onSubmit={marker => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
          createMarkerMutation.mutate({ marker });
        }}
      />
      <EditPointAnnotationDialog
        open={!!editPointDialog}
        point={editPointDialog}
        isLoading={false}
        onClose={() => {
          dispatch(setEditPointDialog(undefined))
        }}
        onSubmit={(p) => {
          dispatch(createOrUpdatePoint(p))
        }}
        title={tPoint(!!editPointDialog?.id ? 'title.edit' : 'title.create')} />

      <EditPathAnnotationDialog
        open={!!editPathDialog}
        path={editPathDialog}
        isLoading={false}
        onClose={() => {
          dispatch(setEditPathDialog(undefined))
        }}
        onSubmit={(p) => {
          dispatch(createOrUpdatePath(p))
        }}
        title={tPath(!!editPathDialog?.id ? 'title.edit' : 'title.create')} />
    </Page>
  );
};

export default MapPage;
