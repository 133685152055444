import React from 'react';
import { Params } from 'react-router';
import { createBrowserRouter, Navigate, NavigateProps, Outlet, useParams } from 'react-router-dom';
import Translate from 'components/shared/translate';
import { FeatureFlag } from 'components/shared/featureFlag';
import FeatureGate from 'components/shared/feature-gate';
import RequireAuth from 'components/shared/requireAuth';
import App from 'components/app';
import MapPage from 'components/pages/map';
import LoginPage from 'components/pages/auth/login/login-view';
import ResetPasswordPage from 'components/pages/auth/resetPassword';
import SignupPage from 'components/pages/auth/signup';
import TermsPage from 'components/pages/legal/terms';
import SetNewPasswordPage from 'components/pages/auth/setNewPassword';
import ErrorPage from 'components/pages/error';
import MessagingPage from 'components/pages/messaging';
import ConnectionsPage from 'components/pages/connections';
import SharingPageLayout from 'components/pages/sharing/layout';
import SharesFrom from 'components/pages/sharing/sharesFrom';
import SharesTo from 'components/pages/sharing/sharesTo';
import SharesSelf from 'components/pages/sharing/sharesSelf';
import ReportingLayout from 'components/pages/reporting/layout';
import ReportingPage from 'components/pages/reporting';
import TripReports from 'components/pages/reporting/tripReports';
import MissionReports from 'components/pages/reporting/missionReports';
import TripAnalysisReport from 'components/pages/reporting/tripAnalysis/report';
import DailyFlightSummaryReport from 'components/pages/reporting/dailyFlightSummary/dailyFlightSummary-report';
import AssetDailyFlightSummary from 'components/pages/reporting/dailyFlightSummary/assetFlightSummaryPage';
import FirefightingLayout from 'components/pages/reporting/firefighting/layout';
import FirefightingLayoutNew from 'components/pages/reporting/firefightingNewMap/layout';
import FirefightingSummary from 'components/pages/reporting/firefighting/summary';
import AssetDropsDetail from 'components/pages/reporting/firefighting/assetDropsDetail';
import OrganisationSettingsPage from 'components/pages/organisationSettings';
import { AccountSettings } from 'components/pages/accountSettings/AccountSettings';
import ManagePage from 'components/pages/manage';
import AssetsPage from 'components/pages/manage/assets';
import { CreateAssetPage } from 'components/pages/manage/assets/create';
import AssetPage from 'components/pages/manage/assets/asset';
import PeoplePage from 'components/pages/manage/people';
import EditPersonPage from 'components/pages/manage/people/edit';
import IcePage from 'components/pages/manage/contactGroups/ice';
import EditIcePage from 'components/pages/manage/contactGroups/ice/edit';
import MessagingWhitelistPage from 'components/pages/manage/contactGroups/messagingWhitelist';
import EditMessagingWhitelistPage from 'components/pages/manage/contactGroups/messagingWhitelist/edit';
import DevicesPage from 'components/pages/manage/devices';
import DevicePage from 'components/pages/manage/devices/device';
import PeopleGroupPage from 'components/pages/manage/peopleGroups/peopleGroups-view';
import EditPeopleGroupPage from 'components/pages/manage/peopleGroups/components/edit/editPage-view';
import EventNotificationList from 'components/pages/manage/eventNotifications/event-notification-list';
import EventNotificationEdit from 'components/pages/manage/eventNotifications/event-notification-edit';
import AssetGroupView from 'components/pages/manage/groups/assetGroups/assetGroups-view';
import EditAssetGroupsPage from 'components/pages/manage/groups/assetGroups/assetGroup-edit';
import MarkersView from 'components/pages/manage/markers';
import StaffPage from 'components/pages/staffPage';
import OrganisationsListPage from 'components/pages/staffPage/organisationsList';
import ProvisioningListPage from 'components/pages/staffPage/provisioning';
import AssetSearchPage from 'components/pages/staffPage/assetSearch';
import DistressIncidentsPage from 'components/pages/staffPage/distressIncidents';
import OrganisationFeaturesPage from 'components/pages/staffPage/organisationsFeatures';
import MarketSectorsPage from 'components/pages/staffPage/marketSector';
import MarketSectorEditPage from 'components/pages/staffPage/marketSector/marketSector-edit';
import MissingOrganisationPage from 'components/pages/missingOrganisationPage';
import { BeaconView } from 'components/pages/manage/beacon/BeaconView';
import SettingsPage from 'components/pages/settings/settings-view';
import { RequirePermission } from 'components/shared/permissions/RequirePermission';
import GeofencesLayout from 'components/pages/manage/geofences/Geofences.layout';
import GeofencesList from 'components/pages/manage/geofences/list/GeofencesList';
import GeofenceEdit from 'components/pages/manage/geofences/edit/GeofenceEdit';
import GeofenceCreate from 'components/pages/manage/geofences/create/GeofenceCreate';
import LabsFeatureContextProvider from 'contexts/labs/labs-feature-context-provider';
import LabsFeatureFlagContextProvider from 'contexts/labs/labs-feature-flag-context-provider';
import { UsersHistoryView } from 'components/pages/manage/usersHistory/usersHistory-view';

const NavigateWithParams = ({ to, ...rest }: Omit<NavigateProps, 'to'> & {
  to: (params: Params) => string,
}) => {
  const params = useParams();
  return <Navigate to={to(params)} {...rest} />;
};

const ThrowError = () => {
  throw new Error('this is a testing error');
};

export const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/login', element: <LoginPage /> },
      { path: '/reset-password', element: <ResetPasswordPage /> },
      { path: '/signup', element: <SignupPage /> },
      { path: '/legal/terms', element: <TermsPage /> },
      { path: '/set-new-password', element: <SetNewPasswordPage /> },
      { path: '/error', element: <ThrowError /> },

      { path: '*', element: <Navigate to="/" replace /> },

      // Private routes
      {
        path: '/',
        element: <RequireAuth><Outlet /></RequireAuth>,
        children: [
          { index: true, element: <MapPage /> },
          { path: 'messaging', element: <MessagingPage /> },
          { path: 'friends', element: <ConnectionsPage /> },

          {
            path: 'sharing',
            element: <SharingPageLayout />,
            children: [
              {
                index: true,
                element: <Navigate to="from" replace />,
              },
              { path: 'from', element: <SharesFrom /> },
              { path: 'to', element: <SharesTo /> },
              { path: 'self', element: <SharesSelf /> },
            ],
          },

          {
            path: 'insights',
            handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.reporting')} /> },
            children: [
              { index: true, element: <ReportingPage /> },
              {
                element: <ReportingLayout />,
                children: [
                  { path: 'trip', element: <TripReports /> },
                  { path: 'mission', element: <MissionReports /> },
                  {
                    path: 'summary',
                    element: (
                      <FeatureFlag
                        feature="utilisationInsights"
                        enabled={(
                          <LabsFeatureContextProvider>
                            <FeatureGate
                              feature="reporting.activitySummaryReport"
                              enabled={<Outlet />}
                              disabled={<Navigate to="/insights" replace />}
                              allowStaff
                              onlyLabs
                            />
                          </LabsFeatureContextProvider>
                        )}
                        disabled={<Navigate to="/insights" replace />}
                      />
                    ),
                    children: [
                      {
                        index: true,
                        lazy: () => import('components/pages/reporting/ActivitySummary'),
                      },
                    ],
                  },
                  {
                    path: 'trip-analysis',
                    element: (
                      <LabsFeatureFlagContextProvider featureFlagKey="featureModules" reverse>
                        <FeatureGate
                          feature="reporting.newTripReports"
                          enabled={<TripAnalysisReport />}
                          disabled={<Navigate to="/insights" replace />}
                          allowStaff
                        />
                      </LabsFeatureFlagContextProvider>
                    )
                  },
                  {
                    path: 'daily-flight-summary',
                    element: (
                      <LabsFeatureContextProvider>
                        <FeatureGate
                          feature="reporting.dailyFlightSummary"
                          enabled={<DailyFlightSummaryReport />}
                          disabled={<Navigate to="/insights" replace />}
                          allowStaff
                          onlyLabs
                        />
                      </LabsFeatureContextProvider>
                    )
                  },
                  {
                    path: 'geofence-events',
                    element: (
                      <FeatureFlag
                        feature="frontendgeofencereports"
                        enabled={(
                          <LabsFeatureContextProvider>
                            <FeatureGate
                              feature="reporting.geofences"
                              enabled={<Outlet />}
                              disabled={<Navigate to="/insights" replace />}
                              allowStaff
                              onlyLabs
                            />
                          </LabsFeatureContextProvider>
                        )}
                        disabled={<Navigate to="/insights" replace />}
                      />
                    ),
                    children: [
                      {
                        index: true,
                        lazy: () => import('components/pages/reporting/GeofenceEvents'),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'firefighting',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.firefighting')} /> },
                element: (
                  <FeatureGate
                    feature="reporting.aerialFirefightingReport"
                    enabled={(
                      <LabsFeatureContextProvider>
                        <LabsFeatureFlagContextProvider featureFlagKey="featureModules" reverse>
                          <FeatureFlag
                            feature="newFirefightingMap"
                            enabled={<FirefightingLayoutNew />}
                            disabled={<FirefightingLayout />}
                          />
                        </LabsFeatureFlagContextProvider>
                      </LabsFeatureContextProvider>
                    )}
                    disabled={<Navigate to="/insights" replace />}
                    allowStaff
                  />
                ),
                children: [
                  { index: true,
                    element: <FirefightingSummary />
                  },
                  { path: 'asset/:assetId',
                    element: <AssetDropsDetail />
                  },
                ],
              },
              {
                path: 'daily-flight-summary',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.dailyFlightSummaries')} /> },
                children: [
                  { path: ':assetId/:day', element: <AssetDailyFlightSummary /> },
                ],
              },
              {
                path: 'tracking-data-reports',
                element: (
                  <FeatureFlag
                    feature="operationalReportingTrackingDataReport"
                    enabled={(
                      <FeatureGate
                        feature="reporting.trackingDataReports"
                        enabled={<Outlet />}
                        disabled={<Navigate to="/insights" replace />}
                        allowStaff
                      />
                    )}
                    disabled={<Navigate to="/insights" replace />}
                  />
                ),
                children: [
                  {
                    index: true,
                    lazy: () => import('components/pages/reporting/trackingDataReports'),
                  },
                ],
              },
            ],
          },

          {
            path: 'reporting/*',
            element: <NavigateWithParams to={params => (params['*'] ? `/insights/${params['*']}` : '/insights')} replace />,
          },

          {
            path: 'settings',
            handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.settings')} /> },
            children: [
              { index: true, element: <SettingsPage /> },
              { path: 'organisation', element: <OrganisationSettingsPage /> },
              { path: 'account', element: <AccountSettings /> },
            ],
          },

          {
            path: 'manage',
            handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.manage')} /> },
            children: [
              { index: true, element: <ManagePage /> },
              {
                path: 'assets',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.manageAssets')} /> },
                children: [
                  { index: true, element: <AssetsPage /> },
                  {
                    path: 'new',
                    element: <RequirePermission
                      permission="canEditAssets"
                      permitted={<CreateAssetPage />}
                      forbidden={<Navigate to="/manage/assets" replace />}
                    />,
                  },
                  { path: ':id', element: <AssetPage /> },
                ],
              },
              {
                path: 'devices',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.manageDevices')} /> },
                children: [
                  { index: true, element: <DevicesPage /> },
                  { path: ':id', element: <DevicePage /> },
                ],
              },
              {
                path: 'beacon',
                element: <FeatureGate
                  feature="beacon.ios"
                  enabled={(
                    <RequirePermission
                      permission="canAdministerBeacon"
                      permitted={<BeaconView />}
                      forbidden={<Navigate to="/manage" replace />}
                    />
                  )}
                  disabled={<Navigate to="/manage" replace />}
                  allowStaff
                />
              },
              {
                path: 'people',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.managePeople')} /> },
                children: [
                  { index: true, element: <PeoplePage /> },
                  { path: ':id', element: <EditPersonPage /> },
                ],
              },
              {
                path: 'ice',
                children: [
                  { index: true, element: <Navigate to="groups" replace /> },
                  {
                    path: 'groups',
                    handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.manageIceContactGroups')} /> },
                    children: [
                      { index: true, element: <IcePage /> },
                      { path: ':id', element: <EditIcePage /> },
                    ],
                  },
                  { path: 'assign-assets', element: <IcePage /> },
                ],
              },
              {
                path: 'device-messaging',
                children: [
                  { index: true, element: <Navigate to="groups" replace /> },
                  {
                    path: 'groups',
                    handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.manageMessagingWhitelistContactGroups')} /> },
                    children: [
                      { index: true, element: <MessagingWhitelistPage /> },
                      { path: ':id', element: <EditMessagingWhitelistPage /> },
                    ],
                  },
                  { path: 'assign-assets', element: <MessagingWhitelistPage /> },
                ],
              },
              {
                path: 'messaging-whitelists',
                children: [
                  { index: true, element: <Navigate to="groups" replace /> },
                  {
                    path: 'groups',
                    children: [
                      { index: true, element: <Navigate to="/manage/device-messaging/groups" replace /> },
                      { path: ':id', element: <NavigateWithParams to={params => `/manage/device-messaging/groups/${params.id}`} replace /> },
                    ],
                  },
                  { path: 'assign-assets', element: <Navigate to="/manage/device-messaging/assign-assets" replace /> },
                ],
              },
              {
                path: 'people-groups',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.peopleGroups')} /> },
                element: (
                  <FeatureGate
                    feature="manage.notifications"
                    enabled={<Outlet />}
                    disabled={<Navigate to="/manage" replace />}
                    allowStaff
                  />
                ),
                children: [
                  { index: true, element: <PeopleGroupPage /> },
                  { path: ':id', element: <EditPeopleGroupPage /> },
                ],
              },
              {
                path: 'asset-groups',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.manageAssetGroups')} /> },
                children: [
                  { index: true, element: <AssetGroupView /> },
                  { path: ':id', element: <EditAssetGroupsPage /> },
                ],
              },
              {
                path: 'event-notifications',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.eventNotifications')} /> },
                element: (
                  <FeatureGate
                    feature="manage.notifications"
                    enabled={<Outlet />}
                    disabled={<Navigate to="/manage" replace />}
                    allowStaff
                  />
                ),
                children: [
                  { index: true, element: <EventNotificationList /> },
                  { path: ':id', element: <EventNotificationEdit /> },
                ],
              },
              {
                path: 'geofencing',
                element: <Navigate to="/manage/geofences" replace />,
              },
              {
                path: 'geofences',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.geofences')} /> },
                element: (
                  <FeatureGate
                    feature="manage.geofencing"
                    enabled={<GeofencesLayout />}
                    disabled={<Navigate to="/manage" replace />}
                  />
                ),
                children: [
                  { index: true, element: <GeofencesList />, handle: { pageName: 'list' } },
                  { path: 'create', element: <GeofenceCreate />, handle: { pageName: 'create' } },
                  { path: ':geofenceId', element: <GeofenceEdit />, handle: { pageName: 'edit' } }
                ],
              },
              {
                path: 'markers',
                element: (
                  <FeatureGate
                    feature="map.markers"
                    enabled={<MarkersView />}
                    disabled={<Navigate to="/manage" replace />}
                  />
                ),
              },
              {
                path: 'users-activity',
                element: <FeatureGate
                  feature="manage.userActivity"
                  enabled={<UsersHistoryView />}
                  disabled={<Navigate to="/manage" replace />}
                  allowStaff
                />,
              },
            ],
          },

          {
            path: 'staff',
            handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.staff')} /> },
            children: [
              { index: true, element: <StaffPage /> },
              {
                path: 'organisations',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.organisations')} /> },
                children: [
                  { index: true, element: <OrganisationsListPage /> },
                  { path: ':id/features', element: <OrganisationFeaturesPage /> },
                ],
              },
              { path: 'distress-incidents', element: <DistressIncidentsPage /> },
              { path: 'provisioning', element: <ProvisioningListPage /> },
              { path: 'asset-search', element: <AssetSearchPage /> },
              {
                path: 'market-sectors',
                handle: { breadcrumb: <Translate render={t => t('components.LinkAbove.marketSectors')} /> },
                children: [
                  { index: true, element: <MarketSectorsPage /> },
                  { path: ':id', element: <MarketSectorEditPage /> },
                ],
              },
            ],
          },

          { path: 'missing-organisation', element: <MissingOrganisationPage /> },
        ],
      },
    ],
  },
]);
