import React, { PropsWithChildren, SetStateAction, useCallback } from 'react';
import {
  Typography, IconButton, Divider, Stack, FormControl, FormControlLabel, Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutline from '@mui/icons-material/HelpOutline';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslations } from 'use-intl';
import CheckboxInput from 'components/shared/forms/inputs/bool';
import SliderInput from 'components/shared/forms/inputs/slider';
import SelectInput from 'components/shared/forms/inputs/muiSelect';
import { useViewport } from 'contexts/viewport/useViewport';
import { useUserSettings } from 'contexts/userSettings/useUserSettings';
import useAllFeatureAssets from 'contexts/featureAssets/useAllFeatureAssets';
import { useSelector } from 'react-redux';
import { selectFirefightingMode, setFirefightingMode } from 'slices/settings/mapSettings.slice';
import { useAppDispatch } from 'store/types';
import { FeatureFlag } from 'components/shared/featureFlag';
import { updateLocalUiSettingsField, updateUiSettingField } from 'slices/settings/uiSettings.slice';
import { showLabInfo } from 'slices/labs.slice';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import useFeatureFlag from 'hooks/useFeatureFlag';
import ContainmentLinesOptionPicker
  from 'components/map/modules/settingsDrawer/modules/containmentLinesOptionPicker/ContainmentLinesOptionPicker';
import { updateMapConfig, type MapSettings } from 'slices/map.slice';
import BaseLayerPicker from './modules/baseLayerPicker';
import {
  MapSettingsDrawer, MapSettingsHeader,
  MapSettingsSection,
  MapSettingsTitle,
  MapSettingsWrapper,
} from './settingsDrawer-styles';
import KmlLoader from './modules/kmlLoader';
import { KmlOrder, KmlStatuses } from './modules/kmlLoader/useKmlManager';
import { FirefightingOptions } from './modules/dropsOptionPicker/firefightingOptions';
import TrialPicker from './modules/trailPicker';
import LabsMapSettings from './modules/labs';
import { toggleControlContrast, updateSetting } from 'slices/settings.slice';
import LocalObjectsView from './modules/annotations/annotations-view';

interface SettingsDrawerProps {
  settingsDrawerOpen: boolean
  handleSettingsDrawerClose: () => void
  config: MapSettings
  setKmlStatus: React.Dispatch<SetStateAction<KmlStatuses>>
  kmlStatus: KmlStatuses
  setKmlOrder: React.Dispatch<SetStateAction<KmlOrder>>
  kmlOrder: KmlOrder
}

const SettingsDrawer: React.FC<PropsWithChildren<SettingsDrawerProps>> = ({
  children,
  settingsDrawerOpen,
  handleSettingsDrawerClose,
  config,
  setKmlStatus,
  kmlStatus,
  setKmlOrder,
  kmlOrder,
}) => {
  const t = useTranslations('pages.map.settingsDialog');
  const trailT = useTranslations('components.TrailColouring');
  const uiSettings = useUiSettings();
  const dispatch = useAppDispatch();
  const highContrastControls = useSelector<ReduxState, boolean>(state => state.settings.map.highContrastControls);
  const viewport = useViewport(config.id);
  const [mapSettings, setMapSettings] = useUserSettings('map');
  const firefightingMode = useSelector(selectFirefightingMode);
  const isUsercodeUser = useIsUsercodeLogin();

  const allFeatures = useAllFeatureAssets();
  const featureModules = useFeatureFlag('featureModules');
  const labsFeature = allFeatures.getFeature('labs');
  const geofencesFeature = allFeatures.getFeature('manage.geofencing');
  const markersFeatureAssets = allFeatures.getFeature('map.markers');
  const threeDFeature = allFeatures.getFeature('map.3d');

  const dropLinesFeatureAssets = allFeatures.getFeature('map.droplines');
  const containmentLinesFeature = allFeatures.getFeature('map.containmentLines');

  const handleAssetDetailChange = useCallback((value: string) => {
    if (isUsercodeUser) {
      dispatch(updateSetting({ category: 'ui', field: 'assetDetailSelected', value }));
      dispatch(updateLocalUiSettingsField({ field: 'assetDetailSelected', value }));
    } else {
      dispatch(updateUiSettingField({ field: 'assetDetailSelected', value }));
    }
  }, [dispatch, isUsercodeUser]);

  return (
    <MapSettingsDrawer
      anchor="left"
      open={settingsDrawerOpen}
      onClose={handleSettingsDrawerClose}
      role="complementary"
      aria-labelledby="mapSettingsHeaderText"
      isdisplay={uiSettings.displayMode ?? false}
    >
      <MapSettingsWrapper>
        <MapSettingsHeader component="header">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography id="mapSettingsHeaderText" variant="h2" color="inherit">{t('title')}</Typography>
            {handleSettingsDrawerClose ? (
              <IconButton
                aria-label="close"
                onClick={handleSettingsDrawerClose}
                size="large"
                color="inherit"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            ) : null}
          </Stack>
        </MapSettingsHeader>
        <Stack flex={1}>
          <BaseLayerPicker />
          <MapSettingsSection component={Stack}>
            <MapSettingsTitle>{t('customLayers')}</MapSettingsTitle>
            <KmlLoader setKmlOrder={setKmlOrder} setKmlStatus={setKmlStatus} kmlOrder={kmlOrder} kmlStatus={kmlStatus} t={t} />
            <Divider />
            <CheckboxInput
              label={t('kml.displayOverlay')}
              value={mapSettings.kml.displayOverlay}
              onChange={(id: unknown, value: boolean) => setMapSettings(s => { s.kml.displayOverlay = value; })}
            />
            <CheckboxInput
              label={t('kml.displayAdditionalData')}
              value={mapSettings.kml.displayAdditionalData}
              onChange={(id: unknown, value: boolean) => setMapSettings(s => { s.kml.displayAdditionalData = value; })}
              disabled={!mapSettings.kml.displayOverlay}
            />

          </MapSettingsSection>
          <FeatureFlag feature="tpcMapAnnotations" enabled={(<MapSettingsSection>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <MapSettingsTitle as="legend">{t('annotations.title')}</MapSettingsTitle>
              <IconButton aria-label={t('annotations.help')} onClick={() => dispatch(showLabInfo('map.annotations'))}>
                <HelpOutline />
              </IconButton>
            </Stack>
            <LocalObjectsView open={true} />
          </MapSettingsSection>)} />
          <FeatureFlag
            feature="frontendSunsetMap"
            enabled={(
              <MapSettingsSection>
                <MapSettingsTitle>{t('otherLayers.title')}</MapSettingsTitle>
                <CheckboxInput
                  label={t('otherLayers.sunLayer')}
                  value={mapSettings.layers.sun}
                  onChange={(id: unknown, value: boolean) => setMapSettings(s => { s.layers.sun = value; })}
                />
              </MapSettingsSection>
            )}
          />
          <MapSettingsSection>
            <MapSettingsTitle>{t('animations')}</MapSettingsTitle>
            <CheckboxInput
              label={t('animateTrails')}
              value={config.animateTrails}
              onChange={(id: unknown, value: boolean) => dispatch(updateMapConfig({ mapId: config.id, config: { animateTrails: value } }))}
            />
            <CheckboxInput
              label={t('animateSelectedTrailOnly')}
              value={config.animateSelectedTrailOnly}
              onChange={(id: unknown, value: boolean) => dispatch(updateMapConfig({ mapId: config.id, config: { animateSelectedTrailOnly: value } }))}
              disabled={!config.animateTrails}
            />
          </MapSettingsSection>
          <MapSettingsSection>
            <MapSettingsTitle>{t('trailOptions')}</MapSettingsTitle>
            <TrialPicker mapId={config.id} />
            <CheckboxInput
              label={t('reportDots')}
              value={config.reportDots}
              onChange={(id: unknown, value: boolean) => dispatch(updateMapConfig({ mapId: config.id, config: { reportDots: value } }))}
            />
            <SliderInput
              label={t('reportDotsZoom')}
              value={config.hideReportDotsAtZoom}
              onChange={(id: unknown, value: number) => dispatch(updateMapConfig({ mapId: config.id, config: { hideReportDotsAtZoom: value } }))}
              disabled={!config.reportDots}
              min={0}
              max={19}
              step={0.5}
              marks={[{ value: viewport?.zoom, label: t('currentZoom') }]}
            />
            <SelectInput
              label={t('selectedTrailColouring')}
              value={config.assetTrailColouring}
              onChange={(id: unknown, value: MapSettings['assetTrailColouring']) => dispatch(updateMapConfig({ mapId: config.id, config: { assetTrailColouring: value } }))}
              options={(['none', 'speed', 'altitude', 'battery', 'latency', 'transport'] as const).map(v => ({ id: v, label: trailT(v) }))}
            />
          </MapSettingsSection>
          <MapSettingsSection>
            {(featureModules || dropLinesFeatureAssets.some) && (
              <>
                {!dropLinesFeatureAssets.some && (
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MapSettingsTitle>{t('firefightingOptions.title')}</MapSettingsTitle>
                    <IconButton aria-label={t('firefightingOptions.help')} onClick={() => dispatch(showLabInfo('map.firefightingOptions'))}>
                      <LockIcon />
                    </IconButton>
                  </Stack>
                )}
                <FirefightingOptions mapId={config.id} disabled={!dropLinesFeatureAssets.some} />
                <FeatureFlag
                  feature="enablefirefightingmodesetting"
                  enabled={(
                    <CheckboxInput
                      label={t('highlightDrops')}
                      value={firefightingMode}
                      onChange={() => dispatch(setFirefightingMode(!firefightingMode))}
                      disabled={!dropLinesFeatureAssets.some}
                    />
                  )} />
              </>
            )}
          </MapSettingsSection>
          <MapSettingsSection>
            <MapSettingsTitle>{t('labels')}</MapSettingsTitle>
            <CheckboxInput
              label={t('assetLabels')}
              value={config.permanentLabels}
              onChange={(id: unknown, value: boolean) => dispatch(updateMapConfig({ mapId: config.id, config: { permanentLabels: value } }))}
            />
            <CheckboxInput
              label={t('kmlLabels')}
              value={config.kmlLabels}
              onChange={(id: unknown, value: boolean) => dispatch(updateMapConfig({ mapId: config.id, config: { kmlLabels: value } }))}
            />
          </MapSettingsSection>
          <MapSettingsSection>
            <MapSettingsTitle>{t('assetDetails')}</MapSettingsTitle>
            <SelectInput
              value={uiSettings.assetDetailSelected}
              onChange={(id: unknown, value: string) => handleAssetDetailChange(value)}
              options={(['bearingAtSpeed', 'altitudeAtSpeed', 'position', 'make', 'model', 'variant', 'owner', 'category'] as const).map(v => ({ id: v, label: t(`assetDetail.${v}`) }))}
            />
          </MapSettingsSection>
          <MapSettingsSection flex={1}>
            <MapSettingsTitle>{t('mapControls')}</MapSettingsTitle>
            <CheckboxInput
              label={t('highContrastControls')}
              value={highContrastControls}
              onChange={() => dispatch(toggleControlContrast(!highContrastControls))}
            />
            <p>{highContrastControls}</p>
          </MapSettingsSection>
          <FeatureFlag feature="featureModules" enabled={(
            <MapSettingsSection>
              <FormControl component="fieldset" fullWidth>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MapSettingsTitle as="legend">{t('geofences.title')}</MapSettingsTitle>
                  <IconButton aria-label={t('geofences.help')} onClick={() => dispatch(showLabInfo('manage.geofencing'))}>
                    <HelpOutline />
                  </IconButton>
                </Stack>
                <FormControlLabel
                  label={t('geofences.enabled')}
                  control={(
                    <Checkbox
                      checked={geofencesFeature.some ? config.geofencesEnabled : false}
                      onChange={event => dispatch(updateMapConfig({ mapId: config.id, config: { geofencesEnabled: event.target.checked } }))}
                    />
                  )}
                  disabled={!geofencesFeature.some}
                />
              </FormControl>
            </MapSettingsSection>
          )} />
          <FeatureFlag feature="featureModules" enabled={(
            <MapSettingsSection>
              <FormControl component="fieldset" fullWidth>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MapSettingsTitle as="legend">{t('markers.title')}</MapSettingsTitle>
                  <IconButton aria-label={t('markers.help')} onClick={() => dispatch(showLabInfo('map.markers'))}>
                    <HelpOutline />
                  </IconButton>
                </Stack>
                <FormControlLabel
                  label={t('markers.enabled')}
                  control={(
                    <Checkbox
                      checked={markersFeatureAssets.some ? config.markersEnabled : false}
                      onChange={event => dispatch(updateMapConfig({ mapId: config.id, config: { markersEnabled: event.target.checked } }))}
                    />
                  )}
                  disabled={!markersFeatureAssets.some}
                />
              </FormControl>
            </MapSettingsSection>
          )} />
          <FeatureFlag feature="featureModules" enabled={(
            <MapSettingsSection>
              <FormControl component="fieldset" fullWidth>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MapSettingsTitle as="legend">{t('3d.title')}</MapSettingsTitle>
                  <IconButton aria-label={t('3d.help')} onClick={() => dispatch(showLabInfo('map.3d'))}>
                    <HelpOutline />
                  </IconButton>
                </Stack>
                <FormControlLabel
                  label={t('3d.enabled')}
                  control={(
                    <Checkbox
                      checked={config.threeDEnabled && threeDFeature.some}
                      onChange={event => dispatch(updateMapConfig({ mapId: config.id, config: { threeDEnabled: event.target.checked } }))}
                    />
                  )}
                  disabled={!threeDFeature.some}
                />
                <FormControlLabel
                  label={t('3d.curtainEnabled')}
                  control={(
                    <Checkbox
                      checked={config.showTrailCurtain && threeDFeature.some}
                      onChange={event => dispatch(updateMapConfig({ mapId: config.id, config: { showTrailCurtain: event.target.checked } }))}
                    />
                  )}
                  disabled={!threeDFeature.some || !config.threeDEnabled}
                />
              </FormControl>
            </MapSettingsSection>
          )} />
          <FeatureFlag feature="featureModules" enabled={(
            <MapSettingsSection>
              <FormControl component="fieldset" fullWidth>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MapSettingsTitle as="legend">{t('containmentLines.title')}</MapSettingsTitle>
                  <IconButton aria-label={t('containmentLines.help')} onClick={() => dispatch(showLabInfo('map.containmentLines'))}>
                    <HelpOutline />
                  </IconButton>
                </Stack>
                <ContainmentLinesOptionPicker config={config} disabled={!containmentLinesFeature.some} />
              </FormControl>
            </MapSettingsSection>
          )} />

          {labsFeature.some && <LabsMapSettings config={config} />}
          {children}
        </Stack>
      </MapSettingsWrapper>
    </MapSettingsDrawer>
  );
};

export default SettingsDrawer;
