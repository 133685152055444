import * as d3 from 'd3';
import { GeoJsonProperties } from 'geojson';

export const SAFE_DEFAULT_BOUNDS: [[number, number], [number, number]] = [[-90, -45], [90, 45]];

export const safeBounds = (object: d3.GeoGeometryObjects | d3.ExtendedFeature<d3.GeoGeometryObjects | null, GeoJsonProperties> | d3.ExtendedFeatureCollection<d3.ExtendedFeature<d3.GeoGeometryObjects | null, GeoJsonProperties>> | d3.ExtendedGeometryCollection<d3.GeoGeometryObjects> | null) => {
  if (!object) {
    console.error('safeBounds was provided with null');
    return SAFE_DEFAULT_BOUNDS;
  }
  const d3bounds = d3.geoBounds(object);
  if (d3bounds[0][0] > 0 && d3bounds[1][0] < 0) {
    d3bounds[1][0] += 360;
  }
  if (!d3bounds.flatMap(a => a).every(v => Number.isFinite(v))) {
    console.error('safeBounds was provided with infinite or NaN bounds', d3bounds);
    return SAFE_DEFAULT_BOUNDS;
  }
  return d3bounds;
};
