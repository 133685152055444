import { esES } from '@mui/x-date-pickers/locales';
import type { MessagesEN } from './en';

// @ts-ignore
const messages: MessagesEN = {
  datePicker: esES.components.MuiLocalizationProvider.defaultProps.localeText,
  app: {
    compatability: {
      title: 'Requerimientos mínimos',
      continueAnyway: 'Si decide continuar usando TracPlus Cloud con este dispositivo no compatible, tenga en cuenta que es posible que la aplicación no funcione como se esperaba.',
      continueAnywayButtonText: 'De todas maneras, continúe',
      downloadTheAppFromAppleAppStore: 'Descargue la aplicación desde Apple App Store.',
      downloadTheAppFromGooglePlay: 'Descargue la aplicación desde Google Play.',
      unsupportedBrowser: 'Tu navegador no es compatible. Utilice uno de: Chrome, Firefox, Safari, Edge u Opera.',
      outdatedBrowser: 'Su navegador no está actualizado. Actualice a la última versión antes de continuar.',
      onMobile: 'Estás utilizando un dispositivo móvil. Recomendamos que descargue nuestro cliente móvil en su lugar.',
      onIos: 'Estás utilizando un dispositivo iOS. Encuentra nuestra aplicación móvil en la App Store de Apple.',
      onAndroid: 'Estás utilizando un dispositivo Android. Encuentra nuestra aplicación móvil en Google Play Store.',
      noWebGl: 'Su navegador no es compatible con WebGL. Asegúrese de estar utilizando un dispositivo con capacidad de aceleración 3D y un navegador moderno.',
      lowRes: 'Su pantalla es demasiado estrecha para mostrar correctamente esta aplicación. Asegúrese de estar utilizando un dispositivo con un ancho de pantalla mínimo de 1200 píxeles.'
    }
  },
  header: {
    modules: {
      alert: {
        multipleAssets: 'Múltiples activos',
        isInDistress: 'Está en emergencia',
        areInDistress: 'Están en emergencia',
        isOverdue: 'Está atrasado',
        isOfConcern: 'es de preocupación',
        isOK: 'esta bien',
        isFinished: 'el monitoreo activo terminó en',
        areOverdue: 'Están Atrasados',
        ofConcern: 'De preocupación',
        areOfConcern: 'Son de preocupación',
        close: 'Cerrar'
      },
      links: {
        knowledgeBase: 'Base de Conocimientos',
        troubleshooting: 'Solución de Problemas',
      },
      userPocket: {
        accountSettingsButtonText: 'Configuración de la cuenta',
        orgSettingsButtonText: 'Configuración de la Organización',
        logoutButtonText: 'Cerrar sesión'
      },
      feedback: {
        feedback: 'Retroalimentación',
        captionOne: 'No dude en ponerse en contacto con nuestro equipo de desarrollo mediante el siguiente formulario. Sus comentarios son importantes y ayudarán a guiar y corregir el desarrollo.',
        submitButton: 'Enviar',
        confirmText: 'Comentarios enviados - ¡Gracias por su apoyo!'
      },
      organisationSelector: {
        selectOrganisation: 'Seleccionar organización',
      },
    }
  },
  navigation: {
    menu: 'Menú',
    map: 'Mapa',
    messaging: 'Mensajería',
    sharing: 'Compartir',
    friends: 'Amigos',
    reporting: 'Informes',
    settings: 'Configuraciones',
    comingSoon: 'Próximamente',
    manage: 'Administrar',
    staffPage: 'Personal',
    compatibility: 'Requisitos mínimos: TracPlus Cloud'
  },
  pageTitles: {
    brand: 'TracPlus Cloud',
    map: 'Mapa',
    search: 'Mapa',
    messaging: 'Mensajería',
    login: 'Inicia Sesión',
    'reset-password': 'Restablecer Contraseña',
    signup: 'Registrarse',
    'legal-terms': 'Términos y Condiciones',
    'set-new-password': 'Nueva Contraseña',
    compatibility: 'Requerimientos Mínimos',
    error: 'Error',
    friends: 'Amigos y grupos',
    reporting: 'Informes',
    sharing: 'Compartir',
    settings: 'Configuraciones',
    'settings-general': 'Configuración General',
    'settings-organisation': 'Configuración de la Organización',
    'settings-account': 'Configuraciones de la Cuenta',
    'assets-and-devices': 'Activos y Dispositivos',
    assets: 'Activos',
    devices: 'Despositivos',
    'settings-people': 'Personas',
    'asset-new': 'Crear activo',
    'edit-asset': 'Configurar activo',
    'device-new': 'Crear Dispositivo',
    'edit-device': 'Configurar Dispositivo',
    staff: 'Herramientas del Personal',
    'staff-organisations': 'Organizaciones',
    'staff-billing-iridium-usage': 'Utilización de Iridium',
    'staff-asset-search': 'Búsqueda de activo',
    'staff-distress-incidents': 'Incidentes de Socorro',
    'missing-organisation': 'Organización Ausente',
    'staff-billing-plansAddons': 'Planes y Servicios Adicionales',
    'staff-billing-plans-new': 'Crear Plan',
    'staff-billing-addons-new': 'Crear Servicio Adicional',
    'edit-plan': 'Editar Plan',
    'edit-addon': 'Editar Servicio Adicional'
  },
  components: {
    LinkAbove: {
      returnTo: 'Volver a {label}',
      reporting: 'Informes',
      dailyFlightSummaries: 'Resúmenes diarios de vuelos',
      firefighting: 'Resumen de combate aéreo de incendios',
      geofenceEvents: 'Eventos de geocerca',
      manage: 'Administrar',
      manageAssets: 'Activos',
      manageDevices: 'Dispositivos',
      managePeople: 'Personas de contacto',
      manageIceContactGroups: 'Grupos de contacto de emergencia',
      manageMessagingWhitelistContactGroups: 'Grupos de contactos de mensajería de dispositivos',
      manageAssetGroups: 'Grupos de activos',
      settings: 'Configuraciones',
      staff: 'Herramientas de soporte',
      trackingDataReport: 'Informes de datos de seguimiento',
      peopleGroups: 'Grupos de contacto',
      eventNotifications: 'Grupos de notificación de eventos',
      marketSectors: 'Sectores de mercado',
      organisations: 'Organizaciones',
      geofences: 'Geocercas',
      userActivity: 'Historial de usuarios',
    },
    SelectSerialType: {
      label: 'Tipo de serie del dispositivo',
      serialType: {
        tpSerial: 'Serie de TracPlus',
        imei: 'IMEI',
        manufacturerSerial: 'Serie del fabricante',
      },
    },
    Nag: {
      missingDefaultIceContactGroup: 'Esta organización no tiene un grupo de contactos ICE predeterminado',
      invalidDefaultIceContactGroup: 'Faltan detalles del grupo de contacto ICE predeterminado',
      createIceContactGroup: 'Por favor, asigne o cree uno ahora',
      editDefaultIceContactGroup: 'Por favor, añádelos ahora',
      dismiss: 'Despedir',
      usercodeWelcome: 'Ha iniciado sesión con un código de usuario de la organización. Esto le da acceso de solo lectura. <br></br>Si necesita acceso de edición, comuníquese con el <link>administrador de esta organización</link> para que le cree una cuenta personal.',
    },
    TableColumnsPicker: {
      columns: 'Columnas',
      columnCount: '{n, plural, =0 {Sin columnas} one {# columna} other {# columnas}}',
    },
    TrailColouring: {
      altitude: 'Altitud',
      speed: 'Velocidad',
      battery: 'Batería',
      transport: 'Transporte',
      latency: 'Latencia',
      none: 'Coloración de recursos (predeterminado)'
    },
    Snackbar: {
      dismiss: 'Despedir',
    },
    OpenStreetMapCredit: {
      osmAttribution: 'Nombres de ubicaciones de {osmLink}',
    },
    MultiSelect: {
      noUnselected: 'No hay elementos no seleccionados',
      noFiltered: 'Sin elementos filtrados',
    },
    Download: {
      label: 'Descargar',
    },
    LiveUpdateDummy: {
      receivedMessage: 'Mensaje recibido de {asset}: {message}',
      receivedMessageNoAsset: 'Mensaje recibido: {message}',
      landingMessage: 'Evento de aterrizaje recibido de {asset}',
      takeoffMessage: 'Evento de despegue recibido de {asset}',
      unnamedAsset: 'Activo sin nombre',
    },
    TimeSince: {
      aFewSeconds: 'hace unos segundos',
      relativeTime: `hace {unit, select,
        seconds {{n, plural, one {un segundo} other {# segundos}}}
        minutes {{n, plural, one {un minuto} other {# minutos}}}
        hours {{n, plural, one {una hora} other {# horas}}}
        days {{n, plural, one {un día} other {# días}}}
        months {{n, plural, one {un mes} other {# meses}}}
        years {{n, plural, one {un año} other {# años}}}
        other {ERROR}
      }`,
    },
  },
  pages: {
    login: {
      pageTitle: 'Iniciar sesión',
      title: 'Inicia sesión en TracPlus',
      paragraph: 'Por favor ingrese los detalles de su cuenta para continuar.',
      registerSentence: 'Nuevo en TracPlus?',
      submitButton: 'Iniciar sesión',
      emailPlaceholder: 'Email or Usercode',
      passwordPlaceholder: 'Contraseña',
      forgottenPassword: '¿Olvidaste tu contraseña?',
      rememberMe: 'Recordar contraseña',
      loginFailed: 'Usuario o contraseña inválido',
      contactSupport: 'Su cuenta no es miembro de nunguna organización. Póngase en contacto con el soporte de TracPlus.',
      serverError: 'Error del servidor',
      tooManyAttempts: 'Demasiados intentos de inicio de sesión fallidos. Vuelva a intentarlo en unos minutos o restablezca su contraseña.',
      differentProviderError: 'Su correo electrónico ya se utiliza para una cuenta de correo electrónico/contraseña. Por favor inicie sesión con su correo electrónico y contraseña.',
      signupFailed: 'Error en la creación de la cuenta'
    },
    signup: {
      title: 'Regístrese en TracPlus',
      paragraph: 'Crea tu cuenta y explore todas las herramientas que necesita para rastrear y administrar su equipo y sus activos.',
      namePlaceholder: 'Nombre completo',
      emailPlaceholder: 'Email',
      passwordPlaceholder: 'Contraseña',
      confirmPasswordPlaceholder: 'Confirmar la contraseña',
      submitButton: 'Regístrate',
      disclaimerText: "Al hacer clic en el botón 'Regístrate', está creando una cuenta TracPlus y acepta los términos de uso de TracPlus",
      disclaimerLink: 'Términos de Uso',
      cancelButton: 'Cancelar',
      passwordStrength: 'Seguridad de la contraseña',
      veryWeak: 'Muy débil',
      weak: 'Débil',
      moderate: 'Moderado',
      strong: 'Fuerte',
      veryStrong: 'Muy fuerte',
      passwordRequired: 'Contraseña requerida.',
      passwordTooShort: 'Su contraseña es demasiado corta.',
      confirmPasswordRequired: 'Confirmación contraseña requerida.',
      passwordsDontMatch: 'Las contraseñas no coinciden.',
      emailRequired: 'Correo electrónico requerido.',
      invalidEmail: 'Dirección de correo electrónico inválida.',
      nameRequired: 'Nombre completo requerido.',
      inviteInvalid: 'Invitar inválido',
      inviteInvalidParagraph: 'Se ha cancelado o ya se ha utilizado. Solicite una nueva invitación o comuníquese con el servicio de asistencia si cree que se trata de un error. ',
      inviteInvalidButton: 'Regresa',
      signupSeparator: 'o regístrate con'
    },
    resetPassword: {
      title: 'Restablecer su contraseña',
      paragraph: 'Para restablecer su contraseña, ingrese la dirección de correo electrónico que utiliza para iniciar sesión en TracPlus. Se enviará un enlace por correo electrónico a esta dirección para restablecer su contraseña.',
      emailPlaceholder: 'Email',
      submitButton: 'Enviar un enlace',
      cancelButton: 'Cancelar',
      errors: {
        inv_email: 'Email inválido'
      },
      emailRequired: 'Correo electrónico requerido.',
      invalidEmail: 'Dirección de correo electrónico inválida.',
      resetPasswordLinkSent: 'Se ha enviado un enlace para restablecer su contraseña.'
    },
    setNewPassword: {
      setNewPassword: 'Establecer una nueva contraseña',
      setNewPasswordMessage: 'Por favor, establezca una nueva contraseña para su cuenta. Su contraseña debe contener al menos {minimumPasswordLength} caracteres.',
      emailAddress: 'Dirección de correo electrónico',
      invalidEmail: 'Dirección de correo electrónico no válida.',
      newPassword: 'Nueva contraseña',
      passwordRequired: 'Contraseña requerida.',
      passwordTooShort: 'Su contraseña es demasiado corta.',
      confirmPassword: 'Confirmar contraseña',
      confirmPasswordRequired: 'Confirmación contraseña requerida.',
      passwordsDontMatch: 'Las contraseñas no coinciden.',
      resetPassword: 'Restablecer la contraseña',
      cancelButton: 'Cancelar',
      resettingPassword: 'Restableciendo su contraseña...',
      returnToLogIn: 'Regresar a Iniciar sesión',
      unableToResetPassword: 'No se pudo restablecer su contraseña.',
      resetPasswordError: 'Esta solicitud de restablecimiento de contraseña ha expirado, envíe una nueva solicitud de restablecimiento de contraseña.'
    },
    connections: {
      title: 'Amigos y Grupos',
      description: 'Conecta tu organización con otra.',
      publicKeyDesc: 'Para hacerte amigo de otra organización, envíales tu clave pública:',
      copyToClipboard: 'Copiar al portapapeles',
      copiedPublicKey: 'Clave pública copiada al portapapeles: {pubkey}',
      tabs: {
        friends: 'Amigos',
        groups: 'Grupos'
      },
      friends: {
        title: 'Amigos',
        accept: 'Aceptar',
        reject: 'Rechazar',
        cancel: 'Cancelar',
        delete: 'Borrar',
        addButton: 'Añadir amigo',
        confirmDeleteFriendTitle: 'Eliminando {friend} de tu lista de amigos.',
        confirmDeleteFriendMessage: 'Ya no podrá crear, editar o eliminar acciones compartidas con {friend}. ¿Está seguro?',
        addFriendDialogTitle: 'Añade un amigo',
        befriendDescription: 'Conecte su organización con otra para permitir el intercambio de activos y la mensajería entre organizaciones.',
        noOrgsFoundToFriend: 'No se encontraron organizaciones',
        searchForOrganisation: 'Buscar una organización',
        friendPublicKeyPrompt: 'Clave pública de tu amigo',
        friendAdded: 'Amigo agregado exitosamente.',
        friendRemoved: 'Amiga eliminada con éxito.',
        addFriendError: 'Agregar amiga falló.',
        removeFriendError: 'Agregar amiga falló.',
        inviteButton: 'Invitación',
        getFriendsFailed: 'Error al conseguir amigos, recarga la página.',
        getSharesFailed: 'No se pudieron obtener acciones',
        group: 'Grupo',
        organisation: 'Organización',
        columns: {
          name: 'Nombre',
          type: 'Tipo',
          sharesFrom: 'Compartidos contigo',
          sharesTo: 'Tú compartes',
          actions: 'Acciones'
        },
        status: {
          PendingMe: 'En espera de su aprobación',
          PendingThem: 'En espera de su aprobación',
          Active: 'Activa'
        }
      },
      groups: {
        title: 'Grupos',
        accept: 'Aceptar',
        reject: 'Rechazar',
        cancel: 'Cancelar',
        delete: 'Eliminar este grupo de tus amigos',
        addButton: 'Hazte amigo del grupo',
        confirmDeleteGroupTitle: 'No amigo {group}?',
        confirmDeleteGroupMessage: 'Ya no podrá compartir datos de seguimiento con {group}. Estas seguro?',
        addGroupDialogTitle: 'Hazte amigo del grupo',
        befriendDescription: 'Hacerse amigo de un grupo le permite compartir datos de seguimiento con los miembros del grupo.',
        noOrgsFoundToFriend: 'No se encontraron organizaciones',
        searchForOrganisation: 'Buscar una organización',
        groupPublicKeyPrompt: 'La clave pública del grupo.',
        groupAdded: 'Grupo se hizo amigo con éxito.',
        groupRemoved: 'Grupo eliminado amigo con éxito.',
        addGroupError: 'No se pudo hacer amistad con el grupo.',
        removeGroupError: 'Error al eliminar amigos del grupo.',
        inviteButton: 'Invitación',
        befriendGroup: 'Agrega este grupo a tus amigos',
        unfriendGroup: 'Eliminar este grupo de tus amigos',
        getGroupsFailed: 'Error al obtener grupos, por favor recarga la página.',
        columns: {
          name: 'Nombre',
          publicKey: 'Llave pública',
          actions: 'Acciones'
        },
        status: {
          PendingMe: 'En espera de su aprobación',
          PendingThem: 'En espera de su aprobación',
          Active: 'Activa'
        },
        permission: {
          canViewCurrent: 'Ver senderos en vivo',
          canViewHistory: 'Ver senderos históricos',
          canSendConfiguration: 'Configurar dispositivo',
          canSendTextMessages: 'Enviar mensajes de texto',
          canViewForms: 'Ver formularios',
          canEditCallSign: 'Editar indicativo de activo',
        },
      },
    },
    reporting: {
      title: 'Informes',
      beta: 'Beta',
      nDaysRemain: '{n, plural, =0 {Ningún día} one {# día} other {# días}} restante',
      sections: {
        safety: 'Seguridad',
        reports: 'Informes',
        previewReports: 'Vista previa de las últimas innovaciones en informes',
      },
      approvalState: '{state, select, all {Aprobado} some {Aprobado parcialmente} none {No aprobado} noTrips {Sin viajes} other {}}',
      trackingDataReports: {
        title: 'Informes de datos de seguimiento',
        description: 'Ver y descargar informes de la actividad de los activos rastreados',
        selectAssetsPlaceholder: 'Seleccionar activos',
        noAssetsSelected: 'No se han seleccionado activos',
        noReportsFound: 'No reports found',
        unnamed: 'Dispositivo sin nombre',
        columns: 'Columnas',
        tableTitle: 'Informes de datos de seguimiento',
        exportTitle: 'Exportar informes de datos de seguimiento',
        exportDescription: 'Descargar informes de la actividad de los activos rastreados',
        export: 'Exportar',
        loading: 'Cargando',
        nReports: '{n} informes',
        headings: {
          name: 'Nombre del activo',
          model: 'Modelo',
          timestamp: 'Marca de tiempo',
          coordinates: 'Coordenadas',
          altitude: 'Altitud',
          track: 'Trayectoria',
          speed: 'Velocidad',
          event: 'Evento',
          latency: 'Estado latente',
          elapsed: 'Transcurrido',
          distance: 'Distancia',
          gateway: 'Puerta',
          battery: 'Batería',
          DOP: 'DOP',
          metadata: 'Metadatos',
        }
      },
      tripReports: {
        title: 'Informes de viaje',
        description: 'Ver y descargar informes de viajes completados por activos',
      },
      utilisation: {
        title: 'Informe de actividades',
        description: 'Estadísticas agregadas de la actividad de los activos',
      },
      tripAnalysis: {
        title: 'Análisis de viaje',
        description: 'Informes y análisis de viajes avanzados',
        descriptionLong: 'Procesamiento de datos avanzado para una actividad de vuelo más detallada, incluidos despegues y aterrizajes georreferenciados, análisis de altitud y velocidad, seguimiento del ciclo del motor y resúmenes de viajes de la flota.',
        promptSelectTrip: 'Seleccione un viaje de la línea de tiempo para ver sus detalles',
        trial: {
          blurb: 'Este informe está disponible como prueba para pruebas beta.',
          begin: 'Comenzar la prueba de 30 días',
          expired: 'Su acceso de prueba a este informe ha finalizado.',
        },
        infoMessage: {
          dismiss: 'Despedir',
          content: "Estás experimentando una publicación anticipada de 'Análisis de viaje'. Esta herramienta avanzada está en continuo desarrollo y mejora la información sobre la actividad de vuelo con cada actualización. <break></break>Sus comentarios nos ayudan a perfeccionar y evolucionar. Envíe sus comentarios a través del botón Soporte en la parte inferior derecha. <break></break>¡Gracias por ser parte de este viaje!",
        },
        download: {
          button: {
            summary: 'Resumen (CSV)',
            trips: 'Viajes (CSV)',
            engineCycles: 'Ciclos del motor (CSV)',
          },
          filename: {
            summary: 'Exportación de resumen de viaje de TracPlus',
            trips: 'Exportación de viajes TracPlus',
            engineCycles: 'Exportación de ciclos de motor de TracPlus',
            dateRange: '{from} a {until}',
          },
          columns: {
            asset: 'Activo',
            make: 'Marca',
            model: 'Modelo',
            from: 'Desde',
            to: 'Hasta',
            startEvent: 'Evento de inicio',
            startTime: 'Hora de inicio',
            startCoordinates: 'Coordenadas de inicio',
            endEvent: 'Evento final',
            endTime: 'Hora de finalización',
            endCoordinates: 'Coordenadas de finalización',
            distance: 'Distancia ({unit})',
            duration: 'Duración',
            tripCount: 'Viajes',
            totalDistance: 'Distancia total ({unit})',
            totalDuration: 'Duración total',
            engineUsageCount: 'Ciclos del motor',
            totalEngineDuration: 'Duración total del motor',
            engineStartTime: 'Hora de inicio del motor',
            engineEndTime: 'Hora de finalización del motor',
            engineDuration: 'Duración del motor',
          },
        },
        toggles: {
          table: 'Mesa',
          timeline: 'Línea de tiempo',
        },
        assetTable: {
          summary: {
            count: 'Viajes totales',
            duration: 'Duración total',
            distance: 'Distancia total',
            engineCycles: 'Ciclos totales del motor',
            engineDuration: 'Duración total del motor',
          },
          from: 'Desde',
          to: 'Hasta',
          duration: 'Duración',
          distance: 'Distancia',
        },
        engineCycles: {
          nCycles: '{n} ciclos',
          none: 'Ninguno detectado',
          tooltip: 'Obtenga más información sobre la detección del ciclo del motor',
          dialog: {
            title: 'Detección del ciclo del motor',
            description: 'Los ciclos del motor solo se detectan si el dispositivo de seguimiento de este activo admite eventos del motor y está instalado correctamente para registrarlos.',
            continue: 'Continuar',
          },
        },
        timeline: {
          nTrips: '{n} {n, plural, one {viaje} other {viajes}}',
          noTrips: 'No se detectaron viajes',
          nEngineCycles: '{n} {n, plural, one {ciclo de motor} other {ciclos de motor}}',
          noEngineCycles: 'No se detectaron ciclos del motor',
          loadingEngineCycles: 'Cargando ciclos del motor...',
        },
        popper: {
          trip: 'Viaje',
          duration: 'Duración',
        },
      },
      tripDetail: {
        tabs: {
          drops: '{n, plural, =0 {Sin goteos} one {1 goteo} other {# goteos}}',
        },
        drops: {
          columns: {
            startTime: 'Hora de inicio',
            endTime: 'Hora de finalización',
            duration: 'Duración',
            type: 'Tipo',
            volume: 'Volumen',
            endVolume: 'Volumen final',
            suppressant: 'Supresor',
            split: 'Dividir',
          },
          values: {
            splitDrop: '{splitDrop, select, true {Sí} other {No}}',
          },
        },
      },
      dailyFlightSummary: {
        title: 'Resumen de vuelo diario',
        description: 'Ver, editar y aprobar resúmenes de vuelos diarios',
        selectAssets: {
          label: 'Seleccionar activos',
          loading: 'Cargando activos...',
          all: 'Todos los activos',
          allDescription: 'Esto seleccionará todos los activos posibles que se pueden elegir.',
          noAssetGroupName: 'Sin nombre de grupo de activos',
        },
        getAssetListFailed: 'No se pudo obtener la lista de activos, vuelva a cargar la página.',
        infoMessage: {
          dismiss: 'Despedir',
          content: "Estás experimentando una publicación anticipada de 'Resumen de vuelo diario'. Esta herramienta avanzada está en continuo desarrollo y mejora la información sobre la actividad de vuelo con cada actualización.<break></break> Sus comentarios nos ayudan a perfeccionar y evolucionar. ¡Gracias por ser parte de este viaje!",
        },
        download: {
          button: {
            summary: 'Resumen (CSV)',
          },
          filename: {
            summary: 'Exportación del resumen diario de vuelos de TracPlus',
          },
          columns: {
            asset: 'Activo',
            make: 'Marca',
            model: 'Modelo',
            from: 'Desde',
            to: 'Hasta',
            takeoffEvent: 'Evento de despegue',
            takeoffTime: 'Hora de despegue',
            takeoffCoordinates: 'Coordenadas de despegue',
            landingEvent: 'Evento de aterrizaje',
            landingTime: 'Hora de aterrizaje',
            landingCoordinates: 'Coordenadas de aterrizaje',
            distance: 'Distancia ({unit})',
            flightDuration: 'Duración del vuelo',
            approvalStatus: 'Estado de aprobación',
          },
        },
      },
      assetFlightSummary: {
        title: 'Resumen del vuelo de {assetName} el {day}',
        description: 'Vuelos de este día',
      },
      missionReports: {
        title: 'Informes de misiones',
        description: 'Ver y descargar informes de misiones generados',
      },
      firefighting: {
        title: 'Combate aéreo de incendios',
        summaryTitle: 'Resumen de combate aéreo de incendios',
        downloadCSV: 'Descargar CSV',
        assetTitle: 'Detalles de combate aéreo de incendios para activos',
        description: 'Análisis detallado de las descargas por activo y tipo de supresor',
        noAssetsToDisplay: 'No hay activos para mostrar',
        loading: 'Cargando...',
        toggleStatsFilterEnabled: 'Ocultar viajes cortos de las estadísticas',
        toggleAssetsNoDrops: 'Ocultar activos sin caídas',
        unknownLocation: 'Ubicación desconocida',
        nDrops: '{n, plural, =0 {Sin descargas} one {# descarga} other {# descargas}}',
        nFills: '{n, plural, =0 {Sin empastes} one {# llenar} other {# llena}}',
        nSplitDrops: '{n, plural, other {# descargas divididas}}',
        suppressant: {
          Water: 'Agua',
          FreshWater: 'Agua dulce',
          SaltWater: 'Agua salada',
          Unknown: 'Desconocido',
          Retardant: 'Retardante',
          Foam: 'Espuma',
          Gel: 'Gel',
        },
        allSuppressant: 'Todos los {name}',
        toggleSuppressantLabel: 'Mostrar {name}',
        assetDetails: 'Detalles',
        dropsByAsset: 'Descargas por activo',
        chartBy: 'Gráfico por',
        totalVolume: 'Volumen total',
        totalFlightTime: 'Tiempo total de vuelo',
        volume: 'Volumen',
        dropCount: 'Recuento de descargas',
        stats: {
          averageDropsPerHour: 'Promedio de descargas por hora',
          averageVolumePerHour: 'Volumen medio por hora',
          averageDropsPerFlightHour: 'Descargas promedio por hora de vuelo',
          averageVolumePerFlightHour: 'Volumen medio por hora de vuelo',
          nDropsPerHour: '{n, number, ::.00} descargas/h',
          tooltips: {
            averageDropsPerHour: 'El número promedio de descargas por hora entre el primer llenado y la última caída de cada viaje.',
            averageVolumePerHour: 'El volumen promedio de descargas por hora entre el primer llenado y la última caída de cada viaje.',
            averageDropsPerFlightHour: 'El número promedio de descargas por hora del tiempo total de vuelo.',
            averageVolumePerFlightHour: 'El volumen medio de descargas por hora del tiempo total de vuelo.',
          },
        },
        selectAssets: {
          label: 'Compartir activos',
        },
        columns: {
          time: 'Hora y ubicación',
          dropVolume: 'Volumen de descarga',
          suppressant: 'Supresor',
          isAllowedInStats: 'Permitido en las estadísticas',
        },
        yes: 'Sí',
        no: 'No',
        notInTripDescription: 'Esta caída no pudo estar asociada a un viaje; esto afecta su inclusión en las estadísticas horarias de este activo',
        detailSummary: {
          fillLocation: 'Ubicación de relleno',
          fillDate: 'Fecha de llenado',
          dropLocation: 'Ubicación de entrega',
          dropDate: 'Fecha de entrega',
          totalFlightTime: 'Tiempo total de vuelo',
          totalDropVolume: 'Volumen total de descarga',
          averageDropRate: 'Tasa de descarga promedio',
          flightTimeDescription: 'Desde el primer llenado hasta el final de la última descarga',
          afterLastDrop: 'después de la última descarga',
          beforeNextFill: 'antes del próximo llenado',
          containerVolume: 'Volumen del contenedor',
          duration: 'Duración',
          rate: 'Tasa',
          speed: 'Velocidad',
          distance: 'Distancia',
          directDistance: 'Distancia directa',
          directDistanceDescription: 'Desde el primer llenado hasta la última descarga',
          directFlightDuration: 'Duracion del vuelo',
          type: {
            Drop: 'Descargar',
            SplitDrop: 'Descarga dividida',
            Fill: 'Llenar',
            Pump: 'Bomba',
            AbortedFill: 'Llenado abortado',
          },
          suppressant: {
            Water: 'de agua',
            FreshWater: 'de agua dulce',
            SaltWater: 'de agua salada',
            Unknown: 'de desconocido',
            Retardant: 'de retardante',
            Foam: 'de espuma',
            Gel: 'de gel',
          },
          suppressantNoVolume: {
            Water: 'Agua',
            FreshWater: 'Agua dulce',
            SaltWater: 'Agua salada',
            Unknown: 'Agregado',
            Retardant: 'Retardante',
            Foam: 'Espuma',
            Gel: 'Gel',
          },
          statsFilter: {
            allow: 'Permitir esta descarga para su uso en estadísticas',
            disallow: 'No permitir que esta caída se utilice para estadísticas',
          },
        },
        csv: {
          summary: {
            dropCount: 'Recuento de descargas',
            totalVolume: 'Volumen total ({unit})',
            totalFlightTimeHrs: 'Horas totales de vuelo',
            averageDropsPerHour: 'Promedio de descargas por hora',
            averageVolumePerHour: 'Volumen medio por hora ({unit})',
            averageDropsPerFlightHour: 'Descargas promedio por hora de vuelo',
            averageVolumePerFlightHour: 'Volumen medio por hora de vuelo ({unit})',
          },
          asset: {
            fillCoords: 'Llenar coordenadas',
            dropCoords: 'Descargas coordenadas',
            directDistance: 'Distancia directa ({unit})',
            flightDuration: 'Duración del vuelo (s)',
            suppressant: 'Supresor',
            volume: 'Volumen ({unit})',
            duration: 'Duración (s)',
            rate: 'Tasa ({unit})',
            speed: 'Velocidad ({unit})',
            distance: 'Distancia ({unit})',
          },
        },
      },
      geofenceEvents: {
        title: 'Eventos de geocerca',
        description: 'Ver y descargar eventos de geocerca',
        downloadCSV: 'Descargar CSV',
        download: {
          filename: 'Exportación de eventos de geocerca de TracPlus',
          dateRange: '{from} a {until}',
        },
        more: '{n} más...',
        columns: {
          asset: 'Activo',
          event: 'Evento',
          time: 'Hora',
          location: 'Ubicación',
          geofence: 'Geocerca',
          distance: 'Distancia',
          make: 'Marca',
          model: 'Modelo',

        },
        dataError: 'Un error inesperado impidió que se cargaran los datos',
        noGeofencesSelected: 'No se han seleccionado geocercas',
        noDirectionsSelected: 'No se han seleccionado direcciones',
        noLocationsSelected: 'No se han seleccionado ubicaciones',
        selectAssets: {
          label: 'Seleccionar activos',
          loading: 'Cargando activos...',
          all: 'Todos los activos',
          allDescription: 'Esto seleccionará todos los activos posibles que se pueden elegir.',
        },
        noAssetsSelected: 'No se han seleccionado activos',
        noData: 'No hay datos para mostrar',
        loading: 'Cargando...',
      },
      summary: {
        applyFilters: 'Aplicar filtros',
        cancel: 'Cancelar',
        metrics: '{count, plural, one {Métrica} other {Métricas}}',
        nAssets: '{n, plural, one {1 activo} other {# activos}}',
        notAvailable: 'No disponible',
        number: '{value, number, ::.}',
        numberWithUnit: '{value, number, ::.} {unitLabel}',
        loading: 'Cargando...',
        dataError: 'Un error inesperado impidió que se cargaran los datos',
        noData: 'No hay datos para mostrar',
        noColumnGrouping: 'Sin agrupación de columnas',
        groupBy: 'Agrupar por',
        dimension: {
          assetId: {
            name: 'Activo',
            filterLabel: 'Activos ({selected, number} seleccionados)',
          },
          assetModel: {
            name: 'Modelo de activo',
            filterLabel: 'Modelos de activo ({selected, number} seleccionados)',
          },
          assetGroupId: {
            name: 'Grupo de activos',
            filterLabel: 'Grupos de activos ({selected, number} seleccionados)',
          },
          category: {
            name: 'Categoría de activo',
            filterLabel: 'Categorías de activos ({selected, number} seleccionados)',
          },
          ownerId: {
            name: 'Propietario del activo',
            filterLabel: 'Propietarios de activos ({selected, number} seleccionados)',
          },
          billable: {
            name: 'Facturable',
            columnHeading: '{value, select, true {Facturable} false {No facturable} other {No establecido}}',
          },
        },
        metric: {
          tripCount: {
            name: 'Cantidad de viajes',
            description: 'El número de viajes',
          },
          distance: {
            name: 'Distancia',
            description: 'La distancia combinada de los viajes',
          },
          duration: {
            name: 'Duración',
            description: 'La duración combinada de los viajes',
          },
          activityCount: {
            name: 'Días activos',
            description: 'El número de días en los que se realizaron viajes',
          },
        },
      },
      tooltips: {
        amsl: 'Altura sobre el nivel medio del mar',
        agl: 'Altura sobre el nivel del suelo'
      },
      downloadPDF: 'Descargar PDF',
      downloadCSV: 'Descargar CSV',
      download: 'Descargar',
      start: 'Comienzo',
      end: 'Final',
      asset: 'Activo',
      make: 'Marca',
      model: 'Modelo',
      approvalStatus: 'Estado de aprobación',
      duration: 'Duración',
      time: 'Tiempo',
      byTime: 'A tiempo',
      timeline: 'Línea de tiempo',
      elevation: 'Elevación',
      speed: 'Velocidad',
      track: 'Trayectoria',
      transport: 'Transporte',
      altitude: 'Altitud',
      distance: 'Distancia',
      byDistance: 'A Distancia',
      takeoff: 'El Despegue',
      landing: 'El Aterrizaje',
      departure: 'Partida',
      arrival: 'Llegada',
      flightDuration: 'Duracion del vuelo',
      missionId: 'Identificación de la misión',
      from: 'Desde',
      to: 'a',
      getAssetListFailed: 'No se pudo obtener la lista de activos, vuelva a cargar la página.',
      getEventReportsFailed: 'No se pudieron obtener los informes de viaje para el intervalo de fechas seleccionado, vuelva a cargar la página.',
      selectAssets: {
        label: 'Seleccionar activos',
        loading: 'Cargando activos...',
        all: 'Todos los activos',
        allDescription: 'Esto seleccionará todos los activos posibles que se pueden elegir.',
      },
      date: 'Fecha',
      dateRange: '{from} a {until}',
      getReports: 'Obtener informes',
      displayTimesInUTC: 'Mostrar tiempos en UTC',
      displayEngineUsage: 'Mostrar ciclos del motor',
      displayGeonames: 'Mostrar nombres de ubicaciones conocidas',
      reports: 'informes',
      loadingTripReports: 'Cargando informes de viaje...',
      loadingTrips: 'Cargando viajes...',
      nTripReports: '{count} {count, plural, one {informe de viaje} other {informes de viaje}}',
      total: 'Total',
      exportFilename: 'Exportación de informes de viaje de TracPlus',
      latency: 'Latencia',
      startEvent: 'Evento de inicio',
      startTime: 'Hora de inicio',
      coordinates: 'Coordenadas',
      startCoordinates: 'Coordenadas de inicio',
      takeoffEvent: 'Evento de despegue',
      takeoffTime: 'Hora de despegue',
      takeoffCoordinates: 'Coordenadas de despegue',
      event: 'Evento',
      inferredEvents: 'Eventos inferidos',
      landingEvent: 'Evento de aterrizaje',
      landingTime: 'Hora de aterrizaje',
      landingCoordinates: 'Coordenadas de aterrizaje',
      endEvent: 'Evento de fin',
      endTime: 'Hora de fin',
      endCoordinates: 'Coordenadas de fin',
      supplementary: 'Dato suplementario',
      supplementaryComingSoon: 'Se podrán añadir datos complementarios a viajes o grupos de viajes. Los ejemplos incluyen referencia de contrato, detalles de llenado de combustible, descripciones de vuelos, detalles del piloto y la tripulación, etc. Esto estará disponible en TracPlus Cloud, pero también en nuestras aplicaciones. Actualmente se está trabajando en esta característica.',
      previous: 'Previo',
      next: 'Próximo',
      trips: {
        start: 'Inicio del viaje',
        end: 'Fin del viaje',
        failedToLoadData: 'No se pudieron cargar los datos del viaje, inténtelo de nuevo',
        enteredStart: 'Inicio ingresado',
        enteredEnd: 'Fin ingresado',
        detectedStart: 'Inicio detectado',
        detectedEnd: 'Final detectado',
        totals: 'Totales',
        distance: 'Distancia',
        details: 'Informe de viaje',
        duration: 'Duración de viaje',
        nTripReports: '{count} {count, plural, one {viaje} other {viajes}}',
        loadingTripReports: 'Cargando informes de viaje...',
        update: 'Actualizar viaje',
        loadEarlier: 'Cargar informes anteriores',
        loadLater: 'Cargar informes posteriores',
        getAssetListFailed: 'No se pudo obtener la lista de activos, vuelva a cargar la página.',
        fixIncorrect: 'Corregir viaje incorrecto',
        editDialogText: 'Elija dónde esperaba que comenzara y terminara el viaje; utilizaremos esta información para ayudar a mejorar la detección de viajes futuros. Puede tener varias horas de inicio y finalización.',
        clearEdits: 'Borrar cambios',
        delete: 'Eliminar viaje',
        submit: 'Guardar cambios',
        cancel: 'Cancelar',
      },
    },
    manage: {
      title: 'Administrar',
      sections: {
        tracking: 'Seguimiento',
        contacts: 'Contactos',
        groups: 'Grupos',
        locations: 'Ubicaciones',
        labsDescription: 'Vista previa de las últimas innovaciones de TracPlus',
      },
      assets: {
        title: 'Activos',
        description: 'Administre los activos rastreados',
      },
      devices: {
        title: 'Dispositivos',
        description: 'Administre los dispositivos de seguimiento',
      },
      beacon: {
        title: 'Beacon',
        description: 'Administrar acceso a Beacon',
      },
      contactGroups: {
        common: {
          contactGroupEdit: {
            basicDetails: {
              name: 'Nombre del grupo',
              save: 'Guardar',
              saving: 'Guardando...',
              cancel: 'Cancelar',
              validation: {
                nameMissing: 'Debe proporcionarse un nombre de grupo',
                nameExists: 'Ya existe un grupo con este nombre',
              },
            },
            remove: {
              remove: 'Eliminar',
              description: 'Elimine este grupo de contacto',
            },
          },
          contactGroupsTable: {
            default: 'Defecto',
            created: 'Recien agregado',
            columns: {
              groupName: 'Nombre del grupo',
              people: 'Personas',
              assignedAssets: 'Asignado a activos',
            },
            noPeople: 'No hay personas asignadas',
            tooltips: {
              view: 'Ver este grupo',
              edit: 'Editar este grupo',
              delete: 'Borrar este grupo',
            },
            unknownAsset: 'Activo desconocido',
          },
          contactGroupsAssetAssignmentTable: {
            columns: {
              selectAll: 'Seleccionar todo',
              asset: 'Activo',
              device: 'Dispositivo',
              contactGroup: 'Grupo de contacto',
            },
            noGroupAssigned: 'No se ha asignado ningún grupo',
            noGroupAssignedWithDefault: 'Usando el grupo predeterminado ({name})',
            noPeople: 'No hay personas asignadas',
            selectedAssets: 'Activos seleccionados',
            noSelectedAssets: 'Seleccione algunos activos para asignar',
            assign: 'Asignar grupo de contacto',
          },
          contactGroupPeopleTable: {
            expandRow: 'Expande la fila',
            collapseRow: 'Colapsar fila',
            columns: {
              nameAndRole: 'Nombre y función',
              voice: 'Voz',
              sms: 'SMS',
              email: 'Correo electrónico',
              language: 'Idioma',
            },
            noContactValues: 'No se han configurado {type, select, voice {números de teléfono de voz} sms {números de teléfono SMS} email {direcciones de correo electrónico} other {}} predeterminados',
            tooltips: {
              edit: 'Editar los detalles de contacto de esta persona',
              missingRequiredContacts: 'Esta persona no tiene un número de teléfono de voz o SMS predeterminado',
            },
          },
          contactGroupAssetsTable: {
            columns: {
              asset: 'Activo',
              device: 'Dispositivo',
            },
            unknownAsset: 'Activo desconocido',
          },
        },
      },
      people: {
        title: 'Contactos',
        description: 'Las personas de contacto de su organización y sus detalles',
        more: 'más',
        noPeopleAlert: 'Su organización no tiene contactos',
        columns: {
          name: 'Nombre',
          role: 'Rol',
          sms: 'SMS',
          voice: 'Voz',
          email: 'Correo Electrónico',
          language: 'Idioma',
          actions: 'Acciones'
        },
        created: 'Recien agregado',
        viewPerson: 'Ver persona',
        editPerson: 'Editar contacto',
        deletePerson: 'Eliminar contacto',
        addPerson: 'Agregar una persona de contacto',
        snackbar: {
          createdSuccessfully: '{name} creado con éxito',
        },
      },
      person: {
        editPerson: 'Editar persona',
        phoneNumber: 'Número de teléfono',
        emailAddress: 'Dirección de correo electrónico',
        basicDetails: {
          title: 'Detalles básicos',
          name: 'Nombre',
          role: 'Rol (opcional)',
          roleHelp: 'La posición, el deber o la relación de esta persona',
          prefLanguage: 'Idioma preferido',
          primaryVoiceNum: 'Número de teléfono de voz principal',
          cancel: 'Cancelar',
          save: 'Guardar',
          saving: 'Guardando...',
          removePerson: 'Eliminar esta persona de contacto',
          remove: 'Eliminar',
          language: {
            en: 'Inglés',
            es: 'Español',
            pt: 'Portugués',
          },
          validation: {
            nameExists: 'Ya existe una persona en su organización con este nombre',
            nameMissing: 'Debe proporcionarse un nombre',
          },
          snackbar: {
            updatedSuccessfully: '{name} actualizado con éxito',
            errorUpdating: 'Un error inesperado impidió que se cambiaran los detalles de esta persona',
            errorUpdating409: 'Los detalles de esta persona han cambiado desde la última vez que los cargó. Por favor revisa tus cambios y vuelve a intentarlo.',
            deletedSuccessfully: '{name} eliminado con éxito',
          },
        },
        phone: {
          title: '{type, select, phone {Contactos de voz} sms {Contactos de SMS} other {}}',
          description: 'Números de teléfono que se utilizarán para contactar a esta persona por {type, select, phone {llamada de voz} sms {SMS.} other {}}',
          smsDisclaimer: 'Al ingresar su número de teléfono aquí, podrá recibir mensajes SMS de TracPlus. La frecuencia de los mensajes depende del uso. Se pueden incurrir en costos por recibir y enviar mensajes a los dispositivos. Envía STOP en cualquier momento para dejar de recibir mensajes. Se aplican la <privacyPolicy>política de privacidad</privacyPolicy> y las <termsAndConditions>términos y condiciones</termsAndConditions> de TracPlus.',
          contactType: 'Número de teléfono',
          add: 'Agregar',
          contactRemoval: 'Eliminar este número de teléfono {type, select, phone {de voz} sms {SMS} other {}}',
          exists: 'Ya existe un contacto para este número de teléfono {type, select, phone {de voz} sms {SMS} other {}}',
          invalid: 'Este número de teléfono no es válido',
          missing: 'Se debe proporcionar un número de teléfono',
          noContacts: 'No se han agregado números de teléfono {type, select, phone {de voz} sms {SMS} other {}}',
          default: 'Predeterminado',
          tooltips: {
            edit: 'Editar este número de teléfono',
            delete: 'Eliminar este número de teléfono',
          },
          snackbar: {
            addContact: {
              success: '{type, select, phone {Voz} sms {SMS} other {}} número de teléfono {value} agregado con éxito',
              error: 'Error al agregar número de teléfono {type, select, phone {de voz} sms {SMS} other {}}',
            },
          },
        },
        email: {
          title: 'Contactos de correo electrónico',
          description: 'Direcciones de correo electrónico que se utilizarán para contactar a esta persona',
          contactType: 'Dirección de correo electrónico',
          add: 'Agregar',
          contactRemoval: 'Eliminar esta dirección de correo electrónico',
          exists: 'Ya existe un contacto para esta dirección de correo electrónico',
          invalid: 'Esta dirección de correo electrónico no es válida',
          noContacts: 'No se han agregado direcciones de correo electrónico',
          default: 'Predeterminado',
          tooltips: {
            edit: 'Editar esta dirección de correo electrónico',
            delete: 'Eliminar esta dirección de correo electrónico',
          },
          snackbar: {
            addContact: {
              success: 'Dirección de correo electrónico {value} agregada con éxito',
              error: 'Error al agregar dirección de correo electrónico',
            },
          },
        },
        groups: {
          title: 'Grupos',
          description: 'Todos los grupos que incluyen a esta persona',
          loading: 'Cargando grupos...',
          noGroups: '{person} no es miembro de ningún grupo',
          default: 'Predeterminado',
          columns: {
            group: 'Grupo',
            groupType: 'Tipo',
          },
          types: {
            ice: 'ICE',
            messagingWhitelist: 'Mensajería del dispositivo',
          },
        },
        missing: {
          title: 'Persona no encontrada',
          description: 'La persona que estaba buscando no existe.',
          link: 'Volver a todas las personas',
        },
      },
      ice: {
        title: 'Grupos de contacto de emergencia',
        description: 'Grupos de personas a notificar en caso de emergencia',
        tabs: {
          label: 'Navegación por pestañas para grupos de contacto de emergencia',
          groups: 'Grupos de contacto',
          assignAssets: 'Asignación masiva de activos',
        },
        all: {
          save: 'Guardar',
          createGroup: 'Crear un grupo de contacto',
          noGroupsAlert: 'Su organización debe tener al menos un grupo de contacto de emergencia. Por favor, cree uno ahora.',
          defaultUnhealthy: 'A las personas principales y secundarias del grupo predeterminado les falta un número de teléfono de voz o SMS',
        },
        create: {
          title: 'Crear nuevo grupo de contacto de emergencia',
          snackbar: {
            success: 'Nuevo grupo de contacto de emergencia, {name}, creado con éxito',
          },
        },
        edit: {
          title: 'Editar grupo de contacto de emergencia: {name}',
          basicDetails: {
            title: 'Detalles básicos',
            snackbar: {
              success: 'El nombre del grupo de contacto se cambió con éxito',
              error: 'Un error inesperado impidió que se cambiara el nombre del grupo',
            }
          },
          default: {
            set: 'Establecer como predeterminado',
            loading: 'Cargando...',
            canSet: 'Este grupo se puede establecer como el grupo de contacto de emergencia predeterminado de su organización.',
            cannotSet: 'Este grupo no se puede establecer como el grupo de contacto de emergencia predeterminado de su organización.',
            cannotSetExplanation: 'Debe asignarse una persona de contacto primaria y secundaria que tenga un número de teléfono de voz y un número de teléfono de SMS.',
            dialog: {
              title: 'Establecer grupo de contacto de emergencia predeterminado',
              message: '¿Está seguro de que desea establecer <strong>{name}</strong> como el grupo de contacto de emergencia predeterminado de su organización?',
              explanation: 'El grupo de contacto de emergencia predeterminado se utiliza para cualquier activo que no tenga uno asignado.',
              unexpectedError: 'Un error inesperado impidió que se estableciera el grupo como predeterminado',
              snackbar: {
                success: '{name} se estableció correctamente como grupo de contacto de emergencia predeterminado',
              },
            },
          },
          remove: {
            isDefaultDescription: 'Este es el grupo de contacto de emergencia predeterminado de su organización.',
            isDefaultBeforeRemoveDescription: 'Antes de eliminar este grupo, debe establecer otro grupo como predeterminado.',
          },
          people: {
            title: 'Personas',
            description: 'Todas las personas asignadas a este grupo de contacto',
            noPeople: 'Ninguna persona asignada',
            primary: 'Primaria',
            secondary: 'Secundaria',
            addButton: 'Agregar personas a este grupo',
            assignButton: 'Administrar personas en este grupo',
            dialog: {
              title: 'Asignar personas al grupo de contacto de emergencia: {group}',
            },
          },
          assets: {
            title: 'Activos',
            description: 'Todos los activos que se asignan a este grupo de contacto',
            noAssets: 'Ningún activo asignado',
            forDefaultGroup: 'Debido a que este es el grupo de contacto de emergencia predeterminado, también se utilizará para cualquier activo que no tenga un grupo asignado.',
            columns: {
              asset: 'Activo',
              device: 'Dispositivo',
            },
            assignButton: 'Asignar activos a este grupo',
            dialog: {
              title: 'Asignar activos a {group}',
            },
          },
          missing: {
            title: 'Grupo de contacto de emergencia no encontrado',
            description: 'El grupo de contacto de emergencia que buscaba no existe',
            link: 'Volver a todos los grupos de contacto de emergencia',
          },
        },
      },
      messagingWhitelists: {
        title: 'Grupos de contactos de mensajería de dispositivos',
        description: 'Grupos de personas que tienen permiso para enviar mensajes a dispositivos',
        tabs: {
          label: 'Navegación con pestañas para grupos de contactos de mensajería de dispositivos',
          groups: 'Grupos de contacto',
          assignAssets: 'Asignación masiva de activos',
        },
        all: {
          save: 'Guardar',
          createGroup: 'Crear grupo de contacto',
          noGroupsAlert: 'Su organización no tiene ningún grupo de contactos de mensajería de dispositivos.',
        },
        create: {
          title: 'Crear un nuevo grupo de contactos de mensajería del dispositivo',
          snackbar: {
            success: 'Nuevo grupo de contactos de mensajería del dispositivo, {name} creado correctamente',
          },
        },
        edit: {
          title: 'Editar grupo de contactos: {name}',
          basicDetails: {
            title: 'Detalles básicos',
            remove: 'Eliminar',
            removeDescription: 'Eliminar este grupo de contacto.',
            isDefaultDescription: 'Este grupo de contactos es el grupo de contactos de mensajería del dispositivo predeterminado de su organización..',
            isDefaultBeforeRemoveDescription: 'Antes de eliminar este grupo, debe establecer otro grupo como predeterminado.',
            snackbar: {
              success: 'El nombre del grupo de contacto se ha cambiado correctamente',
              error: 'Un error inesperado impidió que se cambiara el nombre del grupo',
            }
          },
          default: {
            set: 'Establecer por defecto',
            loading: 'Cargando...',
            canSet: 'Este grupo se puede configurar como el grupo de contactos de mensajería del dispositivo predeterminado de su organización.',
            dialog: {
              title: 'Establecer el grupo de contactos de mensajería del dispositivo predeterminado',
              message: '¿Está seguro de que desea configurar <strong>{name}</strong> como el grupo de contactos de mensajería del dispositivo predeterminado de su organización?',
              explanation: 'El grupo de contactos de mensajería del dispositivo predeterminado se utiliza para cualquier recurso que no tenga uno asignado.',
              unexpectedError: 'Un error inesperado impidió que se estableciera el grupo como predeterminado',
              snackbar: {
                success: '{name} se configuró correctamente como grupo de contactos de mensajería del dispositivo predeterminado',
              },
            },
          },
          remove: {
            isDefaultDescription: 'Este es el grupo de contactos de mensajería del dispositivo predeterminado de su organización.',
            isDefaultBeforeRemoveDescription: 'Antes de eliminar este grupo, debe establecer otro grupo como predeterminado.',
            snackbar: {
              success: 'Grupos de contactos de mensajería del dispositivo, {name} eliminado correctamente',
            },
          },
          people: {
            title: 'Personas',
            description: 'Todas las personas asignadas a este grupo de contacto',
            noPeople: 'No se ha asignado ninguna persona',
            primary: 'Primario',
            secondary: 'Secundario',
            expandRow: 'Expandir fila',
            collapseRow: 'Colapsar fila',
            columns: {
              nameAndRole: 'Nombre y cargo',
              voice: 'Voz',
              sms: 'SMS',
              email: 'Correo electrónico',
              language: 'Idioma',
            },
            noContactValues: 'No hay {type, select, voice {números de teléfono de voz} sms {números de teléfono de SMS} email {direcciones de correo electrónico} other {}} configurados',
            tooltips: {
              edit: 'Editar los detalles de contacto de esta persona',
              missingRequiredContacts: 'Esta persona no tiene un número de teléfono de voz o SMS predeterminado',
            },
            addButton: 'Agregar personas a este grupo',
            assignButton: 'Administrar personas en este grupo',
            dialog: {
              title: 'Asignar personas al grupo de contactos de mensajería del dispositivo: {group}',
            },
          },
          assets: {
            title: 'Activos',
            description: 'Todos los activos asignados a este grupo de contactos',
            noAssets: 'No se ha asignado ningún activo',
            forDefaultGroup: 'Debido a que este grupo es el grupo de contactos de mensajería del dispositivo predeterminado, también lo utilizarán todos los activos que no tengan uno asignado.',
            columns: {
              asset: 'Activo',
              device: 'Dispositivo',
            },
            assignButton: 'Asignar activos a este grupo',
            dialog: {
              title: 'Asignar activos a {group}',
            },
          },
          missing: {
            title: 'No se encontró el grupo de contactos de mensajería del dispositivo',
            description: 'El grupo de contactos de mensajería del dispositivo que estabas buscando no existe',
            link: 'Volver a todos los grupos de contactos de mensajería del dispositivo',
          },
        },
        assignAssets: {
          columns: {
            selectAll: 'Seleccionar todo',
            asset: 'Activo',
            device: 'Dispositivo',
            contactGroup: 'Grupo de contacto',
          },
          noGroupAssigned: 'No hay grupo asignado.',
          selectedAssets: 'Activos seleccionados',
          noSelectedAssets: 'Seleccione algunos activos para asignar',
          assign: 'Asignar grupo de contacto',
        },
      },
      assetGroups: {
        title: 'Grupos de activos',
        description: 'Grupos de activos que se pueden compartir con otras organizaciones',
        snackbar: {
          success: 'Grupo de activos, {name}, creado con éxito',
        },
        edit: {
          title: 'Editar grupo de activos: {name}',
          missing: {
            title: 'Grupo de activos no encontrado',
            description: 'El grupo de activos que estaba buscando no existe',
            link: 'Volver a todos los grupos de activos',
          },
          basicDetails: {
            title: 'Detalles básicos',
            name: 'Nombre del grupo',
            save: 'Guardar',
            saving: 'Guardando...',
            cancel: 'Cancelar',
            nameExists: 'Ya existe un grupo con este nombre',
            nameMissing: 'El nombre del grupo de activos no puede estar vacío',
          },
          assets: {
            title: 'Activos',
            description: 'Todos los activos que se asignan a este grupo de activos',
            unknownAsset: 'Activo desconocido',
            unknownDevice: 'Dispositivo/No desconocido',
            columns: {
              asset: 'Activo',
              device: 'Dispositivo',
            }
          },
          assetDialog: {
            selectedLabel: 'Activos seleccionados',
            unselectedLabel: 'Activos no seleccionados',
            save: 'Guardar',
            saving: 'Guardando...',
            cancel: 'Cancelar',
          },
          assignButton: 'Asignar activos',
          modifyButton: 'Administrar activos',
          noAssets: 'No se ha asignado ningún activo',
          snackbar: {
            rename: 'El nombre del grupo de activos se ha cambiado correctamente a {name}',
            assetsUpdated: 'Los activos se han actualizado correctamente',
          },
          manage: {
            title: 'Asignar activos a {group}',
          },
        },
        tableView: {
          columns: {
            name: 'Nombre del grupo de activos',
            assets: 'Activos',
          },
          tooltips: {
            view: 'Ver este grupo',
            edit: 'Editar este grupo',
            delete: 'Eliminar este grupo',
          },
        },
        createGroup: {
          createButtonText: 'Crear un grupo de activos',
          createDialogTitle: 'Crear un grupo de activos',
          textFieldLabel: 'Nombre del grupo de activos',
          cancelButtonText: 'Cancelar',
          savingButtonText: 'Guardando...',
          saveButtonText: 'Guardar',
          validation: {
            nameMissing: 'Debe proporcionarse un nombre',
            nameExists: 'El nombre ya existe para otro grupo',
          },
        },
        deleteGroup: {
          deleteDialogTitle: 'Eliminar grupo de activos',
          deleteDialogText: '¿Está seguro de que desea eliminar grupo de activos {groupName}?',
          cancelButtonText: 'Cancelar',
          deleteButtonText: 'Eliminar',
          deleteSnackText: 'Grupo de activos {groupName} eliminado',
          deletingButtonText: 'Eliminando...',
        },
      },
      eventNotifications: {
        title: 'Notificaciones de Eventos',
        description: 'Configure las notificaciones para grupos de eventos que se enviarán a grupos de contactos.',
        create: {
          text: 'Crear un nuevo grupo de notificaciones',
          title: 'Agregar nuevo grupo de notificaciones de eventos',
          cancelText: 'Cancelar',
          saveText: 'Guardar',
          savingText: 'Guardando...',
          success: 'Grupo de notificaciones de eventos {name} creado',
          error: 'No se pudo crear el grupo de notificación de eventos debido a un error',
          validation: 'Se requiere el nombre'
        },
        delete: {
          dialogTitle: 'Eliminar grupo de notificaciones de eventos',
          dialogText: '¿Estás seguro de que deseas eliminar el grupo de notificaciones {name}?',
          confirmText: 'Eliminar',
          cancelText: 'Cancelar',
          loadingText: 'Eliminando...',
          successText: 'El grupo de notificaciones de eventos {name} ha sido eliminado',
          errorText: 'El grupo de notificación de eventos {nombre} no se pudo eliminar debido a un error'
        },
        tableView: {
          columns: {
            name: 'Nombre del Grupo de Notificaciones',
            disabled: 'Desactivado',
            assetGroup: 'Grupos de Activos',
            events: 'Eventos de Notificación'
          },
          tooltips: {
            edit: 'Editar grupo de notificaciones',
            delete: 'Eliminar grupo de notificaciones',
            view: 'Mostrar grupo de notificaciones'
          },
          status: {
            noRelevantAssets: 'La función de notificaciones de eventos no está habilitada para ningún activo en los grupos asignados',
            someRelevantAssets: 'La función de notificaciones de eventos no está habilitada para algunos activos en los grupos asignados',
          },
        },
        edit: {
          title: 'Grupo de Notificación de Eventos: {name}',
          titleEdit: 'Editar Grupo de Notificación de Eventos: {name}',
          warnings: {
            neverNotificationAssets: 'Las funciones de notificación no están habilitadas para su organización. No se monitorearán los eventos.',
            noNotificationAssets: 'Ningún activo de los grupos seleccionados tiene habilitadas las funciones de notificación. No se supervisarán los eventos.',
            someNotificationAssets: 'Algunos activos de los grupos seleccionados no tienen habilitadas las funciones de notificación. Solo se supervisarán los eventos de los activos relevantes.',
            neverGeofenceAssets: 'Las funciones de geocerca no están habilitadas para su organización, pero existen eventos de geocerca. Los eventos de geocerca no serán monitoreados.',
            noGeofenceAssets: 'Ningún activo en los grupos elegidos tiene funciones de geovalla habilitadas. Los eventos de geocerca no serán monitoreados.',
            someGeofenceAssets: 'Algunos activos de los grupos elegidos no tienen habilitadas las funciones de geovalla. Sólo los activos relevantes serán monitoreados para eventos de geocerca.',
            noContact: 'El usuario {name} no tiene los métodos de contacto configurados necesarios para recibir notificaciones',
            more: 'Más...',
          },
          detailsSection: {
            name: 'Detalles',
            groupName: {
              label: 'Nombre del Grupo de Notificación',
              validations: {
                nameMissing: 'Debe proporcionarse un nombre',
                nameExists: 'El nombre ya existe para otro grupo'
              }
            },
            buttons: {
              cancel: 'Cancelar',
              save: 'Guardar',
              delete: 'Eliminar'
            },
            deleteLabel: 'Eliminar este grupo de notificación de eventos',
            snackbar: {
              success: 'Grupo de notificación de eventos actualizado',
              updateError: 'Error al actualizar el grupo de notificación de eventos'
            }
          },
          assetsSection: {
            name: 'Grupos de Activos',
            description: 'Grupos de activos a los que se aplicarán estas reglas de notificación',
            columns: {
              name: 'Nombre del Grupo de Activos',
              assets: 'Activos',
              noAssetsEnabled: 'No hay activos habilitados',
              nAssetsDisabled: '{n, plural, one {# activo deshabilitado} other {# activos inhabilitados}}',
            },
            tooltips: {
              assetsDisabled: 'La función de notificaciones no está habilitada para estos activos',
            },
            missingAssets: 'Ningún activo asignado al grupo',
            editText: 'Cambiar los grupos de activos para monitorear eventos',
            editButton: 'Administrar Grupos de Activos'
          },
          assetGroupsDialog: {
            cancelButton: 'Cancelar',
            saveButton: 'Guardar',
            availableHeader: 'Grupos de Activos Disponibles',
            selectedHeader: 'Grupos de Activos Seleccionados',
            title: 'Administrar Grupos de Activos para {name}',
            countLabel: '{count} Activo(s)'
          },
          rulesSection: {
            name: 'Eventos de Notificación',
            description: 'Detalles sobre qué eventos monitorear y a quién notificar.',
            addText: '',
            addButton: 'Agregar Nuevo Evento',
            eventGeofence: 'Geocerca',
            columns: {
              name: 'Nombre',
              eventCode: 'Evento',
              contactType: 'Tipo de Contacto',
              activeTime: 'Tiempo Activo',
              peopleGroups: 'Grupos de Contacto',
              noGeofenceAssets: 'Desactivado',
              someGeofenceAssets: 'Algunos activos',
            },
            rulesDialog: {
              cancelButton: 'Cancelar',
              saveButton: 'Guardar',
              closeButton: 'Cerrar',
              savingButton: 'Guardando...',
              availableHeader: 'Grupos Disponibles',
              selectedHeader: 'Grupos Seleccionados',
              titleCreate: 'Crear evento de notificación',
              titleEdit: 'Administrar evento de notificación',
              titleView: 'Ver evento de notificación',
              countLabel: '{count} Personas',
              sections: {
                schedule: 'Cronograma',
                scheduleDescription: 'Notificar solo si el evento ocurre dentro de un rango de tiempo elegido',
                contactGroups: 'Grupos de contacto',
                contactGroupsDescription: 'Grupos de personas a las que se les notificará cuando ocurra el evento.'
              },
              information: {
                additionalEvents: 'Para suscripciones a eventos adicionales, comuníquese con el soporte de TracPlus',
                lockedEvent: 'Este evento fue modificado por el equipo de soporte y está bloqueado. Comuníquese con el soporte de TracPlus para cualquier cambio',
                geofenceBreak: {
                  RestrictedArea: 'Los eventos se crearán cuando un activo ingrese a la geocerca.',
                  AreaOfOperation: 'Los eventos se crearán cuando un activo abandone la geocerca.',
                  Generic: 'Los eventos se crearán cuando un activo cruce el borde de la geocerca',
                }
              },
              labels: {
                name: 'Nombre *',
                event: 'Código de evento *',
                eventGroup: 'Evento *',
                timezone: 'Zona Horaria *',
                startTime: 'Hora de Inicio *',
                endTime: 'Hora de Fin *',
                geofence: 'Geocerca *'
              },
              validationMessages: {
                name: 'Se requiere un nombre',
                event: 'Se requiere Código de evento',
                eventGroup: 'Se requiere evento',
                geofence: 'Es necesario seleccionar una geocerca',
                contactType: 'Debe seleccionarse al menos 1 método de contacto',
                daysOfWeek: 'Se debe seleccionar al menos 1 opción',
                timezone: 'Se requiere la zona horaria',
                startTime: 'Se requiere hora de inicio',
                endTime: 'Se requiere hora de finalización',
                endTimeOrder: 'La hora de finalización debe ser posterior a la hora de inicio'
              },
              options: {
                transport: {
                  email: 'Correo electrónico',
                  sms: 'SMS',
                  notification: 'Notificación web',
                  mobile: 'Notificación móvil'
                },
                dayOfWeek: {
                  mon: 'Lunes',
                  tue: 'Martes',
                  wed: 'Miércoles',
                  thu: 'Jueves',
                  fri: 'Viernes',
                  sat: 'Sábado',
                  sun: 'Domingo'
                },
                sourceType: {
                  event: 'Código de evento (SÓLO PERSONAL)',
                  group: 'Eventos',
                },
                timeRestriction: {
                  anytime: 'SIEMPRE',
                  restricted: 'LIMITADO'
                }
              }

            },
            tooltips: {
              view: 'Ver las reglas para este evento',
              edit: 'Editar las reglas para este evento',
              delete: 'Eliminar este evento del grupo de notificación'
            },
            snackbar: {
              updateError: 'Se produjo un error al actualizar el evento',
              createError: 'Se produjo un error al crear el evento'
            },
            delete: {
              dialogTitle: 'Eliminar evento de notificación',
              dialogText: '¿Estás seguro de que quieres eliminar el evento {name}?',
              confirmText: 'Eliminar',
              cancelText: 'Cancelar',
              loadingText: 'Eliminando...',
              successText: 'El evento de notificación {name} ha sido eliminado'
            },
            typeDialog: {
              title: '¿Para qué tipo de evento le gustaría configurar las notificaciones?',
              event: {
                title: 'Actividad',
                subtext: 'Notificar sobre eventos de informes de activos, como despegues y aterrizajes.'
              },
              geofence: {
                title: 'Geocerca',
                subtext: 'Notificar cuando los activos entran o salen de áreas geográficas'
              },
              closeLabel: 'Cerca'
            }
          },
          missing: {
            title: 'Grupo de Notificación de Eventos no encontrado',
            description: 'El grupo de notificación de eventos que estabas buscando no existe',
            link: 'Volver a los grupos de notificación de eventos'
          }
        }
      },
      peopleGroups: {
        groupTypes: {
          notification: 'notificaciones',
        },
        title: 'Grupos de contacto',
        description: 'Grupos de personas en su organización',
        all: {
          save: 'Guardar',
          createGroup: 'Crear grupo de contacto',
          noGroupsAlert: 'Su organización no tiene ningún grupo de contacto de {groupType}.',
        },
        create: {
          title: 'Crear nueva grupos de {groupType}',
          snackbar: {
            success: 'Nueva grupos de {groupType}, {name} creada con éxito',
          }
        },
        edit: {
          title: 'Editar grupo de contactos: {name}',
          basicDetails: {
            title: 'Detalles básicos',
            remove: 'eliminar',
            removeDescription: 'Eliminar este grupo de contactos.',
            isDefaultDescription: 'Este grupo de contactos es el grupo de {groupType} predeterminado de su organización..',
            isDefaultBeforeRemoveDescription: 'Antes de eliminar este grupo, debe establecer otro grupo como predeterminado.',
            snackbar: {
              success: 'El nombre del grupo de contacto se ha cambiado correctamente',
              error: 'Un error inesperado impidió que se cambiara el nombre del grupo',
            },
          },
          remove: {
            isDefaultDescription: 'Este es el grupo de contacto predeterminado de su organización.',
            isDefaultBeforeRemoveDescription: 'Antes de eliminar este grupo, debe establecer otro grupo como predeterminado.',
            snackbar: {
              success: 'Grupos de {groupType}, {name} eliminada con éxito',
            },
          },
          people: {
            title: 'Personas',
            description: 'Todas las personas asignadas a este grupo de contacto',
            noPeople: 'No se ha asignado ninguna persona',
            primary: 'Primario',
            secondary: 'Secundario',
            expandRow: 'Expandir fila',
            collapseRow: 'Colapsar fila',
            columns: {
              nameAndRole: 'Nombre y cargo',
              voice: 'Voz',
              sms: 'SMS',
              email: 'Correo electrónico',
              language: 'Idioma',
            },
            noContactValues: 'No hay {type, select, voice {números de teléfono de voz} sms {números de teléfono de SMS} email {direcciones de correo electrónico} other {}} configurados',
            tooltips: {
              edit: 'Editar los detalles de contacto de esta persona',
              missingRequiredContacts: 'Esta persona no tiene un número de teléfono de voz o SMS predeterminado',
            },
            addButton: 'Agregar personas a este grupo',
            assignButton: 'Administrar personas en este grupo',
            dialog: {
              title: 'Asignar personas al grupo de contacto: {group}',
            },
          },
          missing: {
            title: 'Grupo de contacto no encontrado',
            description: 'El grupo de contacto que estabas buscando no existe',
            link: 'Volver a grupos de personas',
          },
          notificationgroups: {
            title: 'Grupos de notificación',
            description: 'Grupos de personas a los que se enviarán notificaciones',
            namesHeader: 'Nombres',
            rulesHeader: 'Reglas',
            notAssigned: 'No asignado de grupos de notificación',
          }
        },
      },
      geofencing: {
        title: 'Geocercas',
        createTitle: 'Crear nueva geocerca',
        editTitle: 'Geocerca: {name}',
        description: 'Crear y gestionar áreas geográficas para recibir notificaciones cuando se crucen',
        removeThisGeofence: 'Eliminar esta geocerca',
        notificationGroups: 'Grupos de Notificación',
        remove: 'Eliminar',
        errorsAndWarnings: {
          noData: 'No se han creado geocercas, por favor usa el botón de importación de archivos o añade una nueva geocerca en el mapa.',
          noMatchFilter: 'Ninguna geocerca coincide con los filtros aplicados',
          noGeofencesFound: 'Sin geocercas para su organización',
          noNotificationGroups: 'No se encontraron grupos de notificación',
          eventNotificationsNotEnabled: 'Las notificaciones de eventos no están habilitadas para esta organización',
          missingTitle: 'Geocerca no encontrada',
          description: 'La geocerca que buscas no existe',
          returnButton: 'Volver a la lista de geocercas',
        },
        import: {
          header: 'Importar desde archivo',
          dropFile: 'Soltar archivo',
          dropFilesHere: 'Suelte el archivo KML o KMZ aquí',
          clickToBrowse: 'Haz clic para buscar archivos',
          importText: 'Importar',
          replaceText: 'Reemplazar todas las formas existentes para esta geocerca',
        },
        dialogs: {
          cancelText: 'Cancelar',
          assetSelection: {
            title: 'Activos de Geocerca',
          },
          delete: {
            title: 'Eliminar Geocerca',
            text: '¿Estás seguro de que quieres eliminar esta geocerca?',
            confirmText: 'Eliminar',
            confirmTextPending: 'Eliminando...',
          },
          discard: {
            title: 'Descartar cambios',
            text: '¿Estás seguro de que quieres descartar estos cambios?',
            confirmText: 'Descartar',
          },
          name: {
            title: 'Establecer Nombre de Geocerca',
            label: 'Nombre',
            confirmText: 'Guardar',
            confirmTextPending: 'Guardando...',
            error: 'El nombre es requerido',
          },
        },
        snackbar: {
          updateSuccess: 'Geocerca {name} actualizada',
          updateError: 'Error al actualizar la geocerca {name}',
          deleteSuccess: 'Geocerca {name} eliminada',
          deleteError: 'Error al eliminar la geocerca {name}',
          createSuccess: 'Geocerca {name} creada',
          createError: 'Error al crear la geocerca',
          createWarningNoData: 'No se encontraron características para guardar',
          fileImportSuccess: 'Archivo {name} importado',
          fileImportError: 'Error al importar el archivo {name}',
        },
        map: {
          tooltips: {
            new: 'Dibujar nueva geocerca en el mapa',
            save: 'Guardar cambios',
            discard: 'Descartar cambios'
          },
          controls: {
            importShapes: 'Importar formas',
            addShape: 'Agregar forma',
            removeShape: 'Quitar forma',
            discardChanges: 'Descartar los cambios',
          },
          drawingInstructions: 'Haga clic para colocar las esquinas de la forma. Haz doble clic para completar la forma.',
          customAttribution: 'Mapas proporcionados por {provider}',
        },
        table: {
          header: 'Nombre',
          subHeader: 'Activos',
          overflow: '...y {count} más',
          warning: 'No hay activos asignados a la geocerca',
          buttons: {
            manageAssets: 'Gestionar Activos',
            rename: 'Renombrar',
            edit: 'Editar Geocerca',
            delete: 'Eliminar',
          },
          breakType: {
            description: 'El tipo de geocerca determina el desencadenante para crear una notificación',
            enter: 'Entrada',
            exit: 'Salida',
            both: 'Genérica',
          },
        },
        list: {
          createGeofence: 'Crear una nueva geocerca',
          createGeofenceFromFile: 'Crear geocerca desde archivo',
          editGeofence: 'Editar geocerca',
          fitMapToAll: 'Ajustar mapa',
          viewDetails: 'Ver detalles',
          goToGroup: 'Ir al grupo',
          events: 'Eventos',
          exit: 'salida',
          enter: 'ingresar',
          both: 'ingresar, salida',
          invalid: 'inválido',
          category: {
            geofenceCategory: 'Tipo de cruce de geocerca',
            enter: 'Sólo al entrar',
            exit: 'Sólo al salir',
            both: 'Al entrar y salir',
            anyCategory: 'Cualquier tipo de cruce',
          },
        },
        create: {
          addAltitude: 'Agregar límites de altitud',
          removeAltitude: 'Eliminar límites de altitud',
          maxAltitudeType: 'Referencia de altitud máxima',
          minAltitudeType: 'Referencia de altitud mínima',
          allGeofences: 'Todas las geocercas',
          MSL: 'Por encima del nivel medio del mar',
          AGL: 'Por encima del nivel del suelo',
        },
        fields: {
          name: 'Nombre',
          description: 'Descripción',
          cancel: 'Cancelar',
          save: 'Guardar',
          search: 'Buscar Geocercas',
          minAltitude: 'Altitud Mínima',
          maxAltitude: 'Altitud Máxima',
        },
        validationMessages: {
          name: 'Se requiere un nombre',
          description: 'Se requiere una descripción',
          altitude: 'Especificación de altitud incompleta',
          features: 'Se requiere una forma de geocerca',
        },
      },
      markers: {
        title: 'Marcadores',
        description: 'Crear y gestionar puntos de interés.',
        instruction: 'Haga clic en el mapa o introduzca coordenadas',
        dropFile: 'Soltar archivo TPP3 Marker aquí',
        dropFilesHere: 'Soltar archivo TPP3 Marker aquí',
        actions: {
          create: 'Crear nuevo marcador(es)',
          cancel: 'Cancelar',
          save: 'Ahorrar',
        },
        fields: {
          latitude: 'Latitud',
          longitude: 'Longitud',
        },
        table: {
          name: 'Nombre',
          actions: {
            show: 'Espectáculo',
            hide: 'Esconder',
            focus: 'Enfocar',
            unfocus: 'Desenfocar',
            edit: 'Editar',
            delete: 'Borrar',
          },
          validationMessages: {
            outOfRange: 'Valor fuera de rango',
          },
        },
        dialogs: {
          create: {
            title: 'Crear nuevo marcador',
          },
          edit: {
            title: 'Editar: {name}',
          },
        },
      },
      usersHistory: {
        title: 'Actividad de usuarios',
        description: 'Ver el historial de actividad de los usuarios',
        email: 'Correo electrónico',
        lastLogin: 'Último acceso',
        never: 'Nunca',
        name: 'Nombre',
        time: 'Tiempo',
        type: 'Tipo',
        ipAddress: 'Dirección IP',
        downloadCSV: 'Descargar CSV',
        staffOnlyWarning: 'Esta función solo es visible debido a su rol de personal. Póngase en contacto con el soporte para habilitar esta función.',
      },
      admin: {
        title: 'Administración',
      }
    },
    assets: {
      title: 'Activo',
      description: 'Todos los activos rastreados y las personas que pertenecen o se comparten con su organización.',
      editButton: 'Editar',
      deleteButton: 'Eliminar',
      newButton: 'Nuevo activo',
      showArchived: 'Mostrar recursos archivados',
      assetsTable: {
        name: 'Nombre',
        tailNumber: 'Número de la cola',
        category: 'Modelo',
        makeModelVariant: 'Categoría',
        installation: 'Dispositivo Asignado',
        deviceSerialNumber: 'Número de serie del dispositivo',
        tpSerial: 'Serie de TracPlus',
        imei: 'IMEI Número',
        manufacturerSerial: 'Serie del fabricante',
        callSign: 'Señal de llamada',
        messagingHandle: 'Asa de mensajería',
        watchlistGroup: 'Grupo de lista de vigilancia',
        owner: 'Propietario',
        iceContactGroup: 'Grupo de contacto de ICE',
        messagingWhitelist: 'Grupo de contactos de mensajería del dispositivo',
        noGroupAssigned: 'Ningún grupo asignado',
        noGroupAssignedWithDefault: 'Grupo predeterminado',
        defaultGroupTooltip: '{name} es el grupo predeterminado',
        actions: 'Acciones',
        editAsset: 'Editar activo',
        viewAsset: 'Ver activo',
        deleteAsset: 'Eliminar activo',
        search: 'Buscar',
      },
      okButton: 'Ok',
      cancelButton: 'Cancelar',
      loadAssetsListFailed: 'No se pudo obtener la lista de activos, vuelva a cargar la página para volver a intentarlo.',
      addNew: 'Agregar nuevo activo',
      create: {
        title: 'Agregar nuevo activo',
        type: 'Tipo',
        manufacturer: 'Fabricante',
        model: 'Modelo',
        variant: 'Variante',
        name: 'Nombre',
        tailNumber: 'Número de cola',
        messagingHandle: 'Manejo de mensajes',
        watchlistGroup: 'Grupo de lista de vigilancia',
        callSign: 'Señal de llamada',
        cancel: 'Cancelar',
        save: 'Guardar',
        saveAndAdd: 'Guardar y agregar otro',
        prompt: {
          group: '¿Qué tipo de activo desea rastrear?',
          type: `¿Qué tipo de {group, select,
            air {aeronave}
            land {vehículo}
            sea {embarcación}
            object {objeto}
            other {{group}}
          } es este?`,
          identify: `¿Cómo desea identificar este {category, select,
            unknown {objeto}
            other {{category}}
          } dentro de nuestro sistema?`,
        },
        group: {
          name: `{group, select,
            air {Aeronave}
            land {Vehículo terrestre}
            sea {Embarcación Marítima}
            person {Persona}
            object {Otro}
            other {{group}}
          }`,
          description: `{group, select,
            air {Avión, helicóptero o cualquier otro tipo de aeronave}
            land {Cualquier tipo de vehículo terrestre}
            sea {Cualquier tipo de embarcación}
            person {Una persona que lleva individualmente un dispositivo de seguimiento}
            other {Cualquier otro tipo de objeto}
          }`,
        },
        category: `{category, select,
          aircraft {Avión}
          helicopter {Helicóptero}
          gyrocopter {Girocóptero}
          drone {Zumbido}
          airship {Dirigible}
          balloon {Globo}
          rocket {Cohete}
          truck {Camión}
          fireTruck {Camión de bomberos}
          car {Coche}
          policeCar {Coche de policía}
          ambulance {Ambulancia}
          bicycle {Bicicleta}
          tractor {Tractor}
          bus {Autobús}
          minibus {Minibús}
          motorcycle {Motocicleta}
          motorHome {Casa rodante}
          powerboat {Lancha rápida}
          yacht {Yate}
          containerShip {Buque de contenedores}
          tug {Remolcador}
          container {Contenedor}
          unknown {Desconocido}
          other {{category}}
        }`,
        aircraft: {
          model: `Tipo de {category, select,
            aircraft {avión}
            helicopter {helicóptero}
            gyrocopter {girocóptero}
            drone {zumbido}
            other {{category}}
          }`,
          enterManually: '¿No puede encontrar su modelo? Ingresarlo manualmente',
          selectFromList: 'Seleccionar de la lista de modelos conocidos',
        },
        snackbar: {
          success: 'Nuevo activo, {name} agregado con éxito',
          error: 'Un error desconocido impidió que se agregara el activo. Inténtalo de nuevo.',
          errorMessagingHandle: 'El identificador de mensajería dado ya está en uso. Por favor, elija otro.',
        },
      },
    },
    devices: {
      title: 'Dispositivos',
      description: 'Todos los dispositivos de seguimiento que se asignan a los activos que pertenecen o se comparten con su organización.',
      editButton: 'Editar',
      deleteButton: 'Eliminar',
      newButton: 'Nuevo dispositivo',
      tpSerial: 'Serie de TracPlus',
      imei: 'IMEI Número',
      manufacturerSerial: 'Serie de fabricante',
      makeModel: 'Modelo',
      firmwareVersion: {
        label: 'Versión de firmware',
        help: 'La versión de firmware solo se muestra para dispositivos Rock7',
        outdated: 'Firmware desactualizado',
        unknown: 'Desconocido',
      },
      status: 'Estado',
      lastActive: 'Último Activo',
      assignedAsset: 'Activo asignado',
      unknownAsset: 'Activo desconocido',
      actions: 'Acciones',
      editDevice: 'Editar dispositivo',
      activated: 'Activo',
      deactivated: 'Inactive',
      suspended: 'Pausado',
      unknown: 'Desconocido',
      error: 'Error',
      archived: 'Archived',
      viewDevice: 'Ver Dispositivo',
      deviceSerialNumber: 'Número de serie del dispositivo',
      owner: 'Propietario',
    },
    beacon: {
      title: 'Beacon',
      description: 'TracPlus Beacon te permite monitorear la seguridad y ubicación de tu equipo. Administra qué usuarios pueden rastrearse con Beacon en la aplicación TracPlus para iOS y Android. Para monitorear a más miembros del equipo, contacta al soporte de TracPlus para aumentar tus cupos.',
      assignedLabel: '{assigned} de {total} asientos de Beacon asignados',
      unassignedLabel: 'Usuarios no asignados',
      trackingInProgress: 'Seguimiento en curso',
      lastTrackedAt: 'Último seguimiento el {at, date, medium}',
      setNumSeats: 'Set number of seats (STAFF ONLY)',
      cancel: 'Cancelar',
      save: 'Guardar',
      search: {
        label: 'Buscar usuarios por nombre o dirección de correo electrónico',
        placeholder: 'Buscar',
      },
      tooltips: {
        assignUser: 'Asignar usuario a un asiento',
        unassignUser: 'Desasignar usuario de su asiento',
        viewOnMap: 'Haga clic para ver el activo de Beacon en el mapa',
      },
      alerts: {
        noFilteredUsers: 'No hay personas que coincidan con esta búsqueda',
        noUsers: 'No quedan personas para asignar un asiento',
        noAssignedUsers: 'No se asignan personas a los asientos',
        noSeats: 'Esta organización no tiene asientos',
      },
      dialogs: {
        setNumberOfSeats: {
          title: 'Set number of Beacon seats',
          label: 'Number of Beacon seats',
        },
        saveSeats: {
          description: 'Cualquier usuario no asignado que actualmente esté rastreando con Beacon dejará de rastrear después de que la aplicación TracPlus en su dispositivo móvil haya establecido una conexión de red y haya sincronizado los informes de ubicación restantes.',
          confirmMessage: '¿Está seguro de que desea cambiar los usuarios asignados?',
          listUnassigned: 'Se darán de baja los siguientes usuarios:',
        },
      },
    },
    settings: {
      legacySettings: {
        title: 'Configuraciones',
        organisationSettingsButton: {
          title: 'Organización',
          description: 'Detalles de la organización y cuentas de usuario'
        },
      },
      userSettings: {
        title: 'Ajustes de usuario',
        movedTitle: 'La configuración de pantalla se ha movido',
        movedDescription: 'Accede a ellos desde la barra de estado en cualquier página',
        generalSettingsButton: {
          title: 'General',
          description: 'Ver y actualizar la configuración de su interfaz de usuario'
        },
        accountSettingsButton: {
          title: 'Cuenta',
          description: 'Ver y actualizar la configuración de su cuenta de usuario'
        }
      },
      organisationSettings: {
        title: 'Configuración de la organización',
        generalSettingsButton: {
          title: 'General',
          description: 'Ver y actualizar la configuración de esta organización'
        },
        usersSettingsButton: {
          title: 'Usuarias',
          description: 'Administrar cuentas de usuario para esta organización'
        },
        peopleSettingsButton: {
          title: 'Personas',
          description: 'Administrar los datos de contacto de las personas de esta organización'
        },
        ICESettingsButton: {
          title: 'Grupos de contacto de ICE',
          description: 'Administrar grupos de personas para ser notificados en caso de emergencia'
        },
        messagingWhitelistsSettingsButton: {
          title: 'Grupo de contactos de mensajería del dispositivo',
          description: 'Administrar grupos de personas que pueden enviar mensajes a los dispositivos'
        },
      },
    },
    assetsAndDevices: {
      title: 'Administrar',
      description: 'Administrar activos y dispositivos.',
      assetsTabTitle: 'Activos y Personas',
      devicesTabTitle: 'Dispositivos'
    },
    deviceView: {
      loading: 'Cargando...',
      general: {
        title: 'Dispositivo',
        description: 'Detalles generales de su dispositivo',
        tpSerial: 'Serie de TracPlus',
        imei: 'IMEI Número',
        manufacturerSerial: 'Serie del fabricante',
        name: 'Nombre',
        make: 'Dispositivo Marca',
        model: 'Dispositivo Modelo',
        status: 'Estado',
        lastActive: 'Última actividad',
        satNumber: 'Número de teléfono satelital',
        smsNumber: 'Número de SMS satelital',
        unknownStatus: 'Desconocido',
        firmwareOutdated: {
          title: 'El firmware está desactualizado',
          description: 'La última versión es {latest}',
          help: 'Aprenda a actualizar el firmware',
        },
      },
      configuration: {
        title: 'Configuración',
        description: 'Detalles de configuración para su dispositivo',
        lastUpdated: 'Última Actualización',
        unknown: 'Desconocida',
      },
      sendConfiguration: {
        buttonTitle: 'Enviar configuración',
        snackbar: {
          success: `{profile, select,
            Aviation {Aviación}
            AviationFastTrac {Aviación FastTrac}
            Maritime {Marítimo}
            MaritimeFastTrac {Marítimo FastTrac}
            Land {Terrestre}
            LandFastTrac {Terrestre FastTrac}
            FactoryReset {Restablecimiento de fábrica}
            other {{profile}}
          } perfil de configuración enviado al dispositivo con éxito`,
        },
        profile: {
          Aviation: 'Aviación',
          AviationFastTrac: 'Aviación FastTrac',
          Maritime: 'Marítimo',
          MaritimeFastTrac: 'Marítimo FastTrac',
          Land: 'Terrestre',
          LandFastTrac: 'Terrestre FastTrac',
          FactoryReset: 'Restablecimiento de fábrica',
        },
        dialog: {
          title: 'Enviar perfil de configuración',
          message: `¿Está seguro de que desea enviar el perfil de configuración predeterminado <strong>{profile, select,
            Aviation {Aviación}
            AviationFastTrac {Aviación FastTrac}
            Maritime {Marítimo}
            MaritimeFastTrac {Marítimo FastTrac}
            Land {Terrestre}
            LandFastTrac {Terrestre FastTrac}
            FactoryReset {Restablecimiento de fábrica}
            other {{profile}}
          }</strong> a este dispositivo?`,
          error: 'Un error inesperado impidió que se enviara la configuración',
          cancel: 'Cancelar',
          confirm: 'Confirmar',
        },
      },
      rates: {
        title: 'Frecuencia de seguimiento',
        description: 'Actualice las frecuencia de seguimiento para su dispositivo',
        cancel: 'Cancelar',
        updateRates: 'Frecuencia de actualización',
        updateRatesSuccess: 'Solicitud de cambio de frecuencia enviada con éxito. Espere unos minutos para que la solicitud llegue al dispositivo.',
        updateRatesFailed: 'No se pudo enviar el cambio de frecuencia. Inténtalo de nuevo.',
        requestConfigFailed: 'No se pudo solicitar la configuración actualizada. Inténtalo de nuevo.',
        CP_CHG_SAT_RT: {
          title: 'Frecuencia Satelital',
          description: 'La frecuencia a la que se transmiten las posiciones cuando se utiliza la red satelital'
        },
        CP_CHG_CEL_RT: {
          title: 'Frecuencia Celular',
          description: 'La frecuencia a la que se transmiten las posiciones cuando se usa la red celular'
        },
        CP_CHG_DS_RT: {
          title: 'Frecuencia de Socorro',
          description: 'La frecuencia a la que se transmiten las posiciones cuando el dispositivo está en peligro, sin importar la red'
        },
        CP_CHG_STD_RT: {
          title: 'Frecuencia estándar',
          description: 'La frecuencia a la que se transmiten las posiciones'
        },
        units: {
          rate: 'Frecuencia',
          seconds: `{value, plural,
            one {# segundo}
            other {# segundos}
          }`,
          minutes: `{value, plural,
            one {# minuto}
            other {# minutos}
          }`,
          hours: `{value, plural,
            one {# hora}
            other {# horas}
          }`,
        }
      },
      asset: {
        title: 'Activos',
        description: 'Activo al que está asignado este dispositivo',
        viewAsset: 'Ver activo',
        editAsset: 'Editar activo',
        name: 'Nombre',
        make: 'Dispositivo Marca',
        model: 'Dispositivo Modelo',
        owner: 'Propietario',
        messagingHandle: 'Asa de mensajería',
        variant: 'Variante',
        callSign: 'Señal de llamada',
        tailNumber: 'Número de la cola',
        errorAssignedDevice: '{statusCode, select, 404 {El activo asignado a este dispositivo no se comparte con su organización.} other {No se pudo recuperar el activo asignado a este dispositivo.}}',
      },
      missingDevice: 'El dispositivo que estabas buscando no existe',
      returnToDeviceList: 'Volver a la lista de dispositivos',
      unnamed: 'Dispositivo sin nombre'
    },
    createDevice: {
      createDevice: 'Crear dispositivo',
      devicesList: 'Lista de Dispositivos',
      generalDetailsStep: 'Detalles generales del dispositivo',
      deviceDescription: 'Ingrese los detalles de su dispositivo',
      serialNum: 'Dispositivo Número de serie',
      make: 'Dispositivo Marca',
      model: 'Dispositivo Modelo',
      imei: 'IMEI Nombre',
      man: 'MAN Número de serie',
      tp: 'TP Número de serie',
      status: 'Estado',
      create: 'Crear',
      cancel: 'Cancelar'
    },
    assetView: {
      titleNoName: 'Configuración de activo',
      'asset-settings': 'Configuración de activo',
      title: 'Detalles de activo',
      archived: 'archivado',
      ownedBy: 'Propiedad de {ownerName} (solo lectura).',
      assetDetailsSaved: 'Detalles del activo guardados',
      assetDetailsSaveFailed: 'No se pudo guardar el activo, inténtelo de nuevo',
      assetImageSaved: 'Imagen del activo guardados',
      assetImageSaveFailed: 'No se pudo guardar el imagen del activo, inténtelo de nuevo',
      assetImageRemoveFailed: 'Error al eliminar el imagen del activo, inténtelo de nuevo',
      assetImageRemoved: 'Imagen de activo eliminado',
      assetDetailsUniqueMessagingHandle: 'El identificador de mensajería ya está en uso. Elija un mango diferente.',
      assetPropertySaved: '{property} del activo guardada',
      description: 'Revise los detalles de tu activo y haga clic en el botón Guardar para confirmar cualquier cambio',
      imageTitle: 'Imagen del activo',
      imageDescription: 'Sube una imagen de tu activo',
      uploadImage: 'Cargar imagen',
      replaceImage: 'Reemplazar imagen',
      removeImage: 'Quita el imagen',
      cropImageDialogTitle: 'Recortar imagen',
      generalTitle: 'Detalles del activo',
      generalDescription: 'Detalles generales de tu activo',
      assetLabelMessage: `Los activos están etiquetados por su {assetLabelKey, select,
         callSign {distintivo de llamada}
         tailNumber {número de cola}
         other {nombre}
       } en toda la aplicación {assetLabelKey, select,
         callSign {con respaldo al nombre si no se establece un distintivo de llamada}
         tailNumber {con respaldo para nombrar si no se establece un número de cola}
         other {}
       }.<break></break> Puede cambiar su etiqueta preferida en <link>Configuración general</link>.`,
      name: 'Nombre',
      category: 'Categoría',
      make: 'Hacer',
      model: 'Modelo',
      variant: 'Variante',
      messagingHandle: 'Distintivo de mensajería',
      watchlistGroup: 'Grupo de lista de vigilancia',
      tailNumber: 'Número de la cola',
      callSign: 'Señal de llamada',
      deviceTitle: 'Dispositivo',
      deviceDescription: 'Dispositivo asignados a tu activo',
      serial: 'Número de serie',
      tpSerial: 'Serie de TracPlus',
      imei: 'IMEI',
      manufacturerSerial: 'Serie del fabricante',
      makeModel: 'Modelo',
      status: 'Estado',
      actions: 'Acciones',
      unassignedDevice: 'Agregar dispositivo no asignado',
      assignDevice: 'Asignar dispositivo',
      unassignDevice: 'Desasignar dispositivo',
      noUnassignedDevices: 'Todos tus dispositivos están asignados actualmente',
      displayTitle: 'Visualización del mapa',
      displayDescription: 'Cómo aparece tu activo en la vista de visualización',
      trailColor: 'Color del track',
      noColor: 'Sin conjunto de colores',
      trailColorDescription: 'Los senderos aparecerán en este color en el mapa.',
      trailColorNote: 'Esta configuración se aplica a toda su organización.',
      failedToSave: 'Error al guardar',
      saving: 'Guardando',
      saved: '¡Guardado!',
      save: 'Guardar',
      cancel: 'Cancelar',
      delete: 'Remover',
      confirmAssignDeviceTitle: '¿Asignar dispositivo al activo?',
      confirmAssignDeviceText: '¿Seguro que quieres asignar {device} a {assetName}?',
      confirmModifySharedAssignment: 'Este activo se comparte con las organizaciones a continuación; probablemente necesitarán ser informados de este cambio.',
      confirmUnassignDeviceTitle: '¿Desasignar dispositivo del activo?',
      confirmUnassignDeviceText: '¿Estás seguro de que deseas eliminar {device} de {assetName}?',
      okButton: 'Ok',
      cancelButton: 'Cancelar',
      noDeviceAssigned: 'Ningún dispositivo asignado',
      noUnassignedDevicesAvailable: 'No hay dispositivos no asignados disponibles',
      deviceAlreadyAssigned: 'Dispositivo ya sin asignación',
      assigned: 'Asignado',
      unassigned: 'No asignado',
      never: 'Nunca',
      missingAsset: 'El activo que buscaba no existe',
      image: 'Imagen',
      systemAsset: 'Aquí hay un activo del sistema que actúa como una protección para permitir que {make} {model} {serial} se vea en el mapa. Para un seguimiento con todas las funciones, este dispositivo de seguimiento debe asignarse a un activo real.',
      amsSettings: {
        title: 'Configuración de monitoreo activo',
        description: 'Configure los ajustes de monitoreo activo para este activo.',
        ofConcernTimeout: 'Tiempo de espera de preocupación',
        overdueTimeout: 'Tiempo de espera vencido',
        stationaryThreshold: 'Conteo estacionario',
        stationaryEnabled: 'Habilitar detección estacionaria',
        minutes: 'minutos',
        reports: 'informes',
        overdue: 'Atrasado',
        assetDeregistered: 'Este activo no está registrado para monitoreo activo. Este activo no será monitoreado',
        assetUnsupported: 'Este activo no es compatible con AMS',
        ofConcern: 'De preocupación',
        overdueInvalid: 'Este valor debe ser mayor que 0 y menor que 100',
        ofConcernInvalid: 'Este valor debe ser mayor que 0 y menor que 100',
        stationaryInvalid: 'Este valor debe ser mayor que 0',
        minutesWithoutReporting: '{time, plural, one {# minuto} other {# minutos}} {br} sin informar',
        stationaryReports: `{reports, plural,
            one {# informe estacionario}
            other {# informes estacionarios}
            }`,
        or: 'o',
        normalTracking: 'Informes Normales',
        saveButton: 'Guardar',
        cancelButton: 'Cancelar',
        deregisterButton: 'Darse de baja',
        registerButton: 'Registrar',
        updateAmsError: 'No se pudo actualizar la configuración de Monitoreo activo. Inténtalo de nuevo.',
        amsUpdated: 'Configuración de monitoreo activo guardada',
        amsUnsetError: 'No se pudo eliminar la configuración de Supervisión activa. Inténtalo de nuevo.',
        amsUnset: 'Se eliminó la configuración de Monitoreo activo',
        confirmDeregisterTitle: '¿Estás seguro de que quieres cancelar el registro de este dispositivo de AMS?',
        confirmDeregisterDescription: 'Cualquier sesión de AMS que el dispositivo intente iniciar no tendrá éxito.'
      },
      incidentSuppression: {
        title: 'Configuración de escalada de incidentes',
        description: 'Configure los ajustes de escalamiento para este activo.',
        isMonitored: 'Este activo está actualmente monitoreado.',
        isMonitoredDescription: 'Cualquier incidente planteado por este dispositivo se escalará a sus contactos de ICE y/o al equipo de soporte de TracPlus.',
        suspend: 'Suspender el seguimiento',
        isNotMonitored: 'La escalada de incidentes de este recurso se ha pausado hasta el ',
        enableMonitoring: 'Habilitar el monitoreo ahora',
        suspendFor: 'Suspender por',
        timeHours: `{hours, plural,
            one {# hora}
            other {# horas}
            }`,
        cancel: 'Cancelar',
        incidentEscalationResumed: 'La escalada del incidente se reanudó con éxito.',
        incidentEscalationResumeFailed: 'No se pudo reanudar la escalada del incidente. Inténtalo de nuevo.',
        incidentEscalationSuspended: 'La escalada del incidente se suspendió con éxito.',
        incidentEscalationSuspendFailed: 'No se pudo suspender la escalada del incidente. Inténtalo de nuevo.',
        confirmSuspendTitle: '¿Estás seguro de que quieres suspender la escalada?',
        confirmSuspendDescription: 'Cualquier incidente planteado por este dispositivo no se escalará a sus contactos de ICE o al equipo de soporte de TracPlus hasta el: ',
        suppressDisabledAlert: 'No puede suprimir incidentes para un activo con AMS registrado.',
        noDeviceError: 'Se debe asignar un dispositivo para configurar los ajustes de escalada'
      },
      rows: 'filas',
      of: 'de',
      firstPage: 'Primera Página',
      nextPage: 'Siguiente Página',
      previousPage: 'Página Anterior',
      lastPage: 'Última Página',
      assign: 'Asignar',
      assignDeviceDialogTitle: 'Asignar un dispositivo',
      assignDeviceDialogContent: 'Seleccione un dispositivo para asignar a su activo.',
      changeDevice: 'Cambio',
      changeDeviceDialogTitle: 'Cambiar asignación de dispositivo',
      changeDeviceDialogContent: 'Seleccione el nuevo dispositivo para asignar a su activo.',
      removeDeviceFromAssetFailed: 'No se pudo eliminar este dispositivo de este activo.',
      removeDeviceFromAssetSucceeded: 'El dispositivo se eliminó correctamente de este recurso.',
      removeDevice: 'Remover',
      removeDeviceDialogTitle: 'Eliminar asignación de dispositivo',
      removeDeviceDialogText: 'Confirme que desea eliminar este dispositivo de este recurso.',
      removeDeviceConfirmationButton: 'Retire el dispositivo',
      device: 'Dispositivo',
      changeDeviceButton: 'Cambia el dispositivo',
      selectDeviceButton: 'Seleccione el dispositivo',
      assignDeviceToAssetFailed: 'No se pudo asignar un dispositivo a este recurso.',
      getDevicesFailed: 'No se pudo recuperar una lista de dispositivos.',
      assignDeviceToAssetSaved: 'El dispositivo se asignó correctamente a este recurso.',
      confirmChangeDeviceDialogTitle: '¿Por favor confirmar?',
      confirmChangeDeviceDialogDevice: 'Dispositivo',
      confirmChangeDeviceDialogWillBeAssignedTo: 'será asignado a',
      confirmChangeDeviceDialogWillBeUnassignedFrom: 'será desasignado de',
      confirmChangeDeviceDialogAndAssignedTo: 'y asignado a',
      unknownStatus: 'Desconocido',
      iceContactGroup: {
        title: 'Grupo de contacto de ICE',
        description: 'El grupo de contacto para las personas que serán contactadas en caso de emergencia para este activo',
        view: 'Ver este grupo de contacto de ICE',
        edit: 'Editar este grupo de contacto de ICE',
        loading: 'Cargando grupo de contacto...',
        noGroupAssigned: 'No hay un grupo de contacto de ICE asignado y no hay un grupo de contacto de ICE predeterminado para su organización.',
        noGroupAssignedWithDefault: 'No se ha asignado ningún grupo de contacto de ICE. Se utilizará el grupo de contacto predeterminado, {name}.',
        noGroups: 'Su organización no tiene grupos de contacto de ICE.',
        noPeople: 'Este grupo de contactos no tiene personas asignadas. Se utilizarán personas del grupo de contacto predeterminado.',
        assign: 'Asignar grupo de contacto ICE',
        create: {
          title: 'Crear nuevo grupo de contacto ICE',
          snackbar: {
            success: 'Nuevo grupo de contacto de ICE, {name} creado con éxito',
          },
        },
      },
      messagingWhitelistContactGroup: {
        title: 'Grupo de contactos de mensajería del dispositivo',
        description: 'El grupo de contacto para las personas que pueden enviar mensajes al dispositivo que rastrea este activo',
        view: 'Ver este grupo de contactos de mensajería de dispositivos',
        edit: 'Editar este grupo de contactos de mensajería de dispositivos',
        loading: 'Cargando grupo de contactos de mensajería del dispositivo...',
        noGroupAssigned: 'No hay ningún grupo de contacto de mensajería del dispositivo asignado y no hay ningún grupo de contacto de mensajería del dispositivo predeterminado para su organización.',
        noGroupAssignedWithDefault: 'No se ha asignado ningún grupo de contactos de mensajería del dispositivo. Se utilizará el grupo de contactos de mensajería predeterminado del dispositivo, {name}.',
        noGroups: 'Su organización no tiene grupos de contactos de mensajería de dispositivos.',
        noPeople: 'Este grupo de contactos de mensajería del dispositivo no tiene personas asignadas. Se utilizarán personas del grupo de contactos de mensajería predeterminado del dispositivo.',
        assign: 'Asignar grupo de contactos de mensajería del dispositivo',
        create: {
          title: 'Crear un nuevo grupo de contactos de mensajería del dispositivo',
          snackbar: {
            success: 'Nuevo grupo de contactos de mensajería del dispositivo, {name} creado correctamente',
          },
        },
      },
      archiveDescription: 'Esto oculta el activo de la vista de mapa y la lista de activos. No se pierde ningún dato.',
      archivedDescription: 'Este recurso está archivado. Desarchivarlo para verlo en la lista de activos.',
      deviceAssignDisabled: 'No puede asignar un dispositivo a este activo, ya que este activo está archivado.',
      archive: 'Recurso',
      archival: 'Archivo',
      archivalUpdated: 'Estado de archivo de {name} actualizado',
      unarchive: 'Desarchivar',
      editCallSign: {
        edit: 'Editar',
        snackbar: {
          success: 'Indicativo de llamada del activo actualizado correctamente',
        },
        dialog: {
          title: 'Editar indicativo de llamada para {asset}',
          description: '{owner} le ha otorgado permiso para editar el indicativo de llamada de este activo.',
          callsign: 'Indicativo de llamada',
          cancel: 'Cancelar',
          save: 'Guardar',
          saving: 'Guardando...',
          error: 'Un error inesperado impidió que se guardara este indicativo de llamada. Por favor, inténtelo de nuevo.',
        },
      },
      sharingTo: {
        title: 'Compartición',
        description: 'Detalles de cómo se comparte este activo con su organización',
        loading: 'Cargando compartidos...',
        owner: 'Este activo es compartido por {owner}',
        explanation: 'A continuación, se muestran los intervalos de fechas y los permisos para cada compartición.',
        dateRange: 'Intervalo de fechas',
        viewTrails: 'Ver senderos',
        canViewForms: 'Ver formularios',
        canSendTextMessages: 'Mensaje',
        canSendConfiguration: 'Configurar',
        canEditCallSign: 'Editar indicativo de llamada',
        allTrails: 'En vivo e históricos',
        liveTrails: 'Solo en vivo',
        historicTrails: 'Solo históricos',
        noTrails: 'Ninguno',
        sharedViaGroup: 'Compartido a través del grupo {group}',
        notes: 'Notas',
      },
    },
    createAsset: {
      createAsset: 'Crear activo',
      landSeaAirStep: 'Tierra, mar o aire',
      landSeaAirDescription: '¿Dónde estará operando su activo?',
      categoryStep: 'Selecciona una categoría',
      categoryDescription: '¿Cuál es tu activo?',
      assetStep: 'Identificar activo',
      assetDescription: 'Ingrese los detalles de sus activos',
      name: 'Nombre',
      nameHelper: 'Por ejemplo: registro de aeronave',
      make: 'Marca',
      makeHelper: 'Por ejemplo: Boeing',
      model: 'Modelo',
      modelHelper: 'Por ejemplo: 747',
      variant: 'Variante',
      variantHelper: 'Por ejemplo: A (opcional)',
      color: 'Color',
      air: 'Aire',
      land: 'Tierra',
      sea: 'Mar',
      other: 'Otro',
      heartbeat: 'Latido del corazón',
      drone: 'Zumbido',
      person: 'Persona',
      worker: 'Trabajador',
      'executive man': 'Ejecutivo',
      'executive woman': 'Ejecutiva',
      bicycle: 'Bicicleta',
      truck: 'Camión',
      bus: 'Autobús',
      ambulance: 'Ambulancia',
      car: 'Coche',
      'fire truck': 'Camión de bomberos',
      'police car': 'Coche de policía',
      minibus: 'microbús',
      'mobile home': 'Casa móvil',
      motorcycle: 'Motocicleta',
      tractor: 'Tractor',
      yacht: 'Yate',
      tug: 'Tirón',
      'container ship': 'Barco mercante',
      powerboat: 'Lancha fuera borda',
      container: 'Envase',
      aircraft: 'Aeronave',
      airship: 'Dirigible',
      helicopter: 'Helicóptero',
      balloon: 'El globo',
      rocket: 'Cohete',
      unknown: 'Desconocido',
      system: 'Sistema'
    },
    generalSettings: {
      title: 'Configuración general',
      userInterfaceLabel: 'Interfaz de usuario',
      userInterfaceDescription: 'Administre la apariencia de la interfaz de usuario en toda la aplicación.',
      darkModeLabel: 'Modo de color',
      darkMode: 'Modo oscuro',
      lightMode: 'Modo de luz',
      systemMode: 'Sistema',
      defaultSerialType: 'Número de serie para dispositivos',
      tpSerial: 'Serie de TracPlus',
      imei: 'IMEI',
      manufacturerSerial: 'Serie del fabricante',
      rowsPerPage: 'Filas por página en tablas',
      rows: 'filas',
      assetLabel: 'Etiqueta de activo',
      fallbackToName: 'Vuelve al nombre si no está configurado',
      name: 'Nombre',
      callSign: 'Señal de llamada',
      tailNumber: 'Número de cola',
      assetDetailToDisplay: 'Detalles de activo para mostrar',
      displaySettings: 'Configuración de pantalla',
      unitsLabel: 'Unidades',
      unitsDescription: 'Administre las unidades de medida que se utilizan en toda la aplicación.',
      distanceLabel: 'Distancia',
      speedLabel: 'Velocidad (Predeterminado)',
      speedLabelAir: 'Velocidad (Aeronave)',
      speedLabelLand: 'Velocidad (Vehículo terrestre)',
      speedLabelSea: 'Velocidad (Embarcación)',
      speedLabelPerson: 'Velocidad (Persona)',
      altitudeLabel: 'Altitud',
      bearingLabel: 'Trayectoria',
      coordinateLabel: 'Formato de coordenadas',
      areaLabel: 'Área',
      volume: 'Volumen',
      duration: 'Duración',
      resetButton: 'Restablecen a los valores predeterminados',
      eventNotifications: {
        landingLabel: 'Notificaciones de mapa para eventos de aterrizaje',
        takeoffLabel: 'Notificaciones de mapa para eventos de despegue',
        enabled: 'Activado',
        disabled: 'Discapacitados',
      },
      warnings: {
        usercodeBrowserStorageOnly: 'Ha iniciado sesión con un código de usuario. Estas configuraciones solo se almacenarán en este navegador web y no se guardarán en la cuenta.',
      },
      unitDescriptions: {
        coordinatesDD: 'Decimal',
        coordinatesDMS: 'DMS',
        coordinatesDDM: 'DM',
      },
      tooltips: {
        kilometres: 'Kilómetros',
        statuteMiles: 'Millas estatutas',
        nauticalMiles: 'Millas náuticas',
        kmh: 'Kilómetros por hora',
        mph: 'Millas por hora',
        knots: 'Nudos',
        metres: 'Metros',
        feet: 'Pies',
        degreesTrue: 'Grados (verdadero)',
        degreesMagnetic: 'Grados (Magnético)',
        coordinatesDD: 'Grados decimales',
        coordinatesDMS: 'Grados, minutos y segundos',
        coordinatesDDM: 'Grados y minutos decimales',
        squareKilometres: 'Kilómetros cuadrados',
        acres: 'Acres',
        hectares: 'Hectareas',
        squareMiles: 'Millas cuadradas',
        squareNauticalMiles: 'Millas náuticas cuadradas',
        litres: 'Litros',
        gallons: 'E.U. Galones',
        hoursMinutes: 'Horas y minutos',
        decimalTime: 'Horas decimales',
      },
    },
    accountSettings: {
      title: 'Configuraciones de la cuenta',
      nameLabel: 'Nombre',
      roleLabel: 'El Oficio',
      emailLabel: 'Email',
      passwordLabel: 'Contraseña',
      tableActions: 'Acciones',
      orgTitle: 'Título de Organización',
      orgDescription: 'Descripción de la organicación',
      orgTableName: 'Nombre de la organización',
      orgTableRole: 'Tu rol',
      leave: 'Abandonar',
      leaveOrg: 'Abandonar {orgName}',
      leaveOrgConfirm: '¿Estás seguro de que quieres abandonar {orgName}?',
      cancel: 'Cancelar',
      userTitle: 'Perfil del usuario',
      userDescription: 'Detalles de su cuenta.',
      userButton: 'Guardar',
      editButton: 'Editar organización',
      organisations: 'Organizaciones',
      newPassword: 'Cambia tu contraseña',
      newPasswordSuccess: 'Correo electrónico de restablecimiento de contraseña enviado a:',
      newPasswordError: 'Verifique que su usuario tenga una dirección de correo electrónico válida',
      savedChanges: 'Perfil de usuario guardado',
      failedToSaveUser: 'No se pudo guardar el usuario, vuelve a cargar la página e inténtalo de nuevo.',
      wrongPassword: 'La contraseña que ingresó es incorrecta, vuelva a cargar la página y vuelva a intentarlo.',
      emailInUse: 'No se pudo cambiar el correo electrónico, este correo electrónico ya está en uso.',
      nameIsRequired: 'Nombre requerido',
      emailRequired: 'Dirección de correo requerido',
      invalidEmail: 'Dirección de correo electrónico inválido',
      reauthenticateHeader: 'Verifique por favor su Identidad'
    },
    organisationSettings: {
      missing: {
        title: 'Organización no encontrada',
        description: 'No tienes acceso a la configuración de esta organización.',
        link: 'Volver a la configuración',
      },
      title: 'Configuraciones de organización para {orgName}',
      nameLabel: 'Nombre',
      emailLabel: 'Correo electrónico',
      tableName: 'Nombre',
      tableDetails: 'Detalles',
      tableDetailsSubtext: 'Detalles sobre su organización',
      orgNameUpdateFailed: 'No se pudo actualizar el nombre de la organización, vuelva a cargar la página y vuelva a intentarlo',
      orgNameUpdated: 'Nombre de la organización actualizado con éxito',
      tableLanguage: 'Idioma',
      tableRoles: 'Rol del usuario',
      tableActions: 'Acciones',
      deleteButton: 'Eliminar',
      inviteButton: 'Enviar invitación',
      cancelInviteButton: 'Cancelar invitación',
      inviteSent: 'Correo electrónico de invitación del usuario enviado',
      inviteSendFailed: 'No se pudo enviar la invitación, inténtalo de nuevo',
      userExistedHelperText: 'Hay un usuario existente con esta dirección de correo electrónico',
      userPendingHelperText: 'Este usuario ya tiene una invitación pendiente',
      newUserTitle: 'Invitar usuario',
      newUserDescription: 'Invita a un nuevo usuario a tu organización.',
      organisationContactsTitle: 'Contactos de la organización',
      organisationContactsDescription: 'Configure los detalles de contacto para cada departamento.',
      primaryOrgContactTitle: 'Contacto primario',
      billingOrgContactTitle: 'Contacto de facturación',
      billingOrgContactNone: 'Ningún contacto de facturación configurado',
      Administrator: 'Administrador',
      ViewOnly: 'Sólo vista',
      'View Only': 'Sólo vista',
      organisationAccessTitle: 'Configuración de acceso a la organización',
      organisationUsersTitle: 'Usuarios de la organización',
      inviteUserTitle: 'Invitar usuario',
      contactsTitle: 'Contactos',
      error: 'Error',
      users: 'los usuarios',
      billingTitle: 'Facturación',
      userRemoved: 'Usuario eliminada con éxito.',
      userRemovedError: 'No se ha podido eliminar al usuario. Vuelva a intentarlo.',
      roleUpdated: 'Actualización de rol exitosa.',
      roleUpdateError: 'No se pudo actualizar el rol, intente nuevamente.',
      userListDescription: 'Administrar cuentas de usuario en su Organización.',
      emergencyContacts: {
        title: 'Contactos',
        description: 'Estos contactos se pueden asignar como contactos de emergencia y de mensajería del dispositivo para activos en la página de activos.',
        movedTitle: 'Los contactos de su organización ahora se encuentran en la sección Administrar',
        movedMessage: 'Allí puede ver y administrar personas de contacto, grupos de contactos de ICE y grupos de contactos de mensajería del dispositivo.',
        movedLink: 'Administrar contactos',
      },
      access: {
        title: 'Configuración de acceso a la organización',
        description: 'El Acceso de esta organización es {access}',
        label: 'Acceso',
        details: 'Detalles de cada nivel de Acceso:',
        publicDescription: 'Esta Organización puede ser vista por todos los usuarios a nivel mundial y ser amiga de otra Organización sin necesidad de confirmación.',
        privateDescription: 'Esta organización puede ser vista por todos los usuarios a nivel mundial, pero la amistad requiere confirmación para ser aprobada.',
        hiddenDescription: 'Esta Organización no puede ser vista por usuarios fuera de ella.',
        accessLevels: {
          public: 'Público',
          private: 'Privado',
          hidden: 'Oculto'
        }
      },
      timezone: {
        title: 'Zona horaria',
        description: 'Configure la zona horaria en función de la zona horaria de su organización',
        label: 'Zona horaria',
        placeholder: 'Elige una zona horaria'
      },
      amsSettings: {
        title: 'Configuración de monitoreo activo',
        description: 'Configure la configuración de supervisión activa para todos los activos de su organización. Estos se pueden anular para activos individuales.',
        ofConcernTimeout: 'Tiempo de espera de preocupación (minutos)',
        overdueTimeout: 'Tiempo de espera vencido (minutos)',
        stationaryCount: 'Conteo estacionario (informes)',
        disableOrgAms: 'Habilitar la supervisión activa de todos los activos de esta organización'
      },
      removeUserFromOrganisation: 'Eliminar usuario de la organización',
      removeUserDialogText: 'Eliminar a {userName} de {organisationName}?',
      cancelInviteToOrganisation: 'Cancelar la invitación de los usuarios de la organización',
      cancelInviteDialogText: 'Cancelar la invitación de {name} con el correo electrónico {email} de {organisationName}?',
      okButton: 'ok',
      cancelButton: 'cancelar',
      search: 'Buscar',
      noUsersFound: 'No se encontraron registros del usuario',
      member: 'Miembro',
      pending: 'Pendiente',
      rows: 'filas',
      of: 'de',
      firstPage: 'Primera Página',
      nextPage: 'Siguiente Página',
      previousPage: 'Página Anterior',
      lastPage: 'Última Página',
      orgName: 'Nombre',
      billPayer: 'Pagador de facturas',
      orgDescription: 'Descripción',
      marketSector: {
        primaryLabel: 'Sectores de mercado',
        secondaryLabel: 'Sectores de mercado adicionales',
        add: 'Agregar nueva',
        dialog: {
          title: 'Agregar sector de mercado adicional',
          saveButton: 'Guardar',
          cancelButton: 'Cancelar',
        },
        snackbar: {
          exists: 'El sector del mercado ya está asignado a esta organización',
          error: 'Se produjo un error al actualizar el sector del mercado de la organización',
          success: 'Sector de mercado actualizado con éxito',
        },
      }
    },
    iceSettings: {
      title: 'En caso de emergencia (ICE)'
    },
    map: {
      template: {
        name: {
          'mapbox-dark': 'Mapbox oscuro',
          'mapbox-outdoors': 'Mapbox al aire libre',
          'mapbox-satellite': 'Satélite Mapbox',
          'mapbox-satellite-streets': 'Calles satelitales de Mapbox',
          'mapbox-streets': 'Calles de Mapbox',
          'nasa-gibs-snpp': 'Satélite diario',
          'reactmapgl-sv-vfr': 'SkyVector VFR Mapa',
          'reactmapgl-sv-ifr-hi': 'SkyVector IFR Hi Mapa',
          'reactmapgl-sv-ifr-lo': 'SkyVector IFR Lo Mapa',
          'open-seamap': 'OpenSeaMap',
          linz: 'LINZ (Solo NZ)',
        },
        attribution: {
          linz: 'Procedente del <a href="https://data.linz.govt.nz/" target="_blank">Servicio de datos de LINZ</a> y autorizado para su reutilización bajo la licencia <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a>.',
          openSeaMap: '&copy; <a href="https://www.openseamap.org/index.php">OpenSeaMap</a> &copy; <a href="https://www.openstreetmap.org/about/">OpenStreetMap</a> colaboradores',
          nasa: 'Imágenes proporcionadas por los servicios de exploración de imágenes globales (GIBS) de la NASA, parte del Sistema de datos e información del sistema de observación de la Tierra (EOSDIS) de la NASA',
          skyVector: 'Mapas proporcionados por <a href="https://skyvector.com" target="_blank">SkyVector</a>',
        },
        provider: {
          mapbox: 'Mapbox',
          skyVector: 'SkyVector',
          linz: 'LINZ',
          openSeaMap: 'OpenSeaMap',
          nasa: 'NASA GIBS',
        },
      },
      liveUpdateDummy: {
        liveUpdateFailed: 'No se pudo obtener la última posición del dispositivo y los informes de eventos. Por favor recarga la página'
      },
      mapLayoutSpeeddial: {
        title: 'Opciones de mapa dividido',
        singleMap: 'Mapa único',
        twoMapsHorizontal: 'Dos mapas - Horizontal',
        twoMapsVertical: 'Dos mapas - Vertical',
        threeMapsA: 'Tres mapas',
        threeMapsB: 'Tres mapas',
        fourMaps: 'Cuatro mapas'
      },
      settingsDialog: {
        title: 'Configuraciones de mapa',
        mapLayers: 'Capas de mapa',
        mapLayerGroups: {
          nauticalCharts: 'Cartas náuticas',
        },
        animations: 'Animaciones',
        labels: 'Etiquetas',
        assetDetails: 'Detalles del activo',
        iconAppearance: 'Apariencia del icono',
        mapControls: 'Controles de mapa',
        highlightDrops: 'Modo de extinción de incendios',
        inputTitle: 'Opciones de mapa',
        assetLabels: 'Etiquetas de activo',
        reportDots: 'Reportar puntos',
        reportDotsZoom: 'Ocultar Puntos de informe con zoom',
        currentZoom: 'Acercamiento actual',
        kmlLabels: 'Etiquetas KML',
        kml: {
          displayOverlay: 'Mostrar superposición al pasar el mouse',
          displayAdditionalData: 'Mostrar datos adicionales en superposición',
        },
        animateTrails: 'Animar trayectoria',
        highContrastControls: 'Controles de mapa de alto contraste',
        scale: 'Escala de distancia',
        assetClustering: 'Agrupación de activos',
        duplicateMapObjects: 'Duplicar objetos del mapa',
        animateSelectedTrailOnly: 'Animar ruta seleccionada únicamente',
        colorByState: 'Activo de color por estado',
        mapType: 'Tipo de mapa',
        showActiveAssetsOnly: 'Ocultar activos inactivos',
        hoursOfInactivity: 'Horas de inactividad',
        trailOptions: 'Opciones de senderos',
        noTrails: 'Sin senderos: solo iconos',
        selectedTrails: 'Solo activos seleccionados',
        selectedTrailsIcons: 'Senderos e iconos de activos seleccionados',
        allTrailsIcons: 'Todos los senderos e iconos de activos',
        firefightingOptions: {
          title: 'Opciones de extinción de incendios',
          showDropTrails: 'Mostrar gotas',
          showDropIcons: 'Mostrar iconos de gota',
          help: 'Información sobre opciones de lucha contra incendios',
        },
        customLayers: 'Capas personalizadas',
        otherLayers: {
          title: 'Otras capas',
          sunLayer: 'Superposición solar',
        },
        dropFile: 'Soltar archivo',
        dropFilesHere: 'Soltar aquí el archivo KML, KMZ o \nTPP3 Markers.xml',
        loadedFile: 'Archivo cargado',
        removeKML: 'Eliminar KML',
        assetActivity: 'Actividad del activo',
        selectedTrailColouring: 'Coloración de senderos seleccionados',
        assetDetail: {
          bearingAtSpeed: 'Trayectoria y velocidad',
          altitudeAtSpeed: 'Altitud y velocidad',
          position: 'Posición',
          make: 'Marca',
          model: 'Modelo',
          variant: 'Variante',
          owner: 'Propietario',
          category: 'Categoría',
        },
        geofences: {
          title: 'Geocercas',
          help: 'Aprender sobre geocercas',
          enabled: 'Mostrar geocercas',
        },
        markers: {
          title: 'Marcadores',
          help: 'Aprende sobre marcadores',
          enabled: 'Mostrar marcadores',
        },
        '3d': {
          title: 'Mapa 3D',
          help: 'Conozca nuestro Mapa 3D',
          enabled: 'Mostrar mapa en 3D',
          curtainEnabled: 'Mostrar cortinas debajo de los senderos',
        },
        containmentLines: {
          title: 'Líneas de contención',
          help: 'Más información sobre las líneas de contención',
          picker: {
            none: 'Ninguno',
            selectedAsset: 'Solo Activo Seleccionado',
            allAssets: 'Todos los Activos',
          },
        },
        labs: {
          description: 'Vista previa de las últimas innovaciones de mapas',
          weather: {
            title: 'Clima',
            help: 'Aprende sobre mapas del clima',
            windTrails: 'Huellas de viento',
            windVelocityHeatmap: 'Mapa de calor de la velocidad del viento',
          },
          adsb: {
            title: 'ADS-B',
            help: 'Aprende sobre mapas ADS-B',
            enabled: 'Mostrar datos ADS-B',
          },
          '3d': {
            title: 'Mapa 3D',
            help: 'Conozca nuestro Mapa 3D',
            enabled: 'Mostrar mapa en 3D',
            curtainEnabled: 'Mostrar cortinas debajo de los senderos',
          },
          markers: {
            title: 'Marcadores',
            help: 'Aprende sobre marcadores',
            enabled: 'Mostrar marcadores',
          },
          containmentLines: {
            title: 'Líneas de contención',
            help: 'Más información sobre las líneas de contención',
            picker: {
              none: 'Ninguno',
              selectedAsset: 'Solo Activo Seleccionado',
              allAssets: 'Todos los Activos',
            },
          },
        },
        annotations: {
          title: 'Anotaciones',
          help: 'Obtenga más información sobre las anotaciones',
          noData: 'Utilice las herramientas del mapa para agregar nuevas anotaciones que se pueden administrar desde aquí'
        }
      },
      mapControls: {
        zoomIn: 'Acercarse',
        zoomOut: 'Alejarse',
        mapSettings: 'Opciones de mapa',
        measurementTool: 'Herramienta de medición',
        distanceRings: 'Anillos de distancia',
        velocityLeaders: 'Líderes de velocidad',
        createMarker: 'Crear marcador',
        flightFollow: 'Seguir Activo',
        positionDetails: 'Detalles de la posición',
        resetMap: 'Restablecer mapa',
        closeMap: 'Cerrar mapa',
        following: 'Siguiendo',
        mapTools: 'Herramientas de mapa',
        createPoint: 'Agregar anotación de puntos',
        createPath: 'Agregar anotación de camino',
      },
      measurementTool: {
        instruction: 'Haga clic para establecer puntos de ruta, haga doble clic para confirmar',
      },
      measurementPanel: {
        toMarker: 'al Marcador',
        toCursor: 'al Cursor',
        distance: 'Distancia',
        speed: 'Velocidad',
        ETE: 'ETE',
        ETA: 'ETA'
      },
      mapInterval: {
        title: 'Intervalo de mapa base',
        start: 'Inicio de imágenes',
        end: 'Fin de las imágenes'
      },
      reportDetails: {
        eventType: 'Tipo de evento',
        inferredEventTypes: 'Tipos de eventos inferidos',
        network: 'Red',
        timeOfFix: 'Tiempo de corrección',
        transport: 'Transporte',
        date: 'Fecha',
        coordinates: 'Coordenadas',
        latitude: 'Latitud',
        longitude: 'Longitud',
        altitude: 'Altitud',
        elevation: 'Elevación',
        speed: 'Velocidad',
        course: 'Trayectoria',
        cellular: 'Celular',
        satellite: 'Satélite',
        standard: 'Estándar',
        latency: 'Latencia'
      },
      error: {
        message: 'Se produjo un error inesperado al renderizar el mapa',
        reset: 'Reiniciar',
      },
      markers: {
        overflow: '...y {n} mas',
        marker: 'Marcadores',
        icon: 'Icono',
        type: 'Variante',
        notes: 'Notas',
        coordinates: 'Coordenadas',
        assetToMarker: '{asset} a {marker}',
        distance: 'Distancia',
        bearing: 'Trayectoria',
        ete: 'ETE',
        eta: 'ETA',
        description: 'Descripción'
      },
      geofences: {
        geofence: 'Geocercado',
        category: 'Categoría',
        categoryValue: '{category, select, Generic {Genérica} RestrictedArea {Zona restringida} AreaOfOperation {Zona de operación} other {Unknown}}',
        description: 'Descripción',
        minAltitude: 'Altitud mínima',
        maxAltitude: 'Altitud máxima',
      },
      annotations: {
        drawOverlay: {
          title: 'Dibujar un camino',
          label: 'Dibuje la ruta que desea crear y seleccione el último punto para completar la ruta y guardarla.',
          button: {
            cancel: 'Cancelar',
            save: 'Guardar',
          }
        },
        hoverOverlay: {
          title: 'Anotación del mapa',
          headers: {
            description: 'Descripción',
            coordinates: 'Coordenadas',
            type: 'Variante',
            distance: 'Distancia',
            bearing: 'Trayectoria',
            ete: 'ETE',
            eta: 'ETA',
          },
          assetToMarker: '{asset} a {marker}',
          type: {
            path: 'Camino',
            point: 'Punto'
          },
        }
      }
    },
    messaging: {
      title: 'Lista de Mensajes',
      findConversation: 'Buscar conversación',
      newConversation: 'Nueva conversación',
      inputPlaceholder: 'Nuevo mensaje',
      sendButton: 'Enviar',
      newConversationBar: {
        label: 'Para: buscar usuarios y activos',
        noUserFound: 'Ningún usuario encontrado con el nombre {userName}.',
        clear: 'Claro',
        error: 'Ocurrió un error inesperado al cargar destinatarios',
      },
      sent: 'Estado del mensaje enviado',
      pending: 'Estado del mensaje pendiente',
      delivering: 'Estado del mensaje pendiente',
      unknown: 'Estado del mensaje pendiente',
      failed: 'No se pudo enviar el mensaje. Vuelve a intentarlo.',
      success: 'Mensaje enviado con éxito',
      assetConversationWarning: 'El envío de mensajes a dispositivos satelitales puede tener un costo.',
      unmessageableAssetConversationWarning: 'Este activo no tiene instalado un dispositivo capaz de enviar mensajes.',
      getConversationsFailed: 'Incluyendo invitaciones pendientes, excluyendo personal de TP.',
      getMessagesFailed: 'Error al obtener mensajes para el dispositivo'
    },
    organisationsList: {
      title: 'Lista de Organizaciones',
      description: 'Administra todas las organizaciones',
      organisationName: 'Nombre de la Organización',
      usercode: 'Usercode',
      search: 'Buscar',
      actions: 'Acciones',
      leave: 'Abandonar',
      join: 'Unirse',
      leaveOrg: 'Abandonar {orgName}',
      joinOrg: 'Unirse a {orgName}',
      leaveOrgConfirm: '¿Estás seguro de que quieres abandonar {orgName}?',
      joinOrgConfirm: '¿Estás seguro de que quieres unirte a {orgName} como administrador?',
      cancel: 'Cancelar',
      access: 'Nivel de acceso',
      primaryContactName: 'Contacto Primario',
      billingContactName: 'Contacto de facturación',
      userCount: 'Usuarios',
      enabled: 'Enabled',
      userCountTooltip: 'Incluyendo invitaciones pendientes, excluyendo TP Staff.',
      iceContactCount: 'ICE Contactos',
      companyName: 'Nombre de Empresa: ',
      name: 'Nombre de Contacto: ',
      phone: 'Teléfono: ',
      phoneAlt: 'Teléfono alternativo: ',
      email: 'Email: ',
      sms: 'Mensajes cortos: ',
      city: 'Ciudad: ',
      country: 'País: ',
      createOrg: 'Crear organización',
      ShowOrgContactDetails: 'Mostrar detalles de contacto de la organización',
      saveOrgContactFailed: 'No se pudieron guardar los datos de contacto de la organización',
      saveOrgContactSuccess: 'Datos de contacto de la organización actualizados',
      createDialog: {
        public: 'Público',
        private: 'Privado',
        hidden: 'Oculto',
        publicDescription: 'Esta Organización puede ser vista por todos los usuarios a nivel global y ser amiga de otra Organización sin necesidad de confirmación.',
        privateDescription: 'Esta organización puede ser vista por todos los usuarios a nivel global, pero la amistad requiere confirmación para ser aprobada.',
        hiddenDescription: 'Esta Organización no puede ser vista por usuarios fuera de ella.',
        createOrg: 'Crear una nueva organización',
        orgName: 'Nombre de la organización',
        OrgDescription: 'Descripción de la organización',
        primaryContact: 'Contacto primario',
        contactName: 'Nombre de contacto',
        phone: 'Teléfono',
        email: 'Correo electrónico',
        address: 'Dirección',
        city: 'Ciudad',
        state: 'Estado',
        postcode: 'Código postal',
        country: 'País',
        cancel: 'Cancelar',
        create: 'Crear',
        label: 'Acceso'
      },
      close: 'Cerrar',
      orgDescription: 'Descripción',
      primaryContactDetail: 'Contacto primario',
      billingContactDetail: 'Contacto de facturación',
      iceContactDetail: 'Contactos ICE',
      error: 'Error al recuperar los datos de la organización ...',
      noDescriptionSet: 'No hay descripción establecida.',
      noICEContactsSet: 'No se han establecido contactos ICE.',
      rows: 'filas',
      of: 'de',
      firstPage: 'Primera Página',
      nextPage: 'Siguiente Página',
      previousPage: 'Página Anterior',
      lastPage: 'Última Página',
      hidden: 'oculto',
      public: 'público',
      private: 'privado'
    },
    sharing: {
      title: 'Compartición',
      more: '{n} más...',
      description: 'Compartir activos con otras organizaciones',
      tabs: {
        sharedFrom: 'Compartido desde mi organización',
        sharedTo: 'Compartido con mi organización',
        sharedSelf: 'Autocompartidos (SOLO PERSONAL)',
      },
      groupByLabel: 'Agrupar por',
      groupBy: {
        recipient: 'Agrupar por destinatario',
        device: 'Agrupar por activo',
      },
      groupByOptions: {
        recipient: 'Destinatario',
        device: 'Activo',
      },
      actions: {
        create: 'Crear nuevo compartido',
        edit: 'Editar compartido',
        delete: 'Eliminar compartido',
      },
      loading: 'Cargando compartidos...',
      noSharesFrom: 'No hay activos compartidos desde su organización.',
      noSharesTo: 'No hay activos compartidos con su organización.',
      noSharesSelf: 'No hay auto-compartidos para esta organización.',
      noSharesForFilters: 'No hay compartidos que coincidan con los filtros aplicados.',
      noSelfShares: 'Sin autocompartidos',
      sharedAsset: 'Activo compartido',
      sharedToRecipient: 'Activos compartidos con',
      sharedBy: 'Activos compartidos por',
      via: 'a través de',
      columns: {
        asset: 'Activo',
        device: 'Dispositivo',
        recipient: 'Destinatario',
        time: 'Hora',
        trails: 'Ver senderos',
        forms: 'Ver formularios',
        message: 'Mensaje',
        configure: 'Configurar',
        callsign: 'Editar indicativo de llamada',
        notes: 'Notas',
      },
      trails: {
        all: 'En vivo e históricos',
        live: 'Solo en vivo',
        historic: 'Solo históricos',
        none: 'Ninguno',
      },
      permissionLabels: {
        canViewForms: 'Ver formularios',
        canSendTextMessages: 'Enviar mensajes de texto',
        canSendConfiguration: 'Configurar dispositivo',
        canEditCallSign: 'Editar indicativo de llamada',
      },
      timeRange: {
        all: 'Todo el tiempo',
        from: 'Desde: {date}',
        to: 'Hasta: {date}',
      },
      asset: {
        label: 'Activo',
        unknown: 'Activo desconocido',
        unnamed: 'Activo sin nombre',
        unknownWithDevice: 'Activo desconocido ({make} {model} {serial})',
      },
      device: {
        label: 'Dispositivo',
      },
      selectAssets: {
        label: 'Seleccionar activos',
        loading: 'Cargando activos',
        all: 'Todos los activos',
        allDescription: 'Esto seleccionará todos los activos posibles que se pueden elegir.',
        noAssetGroupName: 'Grupo de activos sin nombre',
        noAssetsEnabled: 'No hay activos habilitados',
        nAssetsDisabled: '{n, plural, one {# activo deshabilitado} other {# activos inhabilitados}}',
      },
      tooltips: {
        assetsDisabled: 'Esta característica no está habilitada para estos recursos',
      },
      selectParticipants: {
        label: 'Seleccionar grupos u organizaciones',
        loading: 'Cargando grupos y organizaciones',
      },
      filters: {
        labels: {
          filterAssets: 'Filtrar activos',
          filterRecipients: 'Filtrar grupos y organizaciones',
          filterPeriod: 'Filtrar período de tiempo',
        },
        allDevices: 'Todos los activos',
        nDevices: '<c>{n}</c> {n, plural, one {activo} other {activos}}',
        allRecipients: 'Todos los grupos y organizaciones',
        nRecipients: '<c>{n}</c> {n, plural, one {grupo u organización} other {grupos u organizaciones}}',
        nShares: '{n, plural, one {# compartición} other {# comparticiones}}',
        selectedPeriod: `{period, select,
          past {Período de tiempo <c>pasado</c>}
          present {Período de tiempo <c>presente</c>}
          future {Período de tiempo <c>futuro</c>}
          other {Cualquier período de tiempo}
        }`,
        actions: {
          clear: 'Borrar',
          cancel: 'Cancelar',
          apply: 'Aplicar',
        },
        periodLabel: {
          all: 'Cualquier',
          past: 'Pasado',
          present: 'Presente',
          future: 'Futuro',
        },
        periodDescription: {
          all: 'Compartidos para todos los períodos de tiempo',
          past: 'Compartidos que han finalizado',
          present: 'Compartidos que están activos ahora',
          future: 'Compartidos que aún no han comenzado',
        },
      },
      dateRangePicker: {
        label: 'Intervalo de fechas',
        options: {
          all: 'Todo el tiempo',
          openEnded: 'Sin fecha de finalización',
          specificRange: 'Intervalo de fechas',
        },
        start: 'Fecha de inicio',
        end: 'Fecha de finalización',
        effectiveFrom: 'Efectivo a partir de {start}',
        effectiveFromTo: 'Efectivo a partir de {start} hasta {end}',
      },
      permissionsPicker: {
        trails: {
          label: 'Ver senderos',
          options: {
            all: 'Senderos en vivo e históricos',
            live: 'Solo senderos en vivo',
            historic: 'Solo senderos históricos',
            none: 'Sin senderos',
          },
        },
        permissions: {
          label: 'Permisos',
          options: {
            canSendConfiguration: 'Configurar dispositivo',
            canSendTextMessages: 'Enviar mensajes de texto',
            canViewForms: 'Ver formularios',
            canEditCallSign: 'Editar indicativo de llamada',
          },
          descriptions: {
            canSendConfiguration: 'Permitir a los administradores de las organizaciones destinatarias configurar el dispositivo compartido',
            canSendTextMessages: 'Permitir que los usuarios de las organizaciones receptoras envíen mensajes de texto al dispositivo compartido',
            canViewForms: 'Permitir a los usuarios de las organizaciones destinatarias ver los datos enviados en eventos de formularios',
            canEditCallSign: 'Permitir a los administradores de las organizaciones destinatarias editar el indicativo de llamada del activo compartido',
          },
        },
        required: 'Obligatorio'
      },
      note: {
        label: 'Nota',
        placeholder: '¿Para qué se comparte este activo?',
      },
      create: {
        title: 'Compartir activos',
        titleSelf: 'Crear autocompartido',
        notifications: {
          success: 'Compartido creado correctamente',
          error: 'Un error inesperado impidió la creación de este compartido. Por favor, inténtelo de nuevo',
        },
        selectRecipient: {
          label: 'Compartir con organización o grupo',
        },
        selectAssets: {
          label: 'Compartir activos',
        },
        actions: {
          cancel: 'Cancelar',
          save: 'Guardar',
          saving: 'Guardando...',
        },
      },
      edit: {
        title: 'Editar compartido',
        titleSelf: 'Editar autocompartido',
        sharedWith: 'Compartido con',
        notifications: {
          success: 'Compartido editado correctamente',
          error: 'Un error inesperado impidió la edición de este compartido. Por favor, inténtelo de nuevo.',
        },
        actions: {
          clone: 'Clonar',
          cancel: 'Cancelar',
          save: 'Guardar',
          saving: 'Guardando...',
        },
      },
      delete: {
        title: 'Eliminar compartido',
        titleSelf: 'Eliminar autocompartido',
        prompt: '¿Está seguro de que desea eliminar este compartido?',
        warning: 'Si se elimina, {recipient} ya no podrá ver la actividad histórica de este activo para el intervalo de fechas.<br></br> Considere editar el intervalo de fechas para compartir solo la actividad que desea.',
        sharedWith: 'Compartido con',
        notifications: {
          success: 'Compartido eliminado correctamente',
          error: 'Un error inesperado impidió la eliminación de este compartido. Por favor, inténtelo de nuevo.',
        },
        noPermissions: 'Sin permisos adicionales',
        actions: {
          cancel: 'Cancelar',
          delete: 'Eliminar',
          deleting: 'Eliminando...',
        },
      },
    },
  },
  dialogs: {
    contactGroups: {
      addGroup: {
        name: 'Nombre del grupo',
        save: 'Guardar',
        saving: 'Guardando...',
        cancel: 'Cancelar',
        validation: {
          nameExists: 'Ya existe un grupo con este nombre',
          nameMissing: '', // not relevant but keeps happy types
        },
        alert: {
          error: 'Un error inesperado impidió que se creara este grupo de contactos. Inténtalo de nuevo.',
        },
      },
      assignAssets: {
        selectedAssets: 'Seleccionados {count, plural, one {activo} other {activos}}',
        loadingAssets: 'Cargando {count, plural, one {activo} other {activos}}...',
        contactGroupLabel: 'Grupo de contactos',
        loadingPeople: 'Cargando personas...',
        noPeopleInContactGroup: 'Este grupo de contactos no tiene personas asignadas',
        save: 'Guardar',
        saving: 'Guardando...',
        cancel: 'Cancelar',
        columns: {
          name: 'Nombre',
          role: 'Rol',
          sms: 'SMS',
          voice: 'Voz',
          email: 'Correo electrónico',
          language: 'Idioma',
        },
        snackbar: {
          success: '{count, plural, one {{asset} asignado} other {# activos asignados}} al grupo de contactos {group} con éxito',
        },
        error: 'Un error inesperado impidió que estos activos fueran asignados. Inténtalo de nuevo.'
      },
      assignPeople: {
        peopleLoading: 'Cargando personas...',
        selectedPeople: 'Personas seleccionadas',
        otherPeople: 'Otras personas para agregar',
        searchPeople: 'Buscar personas por nombre',
        searchPeoplePlaceholder: 'Buscar',
        dragToReorder: 'Arrastrar para reordenar',
        minimumPeople: 'Cada grupo debe tener al menos dos personas asignadas',
        noUnselectedPeople: 'No hay personas para agregar a este grupo',
        noFilteredPeople: 'No hay personas que coincidan con esta búsqueda',
        tooltips: {
          addPerson: 'Agregar persona al grupo',
          removePerson: 'Eliminar persona del grupo',
          movePersonUp: 'Mover hacia arriba',
          movePersonDown: 'Mover hacia abajo',
          editPersonContacts: 'Editar detalles de contacto predeterminados para esta persona',
        },
        addPerson: 'Agregar una nueva persona de contacto',
        alert: {
          addPersonSuccess: 'La persona recién agregada, {name}, ha sido seleccionada automáticamente',
        },
        skip: 'Omitir',
        cancel: 'Cancelar',
        save: 'Guardar',
        saving: 'Guardando...',
      },
      assignAssetsSingleGroup: {
        assetsLoading: 'Cargando activos...',
        selectedAssets: 'Activos seleccionados',
        otherAssets: 'Otros activos',
        searchAssets: 'Buscar activos',
        searchAssetsPlaceholder: 'Buscar',
        noUnselectedAssets: 'No quedan activos para agregar a este grupo',
        noFilteredAssets: 'Ningún activo coincide con esta búsqueda',
        assignmentWarning: `Todos los activos que se asignen se eliminarán de su {type, select,
          ice {grupo de contacto ICE}
          messagingWhitelist {grupo de contactos de mensajería del dispositivo}
          other {grupo}
        }. Los recursos no asignados utilizarán el {type, select,
          ice {grupo de contacto ICE}
          messagingWhitelist {grupo de contactos de mensajería del dispositivo}
          other {grupo}
        }.`,
        tooltips: {
          addAsset: 'Agregar activo al grupo',
          removeAsset: 'Eliminar activo del grupo',
        },
        snackbar: {
          success: 'Activos asignados a {group} con éxito',
        },
        error: 'Un error inesperado impidió que se agregaran estos recursos.',
        error409: 'Se realizaron otros cambios en los activos asignados de este grupo antes de que se guardaran los cambios. Ahora se muestran los últimos cambios. Revise y vuelva a intentar los cambios si es necesario.',

        cancel: 'Cancelar',
        save: 'Guardar',
        saving: 'Guardando...',
      },
      setDefault: {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      delete: {
        errorDeleting: 'Un error inesperado impidió que este grupo de contactos fuera eliminado.',
        confirm: 'Confirmar eliminación',
        cancel: 'Cancelar',
      },
    },
    ice: {
      assignPeople: {
        primary: 'Primario',
        secondary: 'Secundario',
        error: 'Un error inesperado impidió que estas personas fueran agregadas.',
        error409: 'Se realizaron otros cambios en las personas asignadas de este grupo antes de que se guardaran los cambios. Ahora se muestran los últimos cambios. Revise y vuelva a intentar los cambios si es necesario.',
        failsDefaultGroupRequirements: 'Las personas de contacto primarias y secundarias asignadas deben tener un número de teléfono de voz y un número de teléfono SMS.',
        tooltips: {
          missingRequiredContacts: 'Esta persona no tiene un número de teléfono de voz o SMS predeterminado',
        },
        snackbar: {
          success: 'Personas asignadas al grupo de contactos con éxito',
        },
      },
      assignAssets: {
        title: 'Asignar {count, plural, one {activo} other {activos}} al grupo de contactos de ICE',
      },
      delete: {
        title: 'Eliminar grupo de contactos de ICE',
        message: '¿Está seguro de que desea eliminar el grupo de contactos <strong>{name}</strong>?',
        assetsWarning: '{count, plural, one {El activo} =2 {Ambos activos} other {Todos los {count, number} activos}} que se asignan a este grupo de contactos utilizarán el grupo de contactos de ICE predeterminado.',
        noAssets: 'Este grupo de contactos no está asignado a ningún activo.',
        defaultGroup: 'No se puede eliminar este grupo porque es el grupo de contactos de ICE predeterminado de su organización. Antes de eliminar este grupo, debe establecer otro grupo como predeterminado.',
        snackbar: {
          success: 'Grupo de contactos de ICE, {name} eliminado con éxito',
        },
      },
    },
    messagingWhitelist: {
      assignPeople: {
        error: 'Un error inesperado impidió que estas personas fueran agregadas.',
        error409: 'Se realizaron otros cambios en las personas asignadas de este grupo antes de que se guardaran los cambios. Ahora se muestran los últimos cambios. Revise y vuelva a intentar los cambios si es necesario.',
        tooltips: {
          missingRequiredContacts: 'Esta persona no tiene un número de teléfono de voz o SMS predeterminado',
        },
        snackbar: {
          success: 'Personas asignadas al grupo de contactos con éxito',
        },
      },
      assignAssets: {
        title: 'Asignar {count, plural, one {activo} other {activos}} al grupo de contactos de mensajería del dispositivo',
      },
      delete: {
        title: 'Eliminar grupo de contactos de mensajería del dispositivo',
        message: '¿Está seguro de que desea eliminar el grupo de contactos <strong>{name}</strong>?',
        assetsWarning: '{count, plural, one {El activo} =2 {Ambos activos} other {Todos los {count, number} activos}} a los que está asignado este grupo de contactos utilizarán el grupo de contactos de mensajería predeterminado del dispositivo.',
        noAssets: 'Este grupo de contactos no está asignado a ningún activo.',
        defaultGroup: 'Este grupo no se puede eliminar porque es el grupo de contactos de mensajería del dispositivo predeterminado de su organización. Antes de eliminar este grupo, debe configurar otro grupo como predeterminado.',
        snackbar: {
          success: 'Grupo de contactos de mensajería del dispositivo, {name} eliminado correctamente',
        },
      },
    },
    people: {
      addPerson: {
        title: 'Agregar una nueva persona de contacto',
        alert: {
          error: 'Un error inesperado impidió agregar a esta persona. Inténtalo de nuevo.',
        },
        skip: 'Omitir',
        cancel: 'Cancelar',
        save: 'Guardar',
        saving: 'Guardando...',
      },
      addContacts: {
        title: 'Establecer detalles de contacto predeterminados para {name}',
        requirements: 'Cada persona de contacto debe tener un número de teléfono SMS y de voz',
        requirementsImportant: 'Esta persona de contacto debe tener un SMS y un número de teléfono predeterminados porque es el contacto principal o secundario actual para el grupo de contacto ICE predeterminado.',
        phone: 'Número de teléfono de voz',
        sms: 'Número de teléfono SMS',
        email: 'Dirección de correo electrónico',
        copyFromSms: 'Copiar desde SMS',
        cancel: 'Cancelar',
        save: 'Guardar',
        saving: 'Guardando...',
        saved: 'Guardado',
        alert: {
          error: 'Un error inesperado impidió que se agregaran contactos. Inténtalo de nuevo.',
        },
      },
      deletePerson: {
        title: 'Eliminar persona',
        ariaLabel: 'Eliminar persona',
        message: '¿Está seguro de que desea eliminar permanentemente a <strong>{name}</strong>?',
        loadingGroups: 'Cargando grupos para esta persona',
        noWarnings: 'Esta persona de contacto se puede eliminar de forma segura.',
        memberOfGroups: 'La eliminación de {name} afectará a los siguientes grupos de contactos.',
        iceContactGroup: 'ICE',
        messagingWhitelistContactGroup: 'Grupo de contactos de mensajería del dispositivo',
        cancel: 'Cancelar',
        confirm: 'Confirmar eliminación',
        columns: {
          groupType: 'Tipo de grupo',
          group: 'Nombre del grupo',
          warnings: 'Advertencias',
        },
        errorDeleting: 'Un error inesperado impidió que esta persona fuera eliminada.',
        snackbar: {
          deletedSuccessfully: '{name} eliminado con éxito',
        },
        warnings: {
          personCount: 'La eliminación dejará {n, plural, =0 {ninguna persona} one {solo una persona} =2 {solo dos personas} other {# personas}} en este grupo.',
          noPrimary: 'La eliminación dejará a este grupo sin una persona de contacto primaria.',
          default: 'Esta persona es la {order, plural, one {primaria} other {secundaria}} persona de contacto para el grupo de contacto ICE predeterminado de su organización.',
          defaultAction: 'Antes de que se pueda eliminar a {name}, debe eliminarse de las personas de contacto principales o secundarias del grupo..',
        },
        managePeople: 'Administrar personas en este grupo',
        default: 'Defecto',
      },
      editPersonContact: {
        title: 'Editar {type, select, phone {número de teléfono de voz} sms {número de teléfono SMS} email {dirección de correo electrónico} other {}}',
        ariaLabel: 'Editar {type, select, phone {número de teléfono de voz} sms {número de teléfono SMS} email {dirección de correo electrónico} other {}}',
        cancel: 'Cancelar',
        save: 'Guardar',
        contactType: '{type, select, phone {Número de teléfono} sms {Número de teléfono} email {Dirección de correo electrónico} other {}}',
        setDefault: 'Establecer como {type, select, phone {número de teléfono de voz} sms {número de teléfono SMS} email {dirección de correo electrónico} other {}} predeterminado para esta persona.',
        errorEditing: 'Un error inesperado impidió que este {type, select, phone {número de teléfono} sms {número de teléfono} email {dirección de correo electrónico} other {}} fuera cambiado.',
        errorEditing409: 'Este {type, select, phone {número de teléfono} sms {número de teléfono} email {dirección de correo electrónico} other {}} ha cambiado desde la última vez que lo cargó. <br></br>Ahora se muestra la última versión. Revise y vuelva a intentar los cambios si es necesario.',
        snackbar: {
          editedSuccessfully: '{type, select, phone {Número de teléfono de voz} sms {Número de teléfono SMS} email {Dirección de correo electrónico} other {}} {value} cambiado con éxito',
        },
      },
      deletePersonContact: {
        title: 'Eliminar {type, select, phone {número de teléfono de voz} sms {número de teléfono SMS} email {dirección de correo electrónico} other {}}',
        ariaLabel: 'Eliminar {type, select, phone {número de teléfono de voz} sms {número de teléfono SMS} email {dirección de correo electrónico} other {}}',
        message: '¿Está seguro de que desea eliminar <strong>{value}</strong>?',
        cancel: 'Cancelar',
        confirm: 'Confirmar eliminación',
        errorDeleting: 'Un error inesperado impidió que este {type, select, phone {número de teléfono de voz} sms {número de teléfono SMS} email {dirección de correo electrónico} other {}} fuera eliminado.',
        snackbar: {
          deletedSuccessfully: '{type, select, phone {Número de teléfono de voz} sms {Número de teléfono SMS} email {Dirección de correo electrónico} other {}} {value} eliminado con éxito',
        },
        warning: 'La eliminación dejará a {person} sin {type, select, phone {un número de teléfono de voz} sms {un número de teléfono SMS} email {una dirección de correo electrónico} other {}}.',
      }
    },
    peopleGroups: {
      groupTypes: {
        notification: 'notificaciones',
        contact: 'contacto',
      },
      assignPeople: {
        error: 'Un error inesperado impidió que estas personas fueran agregadas.',
        error409: 'Se realizaron otros cambios en las personas asignadas de este grupo antes de que se guardaran los cambios. Ahora se muestran los últimos cambios. Revise y vuelva a intentar los cambios si es necesario.',
        tooltips: {
          missingRequiredContacts: 'Esta persona no tiene un número de teléfono de voz o SMS predeterminado',
        },
        snackbar: {
          success: 'Personas asignadas al grupo de contactos con éxito',
        },
      },
      delete: {
        title: 'eliminar grupo de {groupType}',
        message: '¿Está seguro de que desea eliminar el grupo de contactos <strong>{name}</strong>?',
        assetsWarning: '{count, plural, one {El activo} =2 {Ambos activos} other {Todos los {count, number} activos}} a los que está asignado este grupo de contactos utilizarán el grupo de contactos de mensajería predeterminado del dispositivo.',
        noAssets: 'Este grupo de contactos no está asignado a ningún activo.',
        defaultGroup: 'Este grupo no se puede eliminar porque es el grupo predeterminado de su organización. Antes de eliminar este grupo, debe configurar otro grupo como predeterminado.',
        manageRule: 'Administrar grupos de contactos para {name}',
        lastGroupWarning: 'La eliminación no dejará ningún grupo de contactos en esta regla de notificación.',
        deleteAlert: 'Este grupo de contactos aún no se puede quitar porque está asignado a reglas de notificación. Consulte los detalles de las reglas a continuación y cambie los grupos de contactos asignados según sea necesario.',
        snackbar: {
          success: 'grupo de {groupType}, {name} eliminada con éxito',
        },
      },
      columns: {
        name: 'Nombre de la regla de notificación',
        warning: 'Advertencias',
      },
      tooltips: {
        edit: 'Editar',
      }
    },
    markers: {
      common: {
        validationMessages: {
          outOfRange: 'Valor fuera de rango',
        },
        fields: {
          name: {
            title: 'Nombre',
            error: 'Se debe proporcionar un nombre',
          },
          icon: {
            title: 'Icono',
            options: {
              generic: 'Genérico',
              airport: 'Aeropuerto',
              fire: 'Fuego',
              fuel: 'Combustible',
              water: 'Agua',
            },
          },
          colour: {
            title: 'Color',
          },
          latitude: {
            title: 'Latitud',
            error: 'Se debe proporcionar una coordenada',
          },
          longitude: {
            title: 'Longitud',
            error: 'Se debe proporcionar una coordenada',
          },
          notes: {
            title: 'Notas'
          },
        },
        actions: {
          save: 'Guardar',
          cancel: 'Cancelar',
          confirm: 'Confirmar',
        },
      },
      create: {
        title: 'Crear nuevo marcador',
      },
      delete: {
        title: 'Borrar: {name}',
        description: '¿Estás seguro de que deseas eliminar este marcador?',
      },
    },
    annotations: {
      point: {
        title: {
          create: 'Agregar anotacion de punto',
          edit: 'Editar punto'
        },
      },
      path: {
        title: {
          create: 'Agregar anotacion de camino',
          edit: 'Editar camino'
        },
      },
      errors: {
        name: "El nombre es obligatorio",
        nameLength: "El nombre es demasiado largo",
        descriptionLength: "La descripción es demasiado larga",
        latitude: "Se requiere una latitud válida",
        longitude: "Se requiere una longitud válida",
        width: "El ancho debe ser positivo"
      },
      fields: {
        name: 'Nombre',
        description: 'Descripción',
        latitude: 'Latitud',
        longitude: 'Longitud',
        icon: 'Icono',
        width: 'Ancho de línea'
      },
      actions: {
        cancel: 'Cancelar',
        save: 'Guardar',
      }
    },
    inferredEvents: {
      help: {
        title: 'Eventos Inferidos',
        description: 'Los eventos inferidos se generan en función de la velocidad y la elevación. Son más útiles para activos en los que el dispositivo no genera eventos.',
        continue: 'Continuar',
      },
    },
  },
  omnibox: {
    modules: {
      search: {
        inputPlaceholder: 'Buscar...'
      },
      datePicker: {
        viewHistoricalData: 'Ver datos históricos',
        historicSnackBar: 'Ahora viendo datos históricos. Los datos en vivo no se mostrarán en modo histórico',
        resetToLive: 'Restablecer en vivo'
      },
      queries: {
        assets: 'Activo',
        devices: 'Dispositivos',
        legs: 'Tramos',
        missions: 'Misiones'
      },
      options: {
        title: 'Agrupación y clasificación',
        groupBy: 'Agrupar por',
        sortBy: 'Ordenar por',
        none: 'Ninguno',
        latestActivity: 'Última actividad',
        make: 'Marca',
        model: 'Modelo',
        status: 'Estado',
        owner: 'Propietario',
        category: 'Categoría',
        type: 'Tipo',
        watchlist: 'Grupo de lista de vigilancia',
        operator: 'Operador',
        activity: 'Actividad',
        name: `{assetLabelKey, select,
          callSign {Distintivo de llamada}
          tailNumber {Número de cola}
          other {Nombre}
        }`,
        assetGroup: 'Grupo de activos',
      },
      results: {
        lastActive: 'Última actividad',
        firmwareOutdated: 'Firmware desactualizado',
        trackingSuspended: 'Seguimiento pausado',
        batteryPercentage: 'Bateria cargada %',
        unassigned: 'No asignado',
        underway: 'En marcha',
        noId: 'Sin identificación',
        noResults: 'Sin resultados',
        unknownQuery: 'Consulta Desconocida',
        never: 'Nunca',
        neverHistoric: 'No activo',
        other: 'Otro',
        heartbeat: 'Latido del corazón',
        drone: 'Zumbido',
        person: 'Persona',
        worker: 'Trabajador',
        'executive man': 'Ejecutivo',
        'executive woman': 'Ejecutiva',
        bicycle: 'Bicicleta',
        truck: 'Camión',
        bus: 'Autobús',
        ambulance: 'Ambulancia',
        car: 'Coche',
        'fire truck': 'Camión de bomberos',
        'police car': 'Coche de policía',
        minibus: 'microbús',
        'mobile home': 'Casa móvil',
        motorcycle: 'Motocicleta',
        tractor: 'Tractor',
        yacht: 'Yate',
        tug: 'Tirón',
        'container ship': 'Barco mercante',
        powerboat: 'Lancha fuera borda',
        container: 'Envase',
        aircraft: 'Aeronave',
        airship: 'Dirigible',
        helicopter: 'Helicóptero',
        balloon: 'El globo',
        rocket: 'Cohete',
        unknown: 'Desconocido',
        system: 'Sistema',
        standard: 'Estándar',
        ACTIVE: 'Activado',
        INACTIVE: 'Desactivado',
        SUSPENDED: 'Pausado',
        ERROR: 'Error',
        UNKNOWN: 'Desconocido'
      },
      groupQueryResults: {
        hideOnMap: 'Ocultar en el mapa',
        showOnMap: 'Mostrar en el mapa',
        never: 'Nunca',
        other: 'Otro',
        heartbeat: 'Latido del corazón',
        drone: 'Zumbido',
        person: 'Persona',
        worker: 'Trabajador',
        'executive man': 'Ejecutivo',
        'executive woman': 'Ejecutiva',
        bicycle: 'Bicicleta',
        truck: 'Camión',
        bus: 'Autobús',
        ambulance: 'Ambulancia',
        car: 'Coche',
        'fire truck': 'Camión de bomberos',
        'police car': 'Coche de policía',
        minibus: 'microbús',
        'mobile home': 'Casa móvil',
        motorcycle: 'Motocicleta',
        tractor: 'Tractor',
        yacht: 'Yate',
        tug: 'Tirón',
        'container ship': 'Barco mercante',
        powerboat: 'Lancha fuera borda',
        container: 'Envase',
        aircraft: 'Aeronave',
        airship: 'Dirigible',
        helicopter: 'Helicóptero',
        balloon: 'El globo',
        rocket: 'Cohete',
        unknown: 'Desconocido',
        system: 'Sistema',
        loading: 'Cargando...',
        timeBuckets: {
          fifteenMinutes: 'Activo ahora',
          hour: 'Activo en la última hora',
          day: 'Activo en el último día',
          week: 'Activo en la última semana',
          month: 'Activo en el último mes',
          older: 'Activo hace más de un mes',
          never: 'Nunca',
          neverHistoric: 'No activo'
        }
      }
    },
    settings: {
      expandPanel: 'Expandir panel',
      collapsePanel: 'Contraer panel',
      hideAll: 'Ocultar todo',
      showAll: 'Mostrar todo',
      groupAndSort: 'Agrupar y ordenar'
    }
  },
  contextbox: {
    asset: {
      details: {
        lastActive: 'Última actividad',
        device: 'Dispositivo',
        devices: 'Dispositivos',
        noDevices: 'Sin dispositivos',
        editAsset: 'Editar activo',
        viewAsset: 'Ver activo',
        name: 'Nombre',
        callSign: 'Señal de llamada',
        tailNumber: 'Número de la cola',
        makeModelVariantTooltip: 'Marca, modelo, variante',
        messagingHandleTooltip: 'Identificador de mensajes',
        noSerialSet: '{serialType} número no establecido',
        tpSerial: 'Serie de TracPlus',
        imei: 'IMEI',
        satNumber: 'Telefono satelital',
        smsNumber: 'SMS satelital',
        manufacturerSerial: 'Serie del fabricante',
        owner: 'Propietario',
        trackingRates: 'Tasas de seguimiento',
        cellular: 'Celular',
        satellite: 'Satélite',
        fixRate: 'Tasa de posición',
        inactiveRate: 'Tasa cuando está inactivo',
        trackingSuspended: 'Seguimiento pausado',
        rock7SuspendedHelp: 'Más información sobre cómo reanudar el seguimiento',
        firmwareOutdated: 'El firmware está desactualizado',
        firmwareOutdatedHelp: 'Aprenda a actualizar el firmware',
        gprsNever: 'La estrategia GPRS está configurada en Nunca',
        gprsNeverHelp: 'Aprenda cómo actualizar la estrategia GPRS',
        gprsNeverDialog: {
          title: 'Cómo actualizar la estrategia GPRS',
          description: 'Cuando la estrategia GPRS está configurada en Nunca, el dispositivo nunca utilizará el seguimiento celular. La estrategia GPRS se puede cambiar usando TP Connect.',
          close: 'Cerrar',
        },
      },
      latestActivity: {
        title: 'Última actividad',
        departure: 'Partida',
        takeoff: 'Despegar',
        landing: 'Aterrizaje',
        arrival: 'Llegada',
        elapsed: 'Transcurrido',
        showAllReports: 'Mostrar todos los informes',
      },
      legs: {
        title: 'Tramos',
        elapsed: 'Finalizado',
        unknown: 'Desconocido'
      },
      activity: {
        title: 'Actividad',
        report: 'Reporte',
        reports: 'Reportes',
        filteredEvent: 'Evento filtrado',
        filteredEvents: 'Eventos filtrados',
        filterEvents: 'Filtrar eventos',
        filteredForm: 'Formar filtrado',
        filteredForms: 'Formas filtrados',
        noActivity: 'Aún no hay actividad registrada para este activo.',
        totalDistance: 'Distancia total',
        totalTime: 'Tiempo Total',
        inferredEvent: 'Evento Inferido',
        actionButtons: {
          jumpToLatestTooltip: 'Ir a la actividad más reciente',
          viewInTripAnalysisTooltip: 'Ver en Análisis de Viaje',
          filterTooltip: 'Filtrar eventos',
          beta: 'Beta',
          historicSnackBar: 'Ver datos históricos. Los datos en vivo no se mostrarán en el modo histórico.',
          analyzeReportData: 'Analizar datos de informes'
        },
        filter: {
          eventsFilter: 'Filtro de eventos',
          selectAll: 'Seleccionar todo',
          clearAll: 'Deseleccionar todo',
          close: 'Cerrar'
        }
      },
      messages: {
        title: 'Últimos mensajes'
      },
      distress: {
        title: 'El activo está en Peligro',
        viewDistressDetails: 'Ver detalles',
        cancelTitle: 'Cancelar modo de emergencia',
        cancelText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        handleTitle: 'Manejar modo de socorro',
        handleText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        startTime: 'Hora de inicio del incidente',
        currentStatus: 'Informe más reciente',
        asset: 'Asset',
        time: 'Hora del informe',
        altitude: 'Altitud',
        latitude: 'Latitud',
        longitude: 'Longitud',
        speed: 'Velocidad',
        courseBearing: 'Trayectoria',
        notAvailable: 'No disponible',
        distressIncidentDetails: 'Detalles del incidente de socorro',
        close: 'Cerrar'
      },
      ams: {
        title: 'Servicio de monitoreo activo del activo'
      },
      charts: {
        altitudeElavationHeader: 'Altitud/Elevación',
        speedHeadingHeader: 'Velocidad/Rumbo',
        altitudeTitle: 'Altitud',
        elevationTitle: 'Elevación',
        speedTitle: 'Velocidad',
        headingTitle: 'Trayectoria'
      },
      jobs: {
        title: 'Trabajos',
        start: 'Comienzo',
        finish: 'Terminar'
      }
    },
    device: {
      details: {
        lastActive: 'Última Actividad',
        status: 'Estado',
        assignedTo: 'Asignado a',
        deviceUnassigned: 'Dispositivo no asignado',
        unassigned: 'no asignado',
        serialNumbers: 'Números seriales',
        tpSerial: 'TracPlus',
        imei: 'IMEI',
        manufacturerSerial: 'Fabricante'
      }
    },
    leg: {
      details: {
        from: 'Desde',
        to: 'a',
        departed: 'Salido',
        arrived: 'Llegado',
        tookOff: 'Despegó',
        landed: 'Aterrizado',
        elapsed: 'Transcurrido',
        asset: 'Activo',
        'make-model': 'Fabricante / modelo',
        pilot: 'Piloto'
      }
    },
    mission: {
      details: {
        route: 'Ruta',
        start: 'Comienzo',
        end: 'Final',
        elapsed: 'Transcurrido',
        asset: 'Activo',
        'make-model': 'Fabricante / Modelo',
        pilot: 'Piloto'
      }
    },
    settings: {
      expandPanel: 'Expandir panel',
      collapsePanel: 'Contraer panel'
    }
  },
  shared: {
    units: {
      altitude: 'Altitud',
      speed: 'Velocidad',
      time: 'Tiempo',
      distance: 'Distancia',
      elevation: 'Elevación'
    },
    calendar: {
      today: 'Hoy',
      day: 'Día',
      week: 'Semana',
      month: 'Mes'
    },
    dataLoader: {
      noData: 'Sin datos'
    },
    multiStageForm: {
      optional: 'Opcional',
      finished: 'Todos los pasos completados: has terminado',
      reset: 'Reiniciar',
      back: 'Atrás',
      next: 'Siguiente',
      finish: 'Terminar',
      skip: 'Omitir'
    },
    underConstruction: {
      message: 'Disculpe. Esta página está actualmente en construcción y puede no estar funcional.'
    },
    staffIndicator: {
      staffmember: 'Personal de TracPlus'
    },
    inferredEvents: {
      INFERRED_MOVEMENT_START: 'Inicio del movimiento',
      INFERRED_MOVEMENT_END: 'Fin del movimiento',
      INFERRED_TAKEOFF: 'Despegue',
      INFERRED_LANDING: 'Aterrizaje',
    },
    inferredEventsWithPrefix: {
      INFERRED_MOVEMENT_START: 'Inicio del movimiento inferido',
      INFERRED_MOVEMENT_END: 'Fin del movimiento inferido',
      INFERRED_TAKEOFF: 'Despegue inferido',
      INFERRED_LANDING: 'Aterrizaje inferido',
    },
  },
  statusbar: {
    regional: {
      title: 'Regional',
      description: 'Administrar las preferencias de idioma y zona horaria',
      tooltip: 'Ajustes regionales',
      languageLabel: 'Idioma',
      timezoneLabel: 'Zona horaria',
      timezonePlaceholder: 'Elige una zona horaria'
    },
    display: {
      title: 'Configuración de pantalla',
      tooltip: 'Configuración de pantalla',
    },
    systemStatus: {
      tooltip: 'Estado del sistema',
      title: 'Estado del sistema'
    },
    settings: {
      displaySettings: 'Configuración de pantalla',
      units: 'Unidades',
      general: 'General',
      locale: 'Configuración regional'
    },
    displayMode: {
      tooltip: 'Alternar Modo de Pantalla',
      label: 'Modo de Pantalla'
    }
  },
  colorPicker: {
    useCustomColor: 'Elige un color personalizado',
    useSuggestedColor: 'Usar un color sugerido'
  },
  passwordStrength: {
    passwordStrength: 'Seguridad de la contraseña',
    veryWeak: 'Muy débil',
    weak: 'Débil',
    moderate: 'Moderado',
    strong: 'Fuerte',
    veryStrong: 'Muy fuerte'
  },
  tags: {
    unknown: '',
    ams: {
      checkedin: 'Registrado',
      ofconcern: 'De preocupación',
      overdue: 'Atrasado'
    },
    distress: {
      indistress: 'En peligro',
      notindistress: ''
    },
    airborne: {
      airborne: 'Aerotransportado',
      landed: 'Aterrizado'
    },
    engine: {
      engineoff: 'Apagar',
      engineon: 'El funcionamiento',
      warmingup: 'Prepararse'
    }
  },
  analysisbox: {
    timeline: {
      allAssets: 'Todos los activos',
      showTimeline: 'Mostrar línea de tiempo',
      hideTimeline: 'Ocultar línea de tiempo',
    },
    standard: 'Estándar',
    timestamp: 'Marca de tiempo',
    coordinates: 'Coordenadas',
    altitude: 'Altitud',
    bearing: 'Trayectoria',
    speed: 'Velocidad',
    event: 'Evento',
    inferredEvents: 'Eventos inferidos',
    text: 'Texto',
    dop: 'DOP',
    latency: 'Latencia',
    elapsed: 'Transcurrido',
    distance: 'Distancia',
    gateway: 'Puerta',
    metadata: 'Metadatos',
    battery: 'Batería',
    export: 'Exportar',
    expandPanel: 'Expandir panel',
    collapsePanel: 'Contraer panel',
    hideReports: 'Ocultar informes',
    showReports: 'Mostrar informes',
    eventFilter: {
      filterEvents: 'Filtrar eventos',
      filterEvent: 'Evento de filtro',
      summaryText: 'Eventos'
    },
    fieldFilter: {
      selectColumns: 'Seleccionar columnas',
      summaryText: 'Columnas'
    }
  },
  billing: {
    allowanceTypes: {
      ptt: 'PTT',
      australian_voice: 'Voz Australiano',
      voice: 'Voz',
      '3rd_party': 'Tercera Parte',
      cell_ip: 'IP Celular',
      iridium_sms: 'Iridium SMS',
      mailbox_check: 'Comprobación del Buzón',
      sbd: 'Iridium SBD',
      sbd_registration: 'Registro de Iridium SBD',
      sms: 'Mensajes de SMS',
      inmarsat: 'Inmarsat',
      dial_up_data: 'Datos de Marcado',
      direct_internet_data: 'Datos Directos de Internet'
    },
    planDetails: {
      failedToLoadAddons: 'Error al cargar los complementos Servicios Adicionales. Actualice para volver a intentarlo.',
      failedToLoadTags: 'Error al cargar etiquetas. Inténtelo de nuevo.',
      name: 'Nombre',
      monthlyCost: 'Costo Mensual',
      activationFee: 'Tarifa de Activación',
      deactivationFee: 'Tarifa de Desactivación',
      tags: 'Etiquetas',
      includedAddons: 'Servicios Adicionales Incluidos',
      assignToAnyDeviceModel: 'Asignar a Cualquier Modelo de Dispositivo',
      deviceModelRestrictions: 'Restricciones del Modelo de Dispositivo',
      planDetails: 'Detalles del Plan',
      unlimited: 'Ilimitado',
      allowancesAndOverages: 'Asignaciónes y Excedentes',
      allowance: 'Asignación',
      overage: 'Excedente',
      includedPlanAddons: 'Servicios Adicionales Incluidos del Plan',
      includedPlanAddonsDesc: 'Servicios Adicionales que sean incluidos en el costo mensual del plan',
      deviceModelAssignmentRestrictions: 'Restricciones del Modelo de Dispositivo',
      deviceModelAssignmentRestrictionsDesc: 'Restrinja los modelos de dispositivos a los que se puede asignar este plan. Tenga en cuenta que dejar la casilla de verificación sin marcar y sin modelos de dispositivo seleccionado significa que el plan no se puede asignar a ningún modelo de dispositivo'
    },
    addonDetails: {
      name: 'Nombre',
      monthlyCost: 'Costo Mensual',
      type: 'Tipo',
      addonDetails: 'Detalles del Accesio',
      device: 'Dispositivo',
      organisation: 'Organización'
    },
    assignments: {
      title: 'Facturación',
      currentPlan: 'Plan Actual:',
      name: 'Nombre:',
      monthlyCost: 'Precio Mensual:',
      startDate: 'Fecha de Comienzo:',
      endDate: 'Fecha de Finalización:',
      discount: 'Descuento:',
      viewDetails: 'Ver Detalles',
      noPlanAssigned: 'No hay ningún plan asignado',
      loading: 'Cargando...',
      planHistory: 'Historia de Planes:',
      currentPlanDetails: 'Detalles del Plan Actual',
      historicPlanDetails: 'Detalles del Plan Histórico',
      currentAddons: 'Servicios Adicionales Actuales:',
      currentOrgAddons: 'Servicios Adicionales Actuales de la Organización',
      addonHistory: 'Historia de Servicios Adicionales:',
      quantity: 'Cantidad:',
      currentAddonDetails: 'Detalles del Servicio Adicional Actual',
      historicAddonDetails: 'Detalles del Servicio Adicional Histórico',
      assignNewPlan: 'Asignar Plan Nuevo',
      assignNewAddon: 'Asignar Servicio Adicional Nuevo',
      failedToLoadPlanAssignments: 'Error al cargar las asignaciones del plan. Actualice e inténtelo de nuevo',
      failedToLoadAddonAssignments: 'Error al cargar las asignaciones del servicio adicional. Actualice e inténtelo de nuevo',
      failedToLoadPlanDetails: 'Error al cargar los detalles del plan. Actualice e inténtelo de nuevo',
      failedToLoadAddonDetails: 'Error al cargar los detalles del servicio adicional. Actualice e inténtelo de nuevo',
      failedToLoadAllPlans: 'Error al cargar los planes. Asignación de planes no será posible.',
      failedToLoadAllAddons: 'Error al cargar los servicios adicionales. Asignación de servicios adicionales no será posible.',
      errorAssigningDevicePlan: 'Error al asignar plan al dispositivo. Actualice e inténtelo de nuevo.',
      planAssignmentCreated: 'Asignación del plan creada correctamente.',
      errorAssigningAddon: 'Error al asignar servicio adicional al dispositivo. Actualice e inténtelo de nuevo.',
      addonAssignmentCreated: 'Asignación del servicio adicional creada correctamente.',
      planCancelled: 'Plan cancelado correctamente',
      failedToCancelPlan: 'Error al cancelar el plan',
      addonCancelled: 'Servicio Adicional cancelado correctamente',
      failedToCancelAddon: 'Error al cancelar el Servicios Adicionales',
      planUpdated: 'Plan actualizado correctamente',
      failedToUpdatePlan: 'Error al actualizar el plan',
      addonUpdated: 'Servicio Adicional actualizado correctamente',
      failedToUpdateAddon: 'Error al actualizar el Servicio Adicional',
      setNewStartDate: 'Establecer nueva fecha de inicio',
      setNewEndDate: 'Establecer nueva fecha de finalización',
      areYouSure: '¿Está seguro?',
      no: 'No',
      yes: 'Sí',
      cancel: 'Cancelar',
      timeNote1: 'Por favor tenga en cuenta que esta fecha está en UTC',
      timeNote2: 'y que la fecha de inicio o finalización del asignado',
      timeNote3: 'adyacente debe ser ajustado, si procede.',
      addon: 'Servicio Adicional',
      'discount%': 'Descuento %',
      discount$: 'Descuento $',
      calculatedMonthlyCost: 'Costo Mensual Calculado: ',
      save: 'Guardar',
      plan: 'Plan',
      retired: ' (Retirado) ',
      showRetired: 'Ver retirados',
      incursActivationFee: 'Incurre tarifa de activación {activationFee}',
      incursDeactivationFee: 'Incurre tarifa de desactivación {deactivationFee}',
      cancelPlan: 'Cancelar Plan',
      cancelAddon: 'Cancelar Servicio Adicional',
      modifyStartDate: 'Modificar Fecha de Inicio',
      modifyEndDate: 'Modificar Fecha de Finalización',
      visibleAssignment: 'Visible para los clientes',
      invisibleAssignment: 'No visible para los clientes',
      makeAssignmentVisible: 'Hacer visible para los clientes',
      makeAssignmentInvisible: 'Hacer no visible para los clientes',
      assignNewPool: 'Asignar Nuevo Tiempo Aire Agrupado',
      allowancePool: 'Tiempo Aire Agrupado',
      cancelAllowancePool: 'Cancelar Tiempo Aire Agrupado',
      failedToLoadPoolAssignments: 'Error al cargar las asignaciones de los tiempos aires agrupados. Actualice e inténtelo de nuevo',
      errorAssigningPool: 'Error al asignar el tiempo aire agrupado al dispositivo. Actualice e inténtelo de nuevo',
      poolCreated: 'Asignación del tiempo aire agrupado creado correctamente.',
      planDescription: 'Detalles y historia sobre los planes de este dispositivo',
      addons: 'Servicios Adicionales',
      addonDescription: 'Detalles y historia sobre los servicios adicionales de este dispositivo',
      noAssignedAddons: 'No hay ningún servicio adicional asignado',
      pooling: 'Tiempo Aire Agrupado',
      poolingDescription: 'Detalles y historia sobre tiempos aires agrupados de este dispositivo',
      currentPools: 'Tiempos Aires Agrupados Actuales:',
      noAssignedPools: 'No hay ningún tiempo aire agrupado asignado',
      poolHistory: 'Historia de Tiempos Aires Agrupados:',
      poolName: 'Nombre del Grupo:',
      allowance: 'Asignación de Tiempo Aire:'
    },
    pools: {
      failedToLoadPlans: 'Error al cargar planes. Actualice e inténtelo de nuevo',
      failedToLoadAllowancePools: 'Error al cargar tiempos aires agrupados. Actualice e inténtelo de nuevo',
      errorUpdatingPool: 'Error al actualizar el tiempo aire agrupado. Actualice e inténtelo de nuevo',
      errorCreatingPool: 'Error al crear el tiempo aire agrupado. Actualice e inténtelo de nuevo',
      errorCancellingPool: 'Error al cancelar el tiempo aire agrupado. Actualice e inténtelo de nuevo',
      poolCreated: 'Tiempo aire agrupado creado correctamente',
      poolUpdated: 'Tiempo aire agrupado actualizado correctamente',
      poolDeleted: 'Tiempo aire agrupado creado correctamente',
      poolCancelled: 'Tiempo aire agrupado cancelado correctamente',
      createPool: 'Crear nuevo tiempo aire agrupado',
      name: 'Nombre',
      plan: 'Plan',
      allowance: 'Asignación de Tiempo Aire',
      editPool: 'Editar tiempo aire agrupado',
      deletePool: 'Borrar tiempo aire agrupado',
      allowancePools: 'Tiempos Aires Agrupados',
      actions: 'Acciones',
      save: 'Guardar',
      cancel: 'Cancelar',
      showRetired: 'Ver retirados',
      newPool: 'Nuevo tiempo aire agrupado',
      modifyPool: 'Modificar tiempo aire agrupado',
      retired: ' (Retirado) '
    }
  },
  limitedList: {
    more: 'y {n} más',
  },
  labs: {
    dialog: {
      continue: 'Continuar',
    },
    features: {
      warnings: {
        noAssetsEnabled: 'No hay activos habilitados',
        nAssetsDisabled: '{n, plural, one {# activo deshabilitado} other {# activos inhabilitados}}',
      },
      tooltips: {
        assetsDisabled: 'Esta característica no está habilitada para estos recursos',
      },
      unknown: {
        title: 'Desconocido',
        description: 'Desconocido'
      },
      map: {
        weather: {
          title: 'Clima en vivo',
          description: 'Muestra la velocidad y dirección del viento en vivo en el mapa',
          imageAlt: ''
        },
        adsb: {
          title: 'Mapeo ADS-B',
          description: 'Ver las posiciones de todos los aviones en el espacio aéreo con posicionamiento en vivo proveniente de ADS-B',
          imageAlt: ''
        },
        '3d': {
          title: 'Mapa 3D',
          description: 'Vea el terreno y muestre la altura real de la aeronave sobre él.',
          imageAlt: ''
        },
        markers: {
          title: 'Marcadores',
          description: 'Ver puntos de interés marcados',
          imageAlt: ''
        },
        containmentLines: {
          title: 'Líneas de contención',
          description: 'Ver líneas de contención en el mapa',
          imageAlt: ''
        },
        firefightingOptions: {
          title: 'Módulo no disponible',
          description: 'Requiere el módulo "Extinción de incendios aéreos"',
          imageAlt: ''
        },
        clouds: {
          title: 'Nubes en tiempo real',
          description: 'Permite un mapa base que muestra imágenes satelitales diarias, incluidas las nubes.'
        },
        droplines: {
          title: 'Líneas de caída',
          description: 'Muestra dónde se dejó caer agua o retardante en el mapa.'
        },
        marine: {
          title: 'Mapas marinos',
          description: 'Permite la visualización de cartas náuticas'
        },
        annotations: {
          title: 'Anotaciones',
          description: 'Crea características en tu mapa, incluidos puntos, rutas y patrones de búsqueda. Estos solo son visibles para ti y tu sesión actual.',
        }
      },
      manage: {
        geofencing: {
          title: 'Geocercas',
          description: 'Reciba notificaciones cuando un activo entre, salga o cruce el límite de un área cerrada.',
          imageAlt: ''
        },
        assetGroups: {
          title: 'Grupos de activos',
          description: 'Habilita la capacidad de crear, editar y eliminar grupos de activos, que son una colección de activos.',
          imageAlt: ''
        },
        notifications: {
          title: 'Notificaciones',
          description: 'Permite a los usuarios crear, editar y eliminar notificaciones para cualquier evento o grupo de eventos.',
          imageAlt: ''
        }
      },
      beacon: {
        ios: {
          title: 'Baliza',
          description: 'Administrar baliza'
        }
      },
      assets: {
        jobs: {
          title: 'Sistema de trabajos',
          description: 'Sistema de trabajos creado para NIWA'
        }
      },
      reporting: {
        activitySummaryReport: {
          title: 'Informe de resumen de actividad',
          description: 'Ver estadísticas agregadas para la actividad de los activos.'
        },
        aerialFirefightingReport: {
          title: 'Informe de Lucha contra Incendios Aéreos',
          description: 'Análisis detallado de las descargas por activo y tipo de supresor.'
        },
        dailyFlightSummary: {
          title: 'Resumen de Vuelo Diario',
          description: 'Ver vuelos agregados para un activo durante un día, editar datos complementarios y aprobar vuelos.'
        },
        geofences: {
          title: 'Informe de Eventos de Geocercas',
          description: 'Ver datos históricos para eventos de cruces de geocercas.'
        },
        newTripReports: {
          title: 'Informes de Viaje',
          description: 'Ver gráficos y datos para viajes.'
        },
        supplementaryData: {
          title: 'Datos Complementarios',
          description: 'Datos complementarios asociados con los viajes.'
        },
        trackingDataReports: {
          title: 'Informes de datos de seguimiento',
          description: 'Ver y descargar informes de la actividad de los activos rastreados',
        }
      },
    },
  },
};

export default messages;
