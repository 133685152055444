import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { deleteAsset, selectLeg, setDay } from './app.slice';
import { loggedOut, resetEverything } from './session/session.slice';

export interface ReportState {
  filters: {
    default: {
      blacklist: string[]
    },
    asset: Record<number, Filter>
  }
  highlightedReportId: number | null
  forms: boolean
  selectedReportPerMap: Record<string, Report | null>
  textMessages: Record<number, number>
  mostRecentDeviceReport: number | null
  debounceStatus: 'idle' | 'pending' | 'failed'
}

const initialState: Readonly<ReportState> = Object.freeze({
  filters: {
    default: {
      blacklist: ['Course Change', 'course_change', 'EVT_COURSE_CHANGE']
    },
    asset: {}
  },
  highlightedReportId: null,
  forms: true,
  selectedReportPerMap: { default: null },
  textMessages: {},
  mostRecentDeviceReport: null,
  debounceStatus: 'idle',
});

const handler = async (timestamp: number | null) => Promise.resolve(timestamp);
const debouncedHandler = (timestamp: number | null) => new Promise<number | null>(resolve => {
  debounce(() => resolve(handler(timestamp)), 1000)();
});

export const setMostRecentDeviceReportDebounceThunk = createAppAsyncThunk(
  'report/SET_MOST_RECENT_DEVICE_REPORT',
  debouncedHandler,
);

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    markRead: (state, action: PayloadAction<{ deviceId: number }>) => {
      delete state.textMessages[action.payload.deviceId];
    },
    setUnreadTextMessage: (state, action: PayloadAction<{ deviceId: number, reportId: number }>) => {
      if (state.textMessages[action.payload.deviceId] === action.payload.reportId) return;
      const alertSound = new Audio('audio/ding.mp3');
      alertSound.play();
      state.textMessages[action.payload.deviceId] = action.payload.reportId;
    },
    filterAssetReports: (state, action: PayloadAction<{ assetId: number, filter: Filter }>) => {
      state.filters.asset[action.payload.assetId] = action.payload.filter;
    },
    setHighlightedReport: (state, action: PayloadAction<{ id: number | null }>) => {
      state.highlightedReportId = action.payload.id;
    },
    setSelectedReport: (state, action: PayloadAction<{ mapId: string, report: Report | null }>) => {
      state.selectedReportPerMap[action.payload.mapId] = action.payload.report;
    },
    _setMostRecentDeviceReport: (state, action: PayloadAction<{ timestamp: number | null }>) => {
      state.mostRecentDeviceReport = action.payload.timestamp;
    }
  },
  selectors: {
    mostRecentDeviceReportSelector: state => state.mostRecentDeviceReport,
    textMessagesSelector: state => state.textMessages,
    highlightedReportIdSelector: state => state.highlightedReportId,
  },
  extraReducers: builder => {
    builder
      .addCase(loggedOut, () => initialState)
      .addCase(resetEverything, () => initialState)
      .addCase(setDay.type, state => {
        state.selectedReportPerMap = Object.keys(state.selectedReportPerMap).reduce((acc, mapId) => {
          acc[mapId] = null;
          return acc;
        }, {} as Record<string, Report | null>);
      })
      .addCase(setMostRecentDeviceReportDebounceThunk.pending, state => {
        state.debounceStatus = 'pending';
      })
      .addCase(setMostRecentDeviceReportDebounceThunk.fulfilled, (state, action: PayloadAction<number | null>) => {
        state.mostRecentDeviceReport = action.payload;
        state.debounceStatus = 'idle';
      })
      .addCase(setMostRecentDeviceReportDebounceThunk.rejected, state => {
        state.mostRecentDeviceReport = null;
        state.debounceStatus = 'failed';
      })
      .addCase(selectLeg, (state, action: PayloadAction<{ leg: Leg | null }>) => {
        if (!action.payload.leg) return;
        Object.entries(state.selectedReportPerMap).forEach(([mapId, report]) => {
          if (report?.received
            && action.payload.leg
            && action.payload.leg.end
            && report?.received
            && report?.received > action.payload.leg.start
            && report?.received < action.payload.leg.end) {
            state.selectedReportPerMap[mapId] = report;
          } else {
            state.selectedReportPerMap[mapId] = null;
          }
        });
      }
      )
      .addCase(deleteAsset, (state, action: PayloadAction<{ id: number }>) => {
        if (!action.payload.id || !state.selectedReportPerMap[action.payload.id]) return;
        delete state.filters.asset[action.payload.id];
      })
  },
});

export const selectedReportSelector = (id: string) => (state: ReduxState) => state.reports.selectedReportPerMap[id];


export const {
  markRead,
  setUnreadTextMessage,
  filterAssetReports,
  setHighlightedReport,
  setSelectedReport,
  _setMostRecentDeviceReport,
} = reportSlice.actions;

export const {
  mostRecentDeviceReportSelector,
  textMessagesSelector,
  highlightedReportIdSelector,
} = reportSlice.selectors;

export default reportSlice.reducer;
