import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { MapSettings } from 'slices/map.slice';
import { AdsbAircraft } from 'apis/adsb/types';
import { useSelector } from 'react-redux';
import { MapTemplate } from 'mapTemplates/reactmapgl/types';
import { Marker } from 'apis/rest/markers/types';
import type { GeofenceResponseItem } from 'apis/rest/geofence/types';
import { FeatureFlag } from 'components/shared/featureFlag';
import MultiMeasurementPanel from 'components/maps/reactmapgl/overlays/measurementDetails/multiMeasurementPanel-view';
import { AnnotationPath, AnnotationPoint } from 'slices/map/annotations.slice';
import MeasurementPanel from './measurementDetails';
import ReportDetails from './reportDetails';
import { AssetTrailColouringLegend } from './assetTrailColouringLegend';
import { HoveredElement, HoveredElementOverlay } from './hoveredElementOverlay';
import { AdsbDetailsOverlay } from './adsbDetails';
import { HoveredGeofenceOverlay } from './hoveredGeofenceOverlay';
import { MapIntervalOverlay } from './MapIntervalOverlay';
import { HoveredMarkerOverlay } from './HoveredMarkerOverlay';
import { HoveredAnnotationOverlay } from './HoveredAnnotationOverlay';

interface OverlayProps {
  measureTool: boolean
  selectedReport?: Report | null
  mapIsSelected?: boolean
  config: MapSettings
  hoveredElement?: HoveredElement
  hoveredAdsbAircraft?: AdsbAircraft
  hoveredGeofences?: GeofenceResponseItem[]
  hoveredMarker?: Marker
  hoveredPoint?: AnnotationPoint
  hoveredPath?: AnnotationPath
  selectedAsset?: AssetBasic
  template: MapTemplate
}

export default ({
  measureTool,
  config,
  mapIsSelected,
  selectedReport,
  hoveredElement,
  hoveredAdsbAircraft,
  hoveredGeofences,
  hoveredMarker,
  hoveredPoint,
  hoveredPath,
  selectedAsset,
  template
}: OverlayProps) => {
  const highContrastControls = useSelector<ReduxState, boolean>(state => state.settings.map.highContrastControls);
  return (
    <Stack
      direction="row"
      px={6}
      spacing={3}
      pt={4}
      top={0}
      justifyContent="end"
      alignItems="start"
      position="absolute"
      width="100%"
      zIndex={2}
      sx={{ pointerEvents: 'none' }}
    >
      <Stack spacing={3} width="260px">
        <AssetTrailColouringLegend config={config} highContrastControls={highContrastControls} />
        <MapIntervalOverlay template={template} highContrast={highContrastControls} />
      </Stack>
      <div style={{ flex: 1 }} />
      {measureTool && (
        <FeatureFlag
          feature="multipointMeasurementTool"
          enabled={<MultiMeasurementPanel highContrastControls={highContrastControls} mapId={config.id} selected={mapIsSelected} />}
          disabled={<MeasurementPanel highContrastControls={highContrastControls} mapId={config.id} selected={mapIsSelected} />}
        />
      )}
      {(selectedReport || hoveredElement || hoveredAdsbAircraft || (hoveredGeofences?.length ?? 0) > 0 || hoveredMarker || hoveredPoint || hoveredPath) && (
        <Stack spacing={3} width="260px">
          {selectedReport && (
            <ReportDetails
              selectedReport={selectedReport}
              highContrastControls={highContrastControls}
            />
          )}
          <HoveredElementOverlay hoveredElement={hoveredElement} highContrast={highContrastControls} />
          <AdsbDetailsOverlay aircraft={hoveredAdsbAircraft} highContrast={highContrastControls} />
          {hoveredGeofences?.map(geofence => (
            <HoveredGeofenceOverlay key={geofence.id} hoveredGeofence={geofence} highContrast={highContrastControls} />
          ))}
          <HoveredMarkerOverlay marker={hoveredMarker} selectedAsset={selectedAsset} highContrast={highContrastControls} />
          <HoveredAnnotationOverlay point={hoveredPoint} path={hoveredPath} selectedAsset={selectedAsset} highContrast={highContrastControls} />
        </Stack>
      )}
    </Stack>
  );
};
