import { DeleteForever, Edit, Place, Route, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { deletePath, deletePoint, selectPaths, selectPoints, setEditPathDialog, setEditPointDialog, togglePathVisibility, togglePointVisibility } from 'slices/map/annotations.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { useTranslations } from 'use-intl';

interface AnnotationsViewProps {
  open: boolean
}



const AnnotationsView: React.FC<PropsWithChildren<AnnotationsViewProps>> = ({
  open
}) => {
  const t = useTranslations('pages.map.settingsDialog.annotations')
  const points = useSelector(selectPoints)
  const paths = useSelector(selectPaths)
  const dispatch = useAppDispatch()

  return (<Stack direction="column" spacing={2} >
    {points.length === 0 && paths.length === 0 && (
      <Typography>{t('noData')}</Typography>
    )}
    {points.map(x => (
      <Stack key={`point-r-${x.id}`} direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-start", alignItems: "center" }}>
          <Place />
          <IconButton size='small' onClick={() => dispatch(togglePointVisibility(x.id))}>
            {x.isVisible ? (<Visibility />) : (<VisibilityOff />)}
          </IconButton>

          <Box
            sx={{
              backgroundColor: x.colour,
              width: '1rem',
              height: '1rem',
              borderRadius: 1,
              flexShrink: 0
            }}
          />
          <Typography>{x.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          <IconButton size='small' onClick={() => dispatch(setEditPointDialog(x))}><Edit /></IconButton>
          <IconButton size='small' onClick={() => dispatch(deletePoint(x.id))}><DeleteForever /></IconButton>
        </Stack>
      </Stack>
    ))}
    {paths.map(x => (
      <Stack key={`paths-r-${x.id}`} direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-start", alignItems: "center" }}>
          <Route />
          <IconButton size='small' onClick={() => dispatch(togglePathVisibility(x.id))}>
            {x.isVisible ? (<Visibility />) : (<VisibilityOff />)}
          </IconButton>
          <Box
            sx={{
              backgroundColor: x.colour,
              width: '1rem',
              height: '1rem',
              borderRadius: 1,
              flexShrink: 0
            }}
          />
          <Typography>{x.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          <IconButton size='small' onClick={() => dispatch(setEditPathDialog(x))}><Edit /></IconButton>
          <IconButton size='small' onClick={() => dispatch(deletePath(x.id))}><DeleteForever /></IconButton>
        </Stack>
      </Stack>
    ))}
  </Stack>)
}

export default AnnotationsView;
