import React, { useCallback } from 'react';
import {
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import moment from 'moment';
import Altitude from 'components/shared/altitude';
import AssetLabel from 'components/shared/assetLabel';
import Coordinate from 'components/shared/coordinate';
import Course from 'components/shared/course';
import DateTime from 'components/shared/datetime';
import DirectionIcon from 'components/shared/icons/directionIcon';
import Icon from 'components/shared/icons/eventIcons';
import Quantity from 'components/shared/quantity';
import Speed from 'components/shared/speed';
import TransmissionIcon from 'components/shared/icons/transmissionIcon';
import { gatewayToTransport } from 'helpers/transport';
import { getSignificantEvents, hasInferredEvents, labelToDisplayLabel } from 'helpers/events';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useInferredEventsForReport } from 'repositories/inferredEvents/hooks';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { SpeedUnit } from 'helpers/unitsOfMeasure';
import { ReportWithInferredEvents } from 'apis/rest/inferredEvents/types';
import useStyles from './reportDetails-styles';
import { ScoreCard, DetailsGrid, OverlayWrapper, OverlayHeader } from '../common';

interface EventIconProps {
  label: string;
  className?: string;
}

interface HeaderProps {
  asset: AssetBasic;
  report: ReportWithInferredEvents;
}

interface PositionDetailsProps {
  speedUnit: SpeedUnit;
  position: Report;
}

interface ReportDetailsOverlayProps {
  selectedReport: Report;
  highContrastControls: boolean;
}

const translationsKey = 'pages.map.reportDetails';

const Simple2dGrid = ({ data }: { data: Record<string, string | number | boolean> }) => {
  const keys = Object.keys(data);

  return (
    <>
      {keys.map(fieldName => {
        // @ts-ignore - TODO: support boolean in scorecard
        const value = data[fieldName] as string | number | undefined;
        return (
          <ScoreCard
            key={fieldName}
            label={fieldName}
            value={value}
            wide={fieldName === 'Passengers'}
          />
        );
      })}
    </>
  );
};

const EventIcon: React.FC<EventIconProps> = ({ label, className }) => (
  <Box className={className}>
    <Icon type={label} />
  </Box>
);

// Displays information common to all reports, eg: the Event name, icon, transport etc
const Header: React.FC<HeaderProps> = ({ asset, report }) => {
  const classes = useStyles();
  const t = useTranslations(translationsKey);
  const t2 = useTranslations('shared.inferredEvents');
  const enableInferredEvents = useFeatureFlag('frontendInferredEvents');

  return (
    <>
      <OverlayHeader>
        <Stack direction="row" alignItems="center">
          <Typography variant="h3"><AssetLabel asset={asset} /></Typography>

          <Box flex={1} />

          {getSignificantEvents(report, enableInferredEvents ?? false).map(e => (
            <EventIcon key={`${report.id}-${e}`} label={e} className={classes.eventIcon} />
          ))}
          {report?.gateway && (
            <Box className={classes.eventIcon}>
              <TransmissionIcon transport={gatewayToTransport(report.gateway)} fillColor="rgba(0,0,0,0.5)" circle />
            </Box>
          )}
          <Box className={classes.iconWrapper}>
            <DirectionIcon style={{
              transition: 'all 0.15s',
              transform: `rotate(${report.course || '0'}deg)`
            }} fillColor="rgba(0,0,0,0.5)" circle />
          </Box>
        </Stack>
      </OverlayHeader>
      <DetailsGrid withoutTopBorder sx={{ pt: 0 }}>
        <ScoreCard label={t('transport')}>
          <Typography fontWeight={500}>{gatewayToTransport(report.gateway) || '--'}</Typography>
        </ScoreCard>
        <ScoreCard label={t('eventType')}>
          <Typography fontWeight={500}>{labelToDisplayLabel(report.events?.[0]) || t('standard')}</Typography>
        </ScoreCard>
        {hasInferredEvents(report) && (
          <ScoreCard label={t('inferredEventTypes')} wide>
            <Box sx={theme => ({ display: 'grid', rowGap: theme.spacing(0.5) })}>
              {report.inferredEvents?.map(evt => (
                <Typography key={`${report.id}-${evt}`} fontWeight={500}>{t2(evt)}</Typography>
              ))}
            </Box>
          </ScoreCard>
        )}
      </DetailsGrid>
    </>
  );
};

const PositionDetails: React.FC<PositionDetailsProps> = ({ position, speedUnit }) => {
  const t = useTranslations(translationsKey);

  return (
    <>
      <ScoreCard label={t('timeOfFix')} wide>
        <DateTime value={moment.unix(position.received)} />
      </ScoreCard>

      <ScoreCard label={t('coordinates')} wide>
        <Coordinate latitude={position.latitude} longitude={position.longitude} />
      </ScoreCard>

      <ScoreCard label={t('altitude')}>
        {position.fixQuality === '3D' ? <Altitude altitudeInMetres={position.altitude} precision={0} /> : <Typography>—</Typography>}
      </ScoreCard>
      <ScoreCard label={t('latency')}>
        <Quantity value={position.logged - position.received} precision={0} units="s" />
      </ScoreCard>

      <ScoreCard label={t('speed')}>
        <Speed unit={speedUnit} speedInKmh={position.speed} />
      </ScoreCard>
      <ScoreCard label={t('course')}>
        <Course courseInDegreesTrue={position.course} timestamp={position.received * 1000} position={[position.longitude, position.latitude]} />
      </ScoreCard>
    </>
  );
};

export const ReportDetailsOverlay: React.FC<ReportDetailsOverlayProps> = ({
  selectedReport,
  highContrastControls,
}) => {
  const { data: asset } = useGetAssetsList({
    select: useCallback((assets: AssetBasic[]) => assets.find(a => a.id === selectedReport.assetId), [selectedReport.assetId]),
  }).query;

  const speedUnit = useSpeedByAsset(asset);

  const enableInferredEvents = useFeatureFlag('frontendInferredEvents');
  const report = useInferredEventsForReport(selectedReport, enableInferredEvents);

  if (!report || !report.isValid || !asset) return null;

  return (
    <OverlayWrapper highContrast={highContrastControls}>
      <Header asset={asset} report={report} />

      <DetailsGrid>
        <PositionDetails position={report} speedUnit={speedUnit} />
        {report?.metadata && (<Simple2dGrid data={report.metadata} />)}
      </DetailsGrid>
    </OverlayWrapper>
  );
};
